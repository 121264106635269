import * as types from "../types";

const initialState = {
  userSearchToggle: 0,
  profileSearch: [],
  documentStatus: [],
  profileSearchMeta: {},
  userProfile: {},
  userLoginHistory: [],
  userLoginHistoryMeta: {},
  userProfileView: [],
  userProfileViewMeta: {},
  userTransactionHistory: {},
  documentHistory: {},
  searchuserTransactionHistoryMeta: null,
  searchuserTransactionHistoryData: null,
  error: {},
  loading: false,
  toggle: 0,
  activeCard: 0,
  subUrl: "",
  supportTickets: [],
  supportTicketsMeta: {},
  searchSupportTicketsMeta: null,
  searchSupportTicketsData: null,
  internalUser: [],
};

const supportReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_TICKET:
      return {
        ...state,
        supportTickets: [action.payload, ...state.supportTickets]
      };
    case types.GET_INTERNAL_USER:
      return {
        ...state,
        internalUser: action.payload,
      };
    case types.FETCH_SUPPORT_TICKET:
      return {
        ...state,
        supportTickets: action.payload,
        supportTicketsMeta: action.meta,
        loading: false,
      };
    case types.SEARCH_SUPPORT_TICKET:
      return {
        ...state,
        searchSupportTicketsData: action.payload,
        searchSupportTicketsMeta: action.meta,
      };
    case types.PROFILE_SEARCH:
      return {
        ...state,
        profileSearch: action.payload,
        profileSearchMeta: action.meta,
        loading: false,
        levelZeroLoading: false,
      };
    case types.PROFILE_SEARCH_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case types.FETCH_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload,
        loading: false,
      };
    case types.FETCH_USER_LOGIN_HISTORY:
      return {
        ...state,
        userLoginHistory: action.payload,
        userLoginHistoryMeta: action.meta,
        loading: false,
      };
    case types.FETCH_USER_PROFILE_VIEW_HISTORY:
      return {
        ...state,
        userProfileView: action.payload,
        userProfileViewMeta: action.meta,
        loading: false,
      };
    case types.FETCH_USER_TRANSACTION_HISTORY:
      return {
        ...state,
        userTransactionHistory: action.payload,
        loading: false,
      };
    case types.SEARCH_USER_TRANSACTION_HISTORY:
      return {
        ...state,
        searchuserTransactionHistoryData: action.payload,
        searchuserTransactionHistoryMeta: action.meta,
      };
    case types.DEACTIVATE_USER:
      return {
        ...state,
        userSearchToggle: action.toggle,
        loading: false,
        userProfile: {
          ...state.userProfile,
          status: "inactive",
          comment: action.comment,
        },
      };
    case types.SET_STATUS:
      return {
        ...state,
        loading: false,
        userProfile: {
          ...state.userProfile,
          status: action.payload,
          comment: action.comment,
        },
      };
    case types.GET_DOCUMENT_STATUS:
      return {
        ...state,
        loading: false,
        documentStatus: action.payload,
      };
    case types.FETCH_DOCUMENT_HISTORY:
      return {
        ...state,
        documentHistory: action.payload,
        loading: false,
      };
    case types.DEACTIVATE_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        userSearchToggle: 13,
      };
    case types.REACTIVATE_USER:
      return {
        ...state,
        userSearchToggle: action.toggle,
        loading: false,
        userProfile: {
          ...state.userProfile,
          status: "active",
          comment: "",
        },
      };
    case types.REACTIVATE_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        userSearchToggle: 13,
      };
    case types.SET_TOGGLE:
      return {
        ...state,
        userSearchToggle: action.payload,
      };
    default:
      return state;
  }
};

export default supportReducer;
