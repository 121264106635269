import "./css/bills-vendor-details.css";
import CloseBtn from "../../img/Close-Square.svg";
import { useState } from "react";

function BillsVendorDetails() {
  const [display, setDisplay] = useState("");
  const closeDisplay = () => {
    setDisplay("bills-close-details");
  };

  return (
    <div className={`display-details ${display}`}>
      <div className={`bills-vendor-details-container`}>
        <div className="bills-details-header">
          <div className="bills-vendor-details">
            <h5>Bills Vendor Detials</h5>
          </div>
        </div>
        <div className="cancel-icon5">
          <img
            src={CloseBtn}
            loading="lazy"
            width="24"
            height="24"
            alt="cancel icon"
            onClick={closeDisplay}
          />
        </div>
        <div className="bills-vendor-details-contents">
          <div className="bills-vendor-details-content">
            <div className="bills-details-content">
              <h5>Vendor Name</h5>
              <p>Vendor 1</p>
            </div>
            <div className="bills-details-content">
              <h5>Phone Number</h5>
              <p>234816489202</p>
            </div>
            <div className="bills-details-content">
              <h5>Email</h5>
              <p>wadew@gmail.com</p>
            </div>
            <div className="bills-details-content">
              <h5>Office Address</h5>
              <p>27, Ogunola strt, ikoyi, lagos</p>
            </div>
            <div className="bills-details-content">
              <h5>API Key</h5>
              <p>jdjd.920394804.090248850/283450</p>
            </div>
            <div className="bills-details-content">
              <h5>Status</h5>
              <p>Active</p>
            </div>
            <div className="bills-details-content">
              <h5>Date Onboarded</h5>
              <p>27/12/2020</p>
            </div>
            <div className="bills-details-content">
              <h5>Bills Services Provided</h5>
              <div className="bills-service">
                <div> <span>Electricity</span></div> 
                <div> <span>Airtime Recharge</span> </div>
                <div> <span>Data</span> </div>
                <div> <span>DSTV</span> </div>
                <div> <span>GOTV</span> </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BillsVendorDetails;
