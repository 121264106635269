// import { useState } from "react";
import "../../css/settlements/settlement-bill-history.css";
import { SettlementInput } from "../input/input";
import kimg from "../../img/ksearch.svg";
import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { SettlementActions } from "../../state/actions";
import Moment from "react-moment";
import Pagination from "../footer-pagination";
import date from "../../img/date.svg";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function SettlementBillsHistory() {
  const [currentPage, setCurrentPage] = useState(1);

  const [search, setSearch] = useState("");

  const [currentDate, setCurrentDate] = useState(null);
  const [currentDate1, setCurrentDate1] = useState(new Date());

  const {
    billHistory,
    // billHistoryloading,
    billHistoryMeta,
    searchBillHistoryMeta,
    searchBillHistoryData,
  } = useSelector((state) => state.settlement);

  const dispatch = useDispatch();
  const { FetchBills, SearchBillHistory, FetchBillsWithDate, DownloadBillHistory } =
    bindActionCreators(SettlementActions, dispatch);

  useEffect(() => {
    if (search.trim() === "") {
      FetchBills(currentPage);
    } else {
      SearchBillHistory(search, currentPage);
    }
    // eslint-disable-next-line
  }, [currentPage]);

  const { per_page, total } = searchBillHistoryMeta
    ? searchBillHistoryMeta
    : billHistoryMeta;

  let userData =
    searchBillHistoryData !== null ? searchBillHistoryData : billHistory;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const DateFormat = forwardRef(({ value, onClick }, ref) => (
    <>
      <div className="bill-history-date-section" onClick={onClick} ref={ref}>
        {value || "DD-MM-YYYY"}
        <img src={date} loading="lazy" width="15" height="20" alt="date icon" />
      </div>
    </>
  ));
  const DateFormat2 = forwardRef(({ value, onClick }, ref) => (
    <>
      <div className="bill-history-date-section" onClick={onClick} ref={ref}>
        {value || "DD-MM-YYYY"}
        <img src={date} loading="lazy" width="15" height="20" alt="date icon" />
      </div>
    </>
  ));

  const displayRecords = () => {
    if (currentDate !== null && currentDate1 !== null) {
      FetchBillsWithDate(1, currentDate, currentDate1);
    } else if (currentDate !== null && currentDate1 === null) {
      FetchBillsWithDate(1, currentDate, null);
    } else if (currentDate === null && currentDate1 !== null) {
      FetchBillsWithDate(1, null, currentDate1);
    }
  };

  return (
    <>
      <div>
        <div className="bills-history-cont">
          <div className="bills">
            <div>
              <div className="settlement-search-section1">
                <div className="settlement-input-cont">
                  <div className="datepicker-class">
                    <DatePicker
                      closeOnScroll={true}
                      selected={currentDate}
                      onChange={(date) => setCurrentDate(date)}
                      isClearable
                      customInput={<DateFormat />}
                    />
                  </div>
                  <div className="datepicker-class">
                    <DatePicker
                      closeOnScroll={true}
                      selected={currentDate1}
                      onChange={(date) => setCurrentDate1(date)}
                      isClearable
                      customInput={<DateFormat2 />}
                    />
                  </div>

                  <button
                    className="bill-history-button"
                    onClick={displayRecords}
                  >
                    Display Records
                  </button>
                  {/* <p className="settlement-verify-pass">
                    Displaying Record for Today
                  </p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="bill-search-cont">
            <div className="">
              <button className="download-records" onClick={DownloadBillHistory}>Download Records</button>
            </div>
            <div className="kscont">
              <SettlementInput
                label="Search Records"
                searchValue={SearchBillHistory}
                search={search}
                setSearch={setSearch}
              />
              <img src={kimg} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="settlement-bills-table-content">
        <table className="transaction-table">
          <tr>
            <th></th>
            <th>Customer</th>
            <th>Transaction ID</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Time</th>
          </tr>
          {userData.map((item, idx) => (
            <tr className="settlement-bills-item-tr">
              <td>{(currentPage - 1) * per_page + 1 + idx}</td>
              <td>{(item.source && item.source.name) || "-"}</td>
              <td>{item.transaction_reference || "-"}</td>
              <td>{(item.amount && "₦" + item.amount) || "-"}</td>
              <td
                className={
                  item.status === "success" ? "text-success" : "text-danger"
                }
              >
                {item.status || "-"}
              </td>
              <td>
                {item.created_at && (
                  <Moment format="DD/MM/YYYY H:m">{item.created_at}</Moment>
                )}
              </td>
            </tr>
          ))}
        </table>
      </div>
      {userData.length === 0 ? (
        <span className="text-danger">No Record found</span>
      ) : (
        <Pagination
          className="users-pagination"
          currentPage={currentPage}
          totalCount={total}
          pageSize={per_page}
          onPageChange={onPageChange}
        />
      )}
      {/* {!billHistoryloading && per_page && total && userData.length > 0 && (
          
        )} */}
    </>
  );
}

export default SettlementBillsHistory;
