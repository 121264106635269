// import Sidebar from "../../components/dashboard/sidebar/sidebar";
import KYCTOP from "../../components/kyc-top/kyc-top";
import "./chat.css";
import { useEffect, useState } from "react";
// import LiveChat from "./liveChat";
// import PendingApproval from "./pendingApproval";
import SupportTicket from "./supportTicket";
import Escalate, {UpdateTicket} from "../../components/escalate";
import ModalUpload from "../../components/support/upload_modal"
import UploadModalID from "../../components/support/upload-modal-id"
import Nav from "../../components/nav";
import SupportProfileSearch from "./support-search";
import Comments from "../../components/comments";
import ReasignTicket from "../../components/reasign-ticket";
import VendorSuccessNotify from "../../components/vendorModals/vendorSuccessNotification";
import ViewSupportTicket from "./view-support-ticket";
import UserControlOnboarding from "../../components/user-control/user-modal/onboarding-modal";
import { bindActionCreators } from "redux";
import { SupportActions } from "../../state/actions";
import { useDispatch, useSelector } from "react-redux";

export default function Chat() {
  const [active, setActive] = useState(0);
  const [escalate, setEscalate] = useState(false);
  const [modalUpload, setModalUpload] = useState(false)
  const [modalUpload2, setModalUpload2] = useState(false)
  const [subUrl, setSubUrl] = useState("")
  const [toggle, setToggle] = useState(0)
  const [ticketID, setTicketID] = useState("")
  const [ID, setID] = useState("")
  // const [deactivate, setDeactivate] = useState(false)
  // const [reject, setReject] = useState(false)
  const [cardHeight, setCardHeight] = useState(true)

  const {supportTicketsMeta} = useSelector((state) => state.support)

  const dispatch = useDispatch();
  const { FetchSupportTicket, GetInternalUser } = bindActionCreators(SupportActions, dispatch);

  useEffect(() => {
    FetchSupportTicket()
    GetInternalUser()

    //eslint-disable-next-line
  }, [ticketID])
  const handleToggle = () => {
    if (toggle === 1) {
      return <div className="support-ticket-comment-detail"><ViewSupportTicket setID={setID} id= {ID} setToggle={setToggle} />
      </div>
    }
    if (toggle === 2) {
      return <div className="support-ticket-comment-detail"><Comments setToggle={setToggle} id={ID} setID={setID} />
      </div>
    }
    if (toggle === 3) {
      return <div className="support-ticket-comment-detail"><ReasignTicket setToggle={setToggle} id={ID} setTicketID={setTicketID} />
      </div>
    }
    if (toggle === 4) {
      return <div className="support-ticket-comment-detail"><VendorSuccessNotify notify="Ticket has been successfully reassigned" setToggle={setToggle} />
      </div>
    }
    if (toggle === 5) {
      return <div className="support-ticket-comment-detail"><UserControlOnboarding notify={`Complaint has been escalated with Ticket Id:
      ${ticketID}`} setToggle={setToggle} />
      </div>
    }

    if (toggle === 6) {
      return <div className="support-ticket-comment-detail"><UpdateTicket setTicketID={setTicketID} id={ID} setToggle={setToggle} />
      </div>
    }

    if (toggle === 7) {
      return <div className="support-ticket-comment-detail"><UserControlOnboarding notify={`Complaint with ticket ID:
      ${ticketID} has been updated successfully`} setToggle={setToggle} />
      </div>
    }
    if (toggle === 8) {
      return <div className="support-ticket-comment-detail"><VendorSuccessNotify
      setToggle={setToggle}
      notify="Ticket has been successfully resolved"
    />
      </div>
    }
    if (toggle === 9) {
      return <div className="support-ticket-comment-detail"><VendorSuccessNotify
      setToggle={setToggle}
      notify="comment added successfully"
    />
      </div>
    }
    if (toggle === 0) {
      return null
    }
  }


  const handleEscalate = () => {
    if (!escalate) {
      setEscalate((c) => (c = true));
    } else {
      setEscalate((c) => (c = false));
    }
  };

  // const [scrollheight, setScrollheight] = useState("89px")

  const supportData = [
    // {
    //   id: 1,
    //   text: "Live Chat Support",
    //   num: 3,
    // },
    {
      id: 2,
      text: "Support Ticket",
      num: supportTicketsMeta ? supportTicketsMeta.total : 0,
    },
    // {
    //   id: 3,
    //   text: "Pending Approval",
    //   num: 2,
    // },
  ];

  function handleClick(id) {
    setActive(id);
    setCardHeight(false)
    if (id === 1) {
      setSubUrl("/Live Chat Support");
    } else if (id === 2) {
      setSubUrl("/Support Tickets");

    } else if (id === 3) {
      setSubUrl("/Pending Approval");
    }

  }

  function handleModalUpload() {
    setModalUpload(!modalUpload)
  }
  function handleModalUpload2() {
    setModalUpload2(!modalUpload2)
  }
  function renderTable() {
    switch (active) {
      case 0:
        return <SupportProfileSearch setCardHeight={setCardHeight} />;
      // case 1:
        // return <LiveChat handleEscalate={handleEscalate} />;
      case 2:
        return <SupportTicket setID={setID} setToggle={setToggle} handleEscalate={handleEscalate} />;
      // case 3:
        // return <PendingApproval handleModalUpload={handleModalUpload} handleModalUpload2={handleModalUpload2} />

      default:
        return null;
    }
  }
  return (
    <div className="vendor-container">
      {handleToggle()}
      <div className=" root-nav vendor-siderbar">
        <Nav Active={3}/>
      </div>
      {modalUpload && <div className="uploadModalCont" >
        <ModalUpload handleModalUpload={handleModalUpload} />
      </div>}
      {modalUpload2 && <div className="uploadModalCont" >
        <UploadModalID handleModalUpload={handleModalUpload2} />
      </div>}


      <div className="right-content chat-wrap">
        <div>
          <KYCTOP url="Support" suburl={subUrl} />
        </div>
        <div className="chat-wrapper">
          {cardHeight ? (
            <div className="chat-vendor-card">
              {supportData.map((item, index) => (
                <div
                  className={active === item.id ? `vcardd active bb` : `vcardd`}
                  key={index}
                  onClick={() => handleClick(item.id)}
                >
                  <p>{item.text}</p>
                  <div className="vcspace">
                    <h2>{item.num}</h2>
                    <p>Total Records</p>

                  </div>
                </div>
              ))}
            </div>) : (
            <div className="chat-vendor-card2">
              {supportData.map((item, index) => (
                <div
                  className={active === item.id ? `vcardd2 active bb` : `vcardd2`}
                  key={index}
                  onClick={() => handleClick(item.id)}
                >
                  <p>{item.text}</p>
                  <div className="vcspace">
                    <h2>{item.num}</h2>
                  </div>
                </div>
              ))}
            </div>)}
          <div className="support-renderTable">
            {renderTable()}
          </div>

        </div>
      </div>

      {escalate ? (<div className="live-chat-escalate-b"> <Escalate setToggle={setToggle} onCancel={handleEscalate} setTicketID={setTicketID} /></div>) : null}

    </div>
  );
};

// export function uploadModalCont(){
//   const [] = useState()
//   return (
//     <div className="uploadModalCont">
//              {modalUpload && <ModalUpload/>}
//       </div>
//   )
// }