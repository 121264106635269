import "../../css/line-chart.css";
import React, { useEffect, useState } from "react";
// import { Area } from '@ant-design/plots';
import "charts.css"
// import { useDispatch, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { dashboardActions } from "../../state/actions";
import moment from "moment";

export default function LineChart() {

  // const { data, analytics } = useSelector((state) => state.dashboard);
   const {data,analytics} = {
    data: {

      user_count: 19873,
      daily_user_count: 8045,
      weekly_user_count: 10927,
      monthly_count: 14052,
      active_user_count: 15683,
      address_verification: {
        total_count: 18,
        approved_count: 4,
        pending_count: 0
      },
      bills: {
        airtime_bill_count:43,
        other_bill_count:61,
        total_count:61,
        tv_bill_count: 1
      },
      cash_request:{
        pending_cash_request_count: 14,
        processed_cash_request_count: 109,
        total_count: 123
      },
      complaints:{
        pending_count:1,
        resoled_count:0,
        total_count:1
      },
      current_active_cash_provider: 1,
      inactive_user_count: 3190,
      kyc: {
        agent_level :4,
        level_one :2,
        level_two :1,
        level_zero :2,
        pending_kyc_count :1,
        total_count :6
      },
      transaction:{
        failed_count: 18,
        pending_count: 214,
        success_count: 7122,
        total_count: 7354,
        total_volume: 12457980.00
    },
  },
    analytics:{
 
        firstWeek: 48,
        secondWeek: 59,
        thirdWeek: 73,
        fourthWeek: 101,
        total_user_count_on_duration: 281
    }
  };
  const dispatch = useDispatch();

  const { LoadDashboardAnalytics } = bindActionCreators(
    dashboardActions,
    dispatch
  );

  const [interval, setInterval] = useState("2")
  // const [newCustomer, setNewCustomer] = useState(analytics ? analytics.total_user_count_on_duration : 0)

  const first = analytics ? (interval === "0" ? analytics.firstHours : ( interval === "1" ? analytics.monday : (interval === "2" ? analytics.firstWeek : 0))) : 0
  const second = analytics ? (interval === "0" ? analytics.secondHours : ( interval === "1" ? analytics.tuesday : (interval === "2" ? analytics.secondWeek : 0))) : 0
  const third = analytics ? (interval === "0" ? analytics.thirdHours : ( interval === "1" ? analytics.wednesday : (interval === "2" ? analytics.thirdWeek : 0))) : 0
  const fourth = analytics ? (interval === "0" ? analytics.otherHours : ( interval === "1" ? analytics.thursday : (interval === "2" ? analytics.fourthWeek : 0))) : 0

  const now = new Date()
  const today = new Date();
  const f = today.getDate() - today.getDay() + 1;
  const firstTitle = interval === "0" ? moment(now.setHours(1,0,0,0)).format('DD/MM/-hh:mm A') : ( interval === "1" ? moment(new Date(today.setDate(f))).format('DD/MM/YYYY') : (interval === "2" ? moment().format('MMM,YYYY-')+'(Wk 1)' : 0))
  const secondTitle = interval === "0" ? moment(now.setHours(3,0,0,0)).format('DD/MM/-hh:mm A') : ( interval === "1" ? moment(new Date(today.setDate(f + 1))).format('DD/MM/YYYY') : (interval === "2" ? moment().format('MMM,YYYY-')+'(Wk 2)' : 0))
  const thirdTitle = interval === "0" ? moment(now.setHours(8,0,0,0)).format('DD/MM/-hh:mm A') : ( interval === "1" ? moment(new Date(today.setDate(f + 2))).format('DD/MM/YYYY') : (interval === "2" ? moment().format('MMM,YYYY-')+'(Wk 3)' : 0))
  const fourthTitle = interval === "0" ? moment(now.setHours(23,0,0,0)).format('DD/MM/-hh:mmA') : ( interval === "1" ? moment(new Date(today.setDate(f + 3))).format('DD/MM/YYYY') : (interval === "2" ? moment().format('MMM,YYYY-')+'(Wk 4)' : 0))

  const total = analytics ? analytics.total_user_count_on_duration : 0

  useEffect(() => {
    if (interval === "0") {
      LoadDashboardAnalytics("daily")
    } else if (interval === "1") {
      LoadDashboardAnalytics("weekly")
    } else {
      LoadDashboardAnalytics("monthly")
    }

    //eslint-disable-next-line
  }, [interval])

  const onChange = (e) => {
    // const value = e.target.value
    // let newCust = newCustomer
   
    // if (value === "0") {
    //   newCust = analytics ? analytics.total_user_count_on_duration : 0
    // } else if (value === "1") {
    //   newCust = analytics ? analytics.total_user_count_on_duration : 0
    // } else {
    //   newCust = analytics ? analytics.total_user_count_on_duration : 0
    // }
    // setNewCustomer(analytics ? analytics.total_user_count_on_duration : 0)
    setInterval(e.target.value)
  }

  // static dummy data..
  const total_transaction_count = Math.round(data.transaction.total_volume/1000)
  const total_active =  Math.round(2/3 *  total_transaction_count)
  const total_customer = Math.round(total_active + (1/5 * total_active))

  return (
    <div className="line-chart-section dashboard-card-color">
      <div className="line-chart-title-section">
        <p className="line-chart-title">Customer Growth Insight</p>
        <select value={interval} onChange={onChange}>
          <option value={0}>Daily</option>
          {/* <option value={1}>Weekly</option>
          <option value={2}>Monthly</option> */}
        </select>
      </div>
      <div className="line-chart-details">
        <div className="chart-details">
          <p className="line-chart-sub-title">Total Customer</p>
          {/* <h1>{data ? data.user_count : 0}</h1> */}
          <h1>{total_customer}</h1>

        </div>
        <div>
          <p className="line-chart-sub-title">New Customer</p>
          {/* <h1>{analytics ? analytics.total_user_count_on_duration : 0}</h1> */}
          <h1>81</h1>
        </div>
      </div>
      {/* <h5>3% growth in the past 5 weeks</h5> */}
      <div className="line-chart-area">
        <DemoArea first={first} second={second} third={third} fourth={fourth} total={total} firstTitle={firstTitle} secondTitle={secondTitle} thirdTitle={thirdTitle} fourthTitle={fourthTitle} />
      </div>
    </div>
  );
}

const DemoArea = ({first, second, third, fourth, total, firstTitle, secondTitle, thirdTitle, fourthTitle}) => {
  const firstRange = total > 0 ? first/total : 0
  const secondRange = total > 0 ? second/total : 0
  const thirdRange = total > 0 ? third/total : 0
  const fourthRange = total > 0 ? fourth/total : 0
  return <>
    <table class="charts-css area" id="my-chart">

      <tbody>
        <tr>
          <td style={{ "--start": 0.1, "--size": firstRange }}> <span class="data"> {first} new user(s) {firstTitle} </span> </td>
        </tr>
        <tr>
          <td style={{ "--start": firstRange, "--size": secondRange }}> <span class="data">{second} new user(s) {secondTitle}  </span> </td>
        </tr>
        <tr>
          <td style={{ "--start": secondRange, "--size": thirdRange }}> <span class="data"> {third} new user(s) {thirdTitle}  </span> </td>
        </tr>
        <tr>
          <td style={{ "--start": thirdRange, "--size": fourthRange }}> <span class="data"> {fourth} new user(s)   {fourthTitle}  </span> </td>
        </tr>
        <tr>
          <td style={{ "--start": fourthRange, "--size": 0.1 }}> </td>
        </tr>
      </tbody>
    </table>
  </>
}

// const DemoArea = ({data}) => {

//   const config = {
//     data,
//     xField: 'timePeriod',
//     yField: 'value',
//     xAxis: {
//       range: [0, 1],
//     },
//   };

//   return <Area {...config} />;
// };
