import * as types from "../types";

import axios from "../../axios";
import moment from "moment";

export const FetchCashRequests = (page) => async (dispatch) => {
  setloading();
  page = page || 1;
  try {
    const res = await axios.get(
      `/transactions?model_type=App%5CModels%5CCashRequest&page%5Bnumber%5D=${page}&include=source,cashRequest&filter[status]=success`
    );
    dispatch({
      type: types.FETCH_CASH_REQUEST,
      payload: res.data.data.transactions.data,
      meta: res.data.data.transactions.meta,
    });
  } catch (err) {
    dispatch({
      type: types.FETCH_CASH_REQUEST_ERROR,
      payload: { top: err.response.data.error.message },
    });
  }
};

export const FetchBills = (page) => async (dispatch) => {
  setloading();

  page = page || 1;

  try {
    const res = await axios.get(
      `/transactions?model_type=App%5CModels%5CBill&page%5Bnumber%5D=${page}&include=source`
    );
    dispatch({
      type: types.FETCH_BILL_HISTORY,
      payload: res.data.data.transactions.data,
      meta: res.data.data.transactions.meta,
    });
  } catch (err) {
    dispatch({
      type: types.FETCH_CASH_REQUEST_ERROR,
      payload: { top: err.response.data.error.message },
    });
  }
};

export const FetchBillsWithDate = (page, date, date1) => async (dispatch) => {
  setloading();

  page = page || 1;

  date =
    (date && moment(date).format("YYYY-MM-DD")) ||
    moment(new Date()).format("YYYY-MM-DD");

  date1 =
    (date1 && moment(date1).format("YYYY-MM-DD")) ||
    moment(new Date()).format("YYYY-MM-DD");

  try {
    const res = await axios.get(
      `/transactions?model_type=App%5CModels%5CBill&page%5Bnumber%5D=${page}&include=source&filter[created_between]=${date}&filter[created_between]=${date1}`
    );
    dispatch({
      type: types.FETCH_BILL_HISTORY,
      payload: res.data.data.transactions.data,
      meta: res.data.data.transactions.meta,
    });
  } catch (err) {
    dispatch({
      type: types.FETCH_CASH_REQUEST_ERROR,
      payload: { top: err.response.data.error.message },
    });
  }
};

export const FetchCashRequestWithData =
  (page, date, date1) => async (dispatch) => {
    setloading();

    page = page || 1;

    date =
      (date && moment(date).format("YYYY-MM-DD")) ||
      moment(new Date()).format("YYYY-MM-DD");

    date1 =
      (date1 && moment(date1).format("YYYY-MM-DD")) ||
      moment(new Date()).format("YYYY-MM-DD");

    try {
      const res = await axios.get(
        `/transactions?model_type=App%5CModels%5CCashRequest&page%5Bnumber%5D=${page}&include=source&filter[created_between]=${date}&filter[created_between]=${date1}&filter[status]=success`
      );
      dispatch({
        type: types.FETCH_CASH_REQUEST,
        payload: res.data.data.transactions.data,
        meta: res.data.data.transactions.meta,
      });
    } catch (err) {
      dispatch({
        type: types.FETCH_CASH_REQUEST_ERROR,
        payload: { top: err.response.data.error.message },
      });
    }
  };

export const FetchFailedTransactions = (page) => async (dispatch) => {
  setloading();
  page = page || 1;
  try {
    const res = await axios.get(
      `/transactions?model_type=App%5CModels%5CCashRequest&page%5Bnumber%5D=${page}&include=source&filter[status]=failed`
    );
    dispatch({
      type: types.FETCH_FAILED_TRANSACTION,
      payload: res.data.data.transactions.data,
      meta: res.data.data.transactions.meta,
    });
  } catch (err) {
    dispatch({
      type: types.FETCH_FAILED_TRANSACTION_ERROR,
      payload: { top: err.response.data.error.message },
    });
  }
};

export const DownloadFailedTransactions = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `/transactions/export?type=App%5CModels%5CCashRequest&filter[status]=failed`
    );
    // console.log(res);
    const blob = new Blob([res.data], { type: "application/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `failed-transaction-${+new Date()}.csv`;
    link.click();
  } catch (err) {
    dispatch({
      type: types.FETCH_FAILED_TRANSACTION_ERROR,
      payload: { top: err.response.data.error.message },
    });
  }
};

export const DownloadBillHistory = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `/transactions/export?type=App%5CModels%5CBill`
    );
    // console.log(res);
    const blob = new Blob([res.data], { type: "application/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `bill-history-${+new Date()}.csv`;
    link.click();
  } catch (err) {
    dispatch({
      type: types.FETCH_FAILED_TRANSACTION_ERROR,
      payload: { top: err.response.data.error.message },
    });
  }
};

export const DownloadCashRequest = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `/transactions/export?type=App%5CModels%5CCashRequest`
    );
    // console.log(res);
    const blob = new Blob([res.data], { type: "application/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `cash-request-${+new Date()}.csv`;
    link.click();
  } catch (err) {
    dispatch({
      type: types.FETCH_FAILED_TRANSACTION_ERROR,
      payload: { top: err.response.data.error.message },
    });
  }
};

export const DownloadBills = () => async (dispatch) => {
  try {
    await axios.get(`/transactions/export?type=App%5CModels%5CBill`);
  } catch (err) {
    dispatch({
      type: types.FETCH_FAILED_TRANSACTION_ERROR,
      payload: { top: err.response.data.error.message },
    });
  }
};

export const SearchFailedTransactions = (search, page) => async (dispatch) => {
  setloading();
  page = page || 1;
  try {
    const res = await axios.get(
      `/transactions?model_type=App%5CModels%5CCashRequest&page%5Bnumber%5D=${page}&filter[term]=${search.trim()}&include=source&filter[status]=failed`
      // "&filter[status]=${search}&filter[amount]=${search}"
    );
    dispatch({
      type: types.SEARCH_FAILED_TRANSACTION,
      payload: res.data.data.transactions.data,
      meta: res.data.data.transactions.meta,
    });
  } catch (err) {
    dispatch({
      type: types.FETCH_FAILED_TRANSACTION,
      payload: { top: err.response.data.error.message },
    });
  }
};

export const SearchBillHistory = (search, page) => async (dispatch) => {
  setloading();
  page = page || 1;
  try {
    const res = await axios.get(
      `/transactions?model_type=App%5CModels%5CBill&page%5Bnumber%5D=${page}&filter[term]=${search.trim()}&include=source`
      // "&filter[status]=${search}&filter[amount]=${search}"
    );
    dispatch({
      type: types.SEARCH_BILL_HISTORY,
      payload: res.data.data.transactions.data,
      meta: res.data.data.transactions.meta,
    });
  } catch (err) {
    dispatch({
      type: types.FETCH_FAILED_TRANSACTION,
      payload: { top: err.response.data.error.message },
    });
  }
};

export const searchCashRequest = (search, page) => async (dispatch) => {
  setloading();
  page = page || 1;
  try {
    const res = await axios.get(
      `/transactions?model_type=App%5CModels%5CCashRequest&page%5Bnumber%5D=${page}&filter[term]=${search.trim()}&include=source&filter[status]=success`
      // "&filter[status]=${search}&filter[amount]=${search}"
    );
    dispatch({
      type: types.SEARCH_CASH_REQUEST,
      payload: res.data.data.transactions.data,
      meta: res.data.data.transactions.meta,
    });
  } catch (err) {
    dispatch({
      type: types.FETCH_FAILED_TRANSACTION,
      payload: { top: err.response.data.error.message },
    });
  }
};

export const setloading = () => (dispatch) => {
  dispatch({
    type: types.SET_LOADING,
  });
};
