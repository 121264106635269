import "../css/logo.css";
import logo from "../img/logo.svg";
export default function Logo() {
  return (
    <div className="landing-logo">
      <img height="50px" src={logo} alt="logo" />
    </div>
  );
}

// function LogoSvg (){
//   return(
//     <div></div>
//   )
// }
