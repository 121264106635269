import { useState } from "react";
import "./input.scss";

export default function KycInput({ label, searchValue }) {
  const [search, setSearch] = useState("");

  const handleSearch = (value) => {
    setSearch(value);
    searchValue(value);
  };
  return (
    <>
      <div class="form__group field">
        <input
          type="text"
          class="form__field"
          placeholder="Name"
          name="name"
          id="name"
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <label for="name" class="form__label">
          {label ? label : `Name`}
        </label>
      </div>
    </>
  );
}

export const SettlementInput = ({ label, searchValue, search, setSearch }) => {
  // const [search, setSearch] = useState("");

  const handleSearch = (value) => {
    // e.preventDefault();
    searchValue(value);
    setSearch(value);
  };
  return (
    <>
      <div class="form__group field">
        {/* <form onSubmit={handleSearch}> */}
        <input
          type="text"
          class="form__field"
          placeholder="Name"
          name="name"
          id="name"
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <label for="name" class="form__label">
          {label ? label : `Name`}
        </label>
        {/* </form> */}
      </div>
    </>
  );
};

export const SupportTicketInput = ({ label, searchValue, search, setSearch }) => {
  // const [search, setSearch] = useState("");

  const handleSearch = (value) => {
    // e.preventDefault();
    searchValue(value);
    setSearch(value);
  };
  return (
    <>
      <div class="form__group field">
        {/* <form onSubmit={handleSearch}> */}
        <input
          type="text"
          class="form__field"
          placeholder="Name"
          name="name"
          id="name"
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <label for="name" class="form__label">
          {label ? label : `Name`}
        </label>
        {/* </form> */}
      </div>
    </>
  );
};

export const SupportProfileSearchInput = ({ label, searchValue, search, setSearch, id }) => {
  // const [search, setSearch] = useState("");

  const handleSearch = (value) => {
    // e.preventDefault();
    searchValue(id, value);
    setSearch(value);
  };
  return (
    <>
      <div class="form__group field">
        {/* <form onSubmit={handleSearch}> */}
        <input
          type="text"
          class="form__field"
          placeholder="Name"
          name="name"
          id="name"
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <label for="name" class="form__label">
          {label ? label : `Name`}
        </label>
        {/* </form> */}
      </div>
    </>
  );
};

export const KycLevelsInput = ({ label, searchValue, search, setSearch }) => {
  // const [search, setSearch] = useState("");

  const handleSearch = (value) => {
    // e.preventDefault();
    searchValue(value);
    setSearch(value);
  };
  return (
    <>
      <div class="form__group field">
        {/* <form onSubmit={handleSearch}> */}
        <input
          type="text"
          class="form__field"
          placeholder="Name"
          name="name"
          id="name"
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <label for="name" class="form__label">
          {label ? label : `Name`}
        </label>
        {/* </form> */}
      </div>
    </>
  );
};
