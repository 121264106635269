import { Link } from "react-router-dom";

function RoleDropDown({ setToggle, id, name }) {
  const changeToggle = (value) => {
    setToggle(value, id);
  };

  return (
    <div className="btn-group">
      <button
        type="button"
        className="btn btn-default"
        style={{
          width: "35px",
          height: "35px",
          backgroundColor: "darkblue",
          borderRadius: "100%",
          color: "white",
        }}
        data-toggle="dropdown"
        aria-expanded="false"
      >
        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
      </button>
      <div className="dropdown-menu">
        <Link
          className="dropdown-item"
          to={`/edit-roles-permission/${btoa(id)}`}
        >
          View Details
        </Link>

        {(name !== "user" && name !== "super-admin" && name !== "admin") && (
          <>
            <div class="dropdown-divider"></div>
            <span
              style={{cursor: "pointer"}}
              className="dropdown-item"
              onClick={() => {
                changeToggle(8);
              }}
            >
              Delete Entry
            </span>
          </>
        )}
      </div>
    </div>
  );
}

export default RoleDropDown;
