import { useState } from 'react'

import "../../css/upload-modal.css"
import Upload from "../../img/upload.png"
import CloseBtn from "../../img/Close-Square.svg";
import VendorSuccessNotify from '../vendorModals/vendorSuccessNotification';
import RejectModal from '../vendorModals/rejectModal';
import UploadModalID from './upload-modal-id';

const UploadModal = ({ handleModalUpload }) => {
  const [active, setActive] = useState("")
  const [modal, setModal] = useState(0)
  const closeModal = () => {
    setActive("close-modal")
    handleModalUpload()
  }
  const renderDom = () => {
    if (modal === 0) {
      return (<>
        <div className={`upload-container ${active} `}>
          <div className='umCont'>
            <div className={`upload-modal`}>
              <div className="upload-header">
                <div className='header-right'>Passport Upload</div>
                <div className='header-left'>
                  <img src={CloseBtn} onClick={closeModal} alt="" ></img>
                </div>

              </div>
              <div className="uplaod-body">
                <div className="body-1">
                  <p style={{
                    width: "150px", fontWeight: "400",
                    fontSize: "15px", color: "#162082"
                  }}>BVN  Picture</p>
                  <div className="body-11">
                    <img src={Upload} alt="upload" />
                  </div>

                </div>
                <div className="body-2">
                  <p style={{
                    width: "150px", fontWeight: "400",
                    fontSize: "15px", color: "#FC3131"
                  }}>Upload Passport</p>
                  <div className="body-22">
                    <img src={Upload} alt="upload" />
                  </div>
                </div>
              </div>
              <div className="upload-footer">
                <a href="#approve" className="footer-button" onClick={() => setModal(1)}>
                  Approve
                </a>
                <a href="#reject" className="footer-button-2" style={{ marginLeft: "15px" }} onClick={() => setModal(2)}>
                  Reject
                </a>
              </div>
            </div>
          </div>
        </div>
      </>)
    }
    if (modal === 1) {
      return <div className={`display-deactivate close-deactivate ${active}`}>
        <VendorSuccessNotify handleModalUpload={closeModal} notify="Document upload has been successfully approved" />
      </div>
    }
    if (modal === 2) {
      return <div className="display-deactivate close-deactivate">
        <RejectModal handleModalUpload={closeModal} notify="Document upload has been successfully rejected" />
      </div>
    }
    if (modal === 3) {
      return <div className="display-deactivate close-deactivate">
        <UploadModalID handleModalUpload={closeModal} />
      </div>
    }
  }
  return (
    <>
      <div className={`upload-containe ${active}`}>
        {renderDom()}
      </div>

    </>

  )
}

export default UploadModal