import "../../css/btn/toggle.css";
export default function Toggle({ checked, setRemember }) {
  return (
    <div>
      <label class="switch">
        <input type="checkbox" checked={checked} onClick={() => setRemember(!checked)} />
        <span class="slider round"></span>
      </label>
    </div>
  );
}