import { useState } from "react";

import CloseBtn from "../../img/Close-Square.svg";
import ArrowBtn from "../../img/arrow.svg";
import "../../css/upload-document/upload-document.css";
import UploadDocumentModal from "./upload-document-modal";
import UploadDocumentMain from "./upload-document-main";

const UploadDocument = ({ setToggle, id }) => {
  const [display, setDisplay] = useState("");
  const [options, setOptions] = useState(false);
  const [cardType, setCardType] = useState("");
  const closeDisplay = () => {
    setDisplay("close");
    setToggle(0);
  };

  const setCard = (text) => {
    setOptions(!options);
    setCardType(text);
  };
  return (
    <>
      <div className={`uploadDoc-container ${display}`}>
        <div className="uploadDoc-card-container">
          <div>
            <UploadDocumentModal options={options} setCardType={setCard} />
          </div>
          <div className="uploadDoc-card-header">
            <div className="uploadDoc-card-title">Upload Document</div>
            <div
              style={{ cursor: "pointer" }}
              className=""
              onClick={closeDisplay}
            >
              <img
                src={CloseBtn}
                loading="lazy"
                width="24"
                height="24"
                alt="cancel icon"
                onClick={closeDisplay}
              />
            </div>
          </div>
          <div className="uploadDoc-card-body">
            <div className="uploadDoc-card-content">
              <label
                className="uploadDoc-card-body-label"
                style={{ marginRight: "63px" }}
              >
                Document Type
              </label>
              <div
                className="uploadDoc-card-body-input"
                onClick={() => {
                  setOptions(!options);
                }}
              >
                <div className="input-placeholder">
                  {cardType === "" ? (
                    "Select Document to upload"
                  ) : (
                    <div className="uploadDoc-optinValue">{cardType}</div>
                  )}
                </div>

                <div>
                  <img
                    src={ArrowBtn}
                    loading="lazy"
                    width="35"
                    height="35"
                    alt="cancel icon"
                  />
                </div>
              </div>
            </div>
          </div>
          
          {cardType && (
            <UploadDocumentMain
              id={id}
              closeDisplay={closeDisplay}
              setToggle={setToggle}
              cardType={cardType}
            />
          )}
          {cardType === "" ? (
            <div className="uploadDoc-card-footer">
              <button
                className="uploadDoc-card-footer-botton"
                onClick={closeDisplay}
              >
                Close
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default UploadDocument;
