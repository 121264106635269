import { useEffect, useState } from "react";
// import pdf from "../../img/pdf-icon.svg";
import "./view-support-ticket.css";
import CloseBtn from "../../img/Close-Square.svg";
// import VendorSuccessNotify from "../../components/vendorModals/vendorSuccessNotification";

import axios from "../../axios";
import Moment from "react-moment";

const ViewSupportTicket = ({ setToggle, id, setID }) => {
  // const [active, setActive] = useState("");
  const [modal, setModal] = useState(0);
  const [loading, setLoading] = useState(true)

  const closeModal = () => {
    
    setID("")
    setModal(0)
    setToggle(0);
  };

  const [ticket, setTicket] = useState(null)

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`/tickets/${id}`);
       setTicket(res.data.data.ticket);
       
      } catch (err) {}
      setLoading(false)
    })();

    // eslint-disable-next-line
  }, []);

  const ResolveTicket = async () => {
    try {
      await axios.patch(`/tickets/${id}`, {
        customer_telephone: ticket.customer.telephone,
        internal_user_id: ticket.internal_user.id,
        priority_level: ticket.priority_level,
        description: ticket.description,
        status: "processed"
      });
      // console.log(res.data.data.internal_users)
      setToggle(8);
    } catch (err) {
      setModal(2)
    }
  }
  const renderDom = () => {
    if (modal === 0) {
      return (
        <>
          <div className="view-support-ticket-container">
            <div className="view-support-ticket-umCont">
              <div className={`view-support-ticket-modal`}>
                <div className="view-support-ticket-header">
                  <div className="view-support-ticket-header-left">
                    <img src={CloseBtn} onClick={closeModal} alt=""></img>
                  </div>
                </div>
                <div className="view-support-ticket-body">
                  <div className="view-body-1">
                    {/* <div className="view-title-con">
                      <h3 className="view-title">Title</h3>
                      <p>Cash Deposit Not Reflecting in Wallet</p>
                    </div> */}
                    <h4 className="view-desc">Description</h4>
                    <div className="view-support-ticket-body-1">
                      <div className="view-support-ticket-body-1-p">
                      <p>
                        {ticket && ticket.description}
                      </p>
                      </div>
                      <div className="text-box-footer">
                        {/* <div className="inner-p">
                          <span className="inner-para-1">
                            <img src={pdf} alt="pdf icon"></img>{ticket && ticket.customer && ticket.customer.name}
                          </span>
                        </div> */}
                        <span className="inner-para" style={{cursor: "pointer"}} onClick={() => setToggle(2)}>View Comments</span>
                      </div>
                    </div>
                    <div className="view-support-ticket-body-text">
                      <div className="view-support-ticket-inputs">
                        <h5>Customer Name</h5>
                        <p>{ticket && ticket.customer && ticket.customer.name}</p>
                        <h5>Priority Level</h5>
                        <p>{ticket && ticket.priority_level}</p>
                        <h5>Escalated to</h5>
                        <p>{ticket && ticket.internal_user && ticket.internal_user.name}</p>
                      </div>

                      <div className="view-support-ticket-inputs-2">
                        <h5>Phone Number</h5>
                        <p>{ticket && ticket.customer && ticket.customer.telephone}</p>
                        <h5>Date Created</h5>
                        <p><Moment format="DD/MM/YYYY hh:mma">{ticket && ticket.created_at}</Moment></p>
                        <h5>Created by</h5>
                        <p>{ticket && ticket.author && ticket.author.name}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="view-support-ticket-footer">
                  <button
                    className={`view-support-ticket-footer-button ${loading ? "btn-default" : ""}`}
                    onClick={ResolveTicket}
                    disabled={loading}
                  >
                    Resolve
                  </button>
                  <button
                    className="view-support-ticket-footer-button-2"
                    style={{ marginLeft: "15px" }}
                    onClick={closeModal}
                  >
                    close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  
  };
  return (
    <>
      <div >{renderDom()}</div>
    </>
  );
};

export default ViewSupportTicket;
