import React, {useState } from "react";
import axios from "../../axios";
import "./css/faq.css";
import KYCTOP from "../../components/kyc-top/kyc-top";
import Nav from "../../components/nav";
// import { IoImageOutline } from "react-icons/io5";
import AppNotificationModal, { RoleCreationError } from "../../components/vendorModals/appNotificationModal";
import { useNavigate } from "react-router-dom";

function FrequentlyAskedQuestion() {
  // const file = useRef();
  // const [selectedFile, setSelectedFile] = useState(null);
  // const [fileName, setFileName] = useState("");
  const [toggle, setToggle] = useState(0);

  const [error, setError] = useState({});

  // const [files, setFiles] = useState(null);
  const [fields, setFields] = useState({
    question: "",
    propose_solution: "",
    active_platform: "App",
    status: "active",
  });

  function handleChange(e) {
    const { name, value } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  const navigate = useNavigate();
  // const onUpload = () => {
  //   file.current.click();
  // };

  // const onFileUpload = (e) => {
  //   setSelectedFile(e.target.files[0]);
  //   if (e.target.files[0]) {
  //     // setFileName(e.target.files[0].name);
  //     setFiles(e.target.files[0]);
  //   }

  //   // console.log(selectedFile);
  // };

  const handleClick = (e) => {
    e.preventDefault();
    if (fields.question.trim() === "") {
      setError({ question: "This field is required" });
    } else if (fields.propose_solution.trim() === "") {
      setError({ propose_solution: "This field is required" });
    } else {
      let formData = new FormData();
      // formData.append("image", files);
      for (const val in fields) {
        formData.append(`${val}`, fields[val]);
      }
      axios
        .post("/faq", formData, {
          header: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setToggle(1);
        })
        .catch((err) => {
          if (err.response.status === 500 || err.response.status === 404) {
            setError({top: err.response.data.error.message});
          } else {
            setError({top: err.response.data.error.message.join("\n")});
          }
          setToggle(2)
        });
    }
  };

  const handleToggle = () => {
    if (toggle === 0) {
      return null;
    }
    if (toggle === 1) {
      return (
        <div className="close-deactivate">
          <AppNotificationModal
            notify={"You have successfully created a new Faq Notification"}
            setToggle={setToggle}
          />
        </div>
      );
    }
    if (toggle === 2) {
      return (
        <div className="close-deactivate">
          <RoleCreationError
            notify={`OOPS! Something went wrong, Please try again"`}
            setToggle={setToggle}
            close="close"
          />
        </div>
      );
    }
  };

  return (
    <div className="faq-section">
      <div className="faq-app-nav">
        <Nav Active={6} />
      </div>
      <div className="right-content faq-notification-wrapper">
        <div className="faq-app-top">
          <KYCTOP url="App Contents" suburl="/FAQ" />{" "}
        </div>
        <form onSubmit={handleClick}>
          <div className="faq-container">
            <div className="faq-header">
              <h2>New Item</h2>
            </div>
            {error.top && <span className="text-danger">{error.top}</span>}

            <div className="faq-title">
              <h4>Frequently Asked Question</h4>
              <input
                type="text"
                name="question"
                id="name"
                value={fields.question}
                onChange={handleChange}
                placeholder="Enter question"
              />
            </div>
            {error.question && <span className="text-danger">{error.question}</span>}

            <div className="faq-message">
              <h4>Prosposed Solution</h4>
              <textarea
                name="propose_solution"
                id=""
                cols="30"
                rows="15"
                value={fields.propose_solution}
                onChange={handleChange}
                placeholder="Enter answer to the FAQ"
              ></textarea>
            </div>
            {error.propose_solution && <span className="text-danger">{error.propose_solution}</span>}
           
            {/* <div className="new-article-content-1">
              <div className="article-cont-1">
                <h5>Attach Item Image</h5>
                <div className="article-cont-1-input" onClick={onUpload}>
                  <IoImageOutline size={20} />
                  <div className="article-input-placeholder">
                    {fileName === "" ? (
                      "Click here to attach image"
                    ) : (
                      <div style={{ width: "250px" }}>{fileName}</div>
                    )}
                  </div>
                  <input
                    type="file"
                    ref={file}
                    style={{ display: "none" }}
                    //   value={selectedFile}
                    onChange={onFileUpload}
                  ></input>
                </div>
              </div>
            </div> */}

            <div className="faq-content-2">
              <div>
                <h5>Active Platform</h5>
                <select className="article-cont-3" name="active_platform" value={fields.active_platform} onChange={handleChange}>
                <option value="App">App</option>
                <option value="Web">Web</option>
                <option value="Both">Both</option>
                </select>
              </div>
            </div>

            <div className="new-article-footer-3">
              <button
                className="new-article-footer-botton"
              >
                Create Item
              </button>
              <button
                onClick={() => navigate("/app-content")}
                className="new-article-footer-close"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
      {handleToggle()}
    </div>
  );
}

export default FrequentlyAskedQuestion;
