import React from 'react'
import "../../../css/support/profile-view-reactivate.css"
import CloseBtn from "../../../img/Close-Square.svg"
import ProfileActivate from "../../../img/profileactivate.svg"
import { useState } from "react"
import axios from "../../../axios"
import { useDispatch} from 'react-redux'
import { bindActionCreators } from 'redux'
import { SupportActions } from '../../../state/actions'



function ProfileViewReactivate({ setToggle, value, notify }) {

    const [display, setDisplay] = useState("")
    const closeDisplay = () => {
        setDisplay("close")
        setToggle(0)
    }

    return (
        <>
            <div className={`profile-view-reactivate-display-details ${display}`}>
                <div className={`profile-view-reactivate ${display}`}>
                    <div onClick={closeDisplay}><strong class="profile-view-reactivate-bold-text">Cancel</strong></div>
                    <div className="profile-view-reactivate-flexedcont"><img src={ProfileActivate} loading="lazy" width="63" height="63" alt="" className="profile-view-reactivate-image" />
                        <div className="profile-view-reactivate-text-block-2">{notify}</div>
                        <a href="#/" className="profile-view-reactivate-button profile-view-reactivate-w-button" onClick={() => { setToggle(value) }}><strong>Reactivate</strong></a>
                    </div>
                    <img src={CloseBtn} loading="lazy" width="24" height="24" alt="" class="profile-view-reactivate-image-2" onClick={closeDisplay} />
                </div>
            </div>
        </>
    )
}

export default ProfileViewReactivate;

export const SupportUserReactivate = ({ setToggle, value, notify, id }) => {

    const [display, setDisplay] = useState("")

    const dispatch = useDispatch();

  const { setStatus } = bindActionCreators(SupportActions, dispatch);

//   const {  } = useSelector(
//     (state) => state.userControl
//   );

    const closeDisplay = () => {
        setDisplay("close")
        setToggle(0)
    }

    const ReActivate = async () => {
        try {
            await axios.patch(`/users/${id}/status`, {
              status: "active",
              
            });
            setStatus("active")
            setToggle(value)
          } catch (err) {
            setToggle(13)
          }
    }

    return (
        <>
            <div className={`profile-view-reactivate-display-details ${display}`}>
                <div className={`profile-view-reactivate ${display}`}>
                    <div onClick={closeDisplay}><strong class="profile-view-reactivate-bold-text">Cancel</strong></div>
                    <div className="profile-view-reactivate-flexedcont"><img src={ProfileActivate} loading="lazy" width="63" height="63" alt="" className="profile-view-reactivate-image" />
                        <div className="profile-view-reactivate-text-block-2">{notify}</div>
                        <a href="#/" className="profile-view-reactivate-button profile-view-reactivate-w-button" onClick={ReActivate}><strong>Reactivate</strong></a>
                    </div>
                    <img src={CloseBtn} loading="lazy" width="24" height="24" alt="" class="profile-view-reactivate-image-2" onClick={closeDisplay} />
                </div>
            </div>
        </>
    )
}

export const SupportUserReactivateError = ({setToggle}) => {

    const [display, setDisplay] = useState("")
    const closeDisplay = () => {
        setDisplay("close")
        setToggle(0)
    }

    return (
        <>
            <div className={`profile-view-reactivate-display-details ${display}`}>
                <div className={`profile-view-reactivate ${display}`}>
                    <div onClick={closeDisplay}><strong class="profile-view-reactivate-bold-text">Cancel</strong></div>
                    <div className="profile-view-reactivate-flexedcont"><img src={ProfileActivate} loading="lazy" width="63" height="63" alt="" className="profile-view-reactivate-image" />
                        <div className="profile-view-reactivate-text-block-2">OOPS! Something went wrong</div>
                    </div>
                    <img src={CloseBtn} loading="lazy" width="24" height="24" alt="" class="profile-view-reactivate-image-2" onClick={closeDisplay} />
                </div>
            </div>
        </>
    )
}
