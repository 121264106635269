import React from "react";
import "./css/vendor-profile-reactivation.css";
import CloseBtn from "../../img/Close-Square.svg";
import ProfileActivate from "../../img/profileactivate.svg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { userControlActions } from "../../state/actions";

function VendorProfileReactivation({ setToggle, value, notify }) {
  const [display, setDisplay] = useState("");
  const [reason, setReason] = useState("");
  const [errorX, setErrorX] = useState("");

  const closeDisplay = () => {
    setDisplay("close");
    setToggle(0);
  };

  const dispatch = useDispatch();

  const { ReactivateUser } = bindActionCreators(userControlActions, dispatch);

  const { loading, userID, error } = useSelector((state) => state.userControl);

  const submitReason = () => {
    if (reason.trim() === "") {
      setErrorX("Please specify the reason for reactivation");
    } else {
      setErrorX("");
      ReactivateUser(userID, reason, value);
    }
  };

  return (
    <>
      <div className={`display-details ${display}`}>
        <div className={`profile-reactivate ${display}`}>
          <div onClick={closeDisplay}>
            <strong class="profile-reactivate-bold-text">Cancel</strong>
          </div>
          <div className="profile-reactivate-flexedcont">
            <img
              src={ProfileActivate}
              loading="lazy"
              width="63"
              height="63"
              alt=""
              className="profile-reactivate-image"
            />
            <div className="profile-reactivate-text-block-2">{notify}</div>
            <textarea
              className="profile-reactivate-textarea"
              name=""
              id=""
              cols="40"
              rows="6"
              placeholder="  This user is currently undergoing investigation by the Control Unit"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            ></textarea>
            {errorX && <span className="text-danger">{errorX}</span>}
            {error.active && (
              <span className="text-danger">{errorX.active}</span>
            )}
            <button
              className="profile-reactivate-button profile-reactivate-w-button"
              disabled={loading}
              onClick={submitReason}
            >
              <strong>Reactivate</strong>
            </button>
          </div>
          <img
            src={CloseBtn}
            loading="lazy"
            width="24"
            height="24"
            alt=""
            class="profile-reactivate-image-2"
            onClick={closeDisplay}
          />
        </div>
      </div>
    </>
  );
}

export default VendorProfileReactivation;
