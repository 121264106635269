export const USERNAME = "username"
export const TOKEN = "token"
export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"
export const LOGOUT_MESSAGE = "LOGOUT_ERROR"
export const LOGIN_ERROR = "LOGIN_ERROR"
export const CLEAR_LOCAL = "CLEAR_LOCAL"
export const SET_USER = "SET_USER"
export const SET_LOADING = "SET_LOADING"

// dashboard
export const LOAD_DASHBOARD = "LOAD_DASHBOARD"
export const LOAD_DASHBOARD_ANAYTICS = "LOAD_DASHBOARD_ANAYTICS"
export const LOAD_DASHBOARD_ERROR = "LOAD_DASHBOARD_ERROR"

// User control
export const FETCH_USERS = "FETCH_USERS"
export const FETCH_USERS_ERROR = "FETCH_USERS_ERROR"
export const FETCH_USER = "FETCH_USER"
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR"
export const FETCH_USER_ERROR = "FETCH_USER_ERROR"
export const FETCH_LOGIN_HISTORY = "FETCH_LOGIN_HISTORY"
export const FETCH_LOGIN_HISTORY_ERROR = "FETCH_LOGIN_HISTORY_ERROR"
export const CREATE_ROLE = "CREATE_ROLE"
export const CREATE_ROLE_ERROR = "CREATE_ROLE_ERROR"
export const UPDATE_ROLE = "UPDATE_ROLE"
export const UPDATE_ROLE_ERROR = "UPDATE_ROLE_ERROR"
export const GET_ROLE = "GET_ROLE"
export const DELETE_ROLE = "DELETE_ROLE"
export const DELETE_ROLE_ERROR = "DELETE_ROLE_ERROR"
export const FETCH_ROLES = "FETCH_ROLES"
export const FETCH_ROLES_ERROR = "FETCH_ROLES_ERROR"
export const USER_ERROR = "USER_ERROR"
export const SET_TOGGLE = "SET_TOGGLE"
export const SET_ROLE_ID = "SET_ROLE_ID"
export const CREATE_USER = "CREATE_USER"
export const CREATE_USER_ERROR = "CREATE_USER_ERROR"
export const SET_ACTIVE_CARD = "SET_ACTIVE_CARD"
export const SEARCH_USER = "SEARCH_USER"
export const SEARCH_ROLE = "SEARCH_ROLE"
export const DEACTIVATE_USER = "DEACTIVATE_USER"
export const DEACTIVATE_USER_ERROR = "DEACTIVATE_USER_ERROR"
export const REACTIVATE_USER = "REACTIVATE_USER"
export const REACTIVATE_USER_ERROR = "REACTIVATE_USER_ERROR"


//KYC
export const FETCH_KYC_ERROR = "FETCH_KYC_ERROR"
export const FETCH_KYC_LEVEL_ONE = "FETCH_KYC_LEVEL_ONE"
export const FETCH_KYC_LEVEL_ZERO = "FETCH_KYC_LEVEL_ZERO"
export const FETCH_KYC_LEVEL_TWO = "FETCH_KYC_LEVEL_TWO"
export const FETCH_KYC_AGENCY = "FETCH_KYC_AGENCY"
export const FETCH_KYC_VERIFICATION = "FETCH_KYC_VERIFICATION"
export const FETCH_PASSWORD_VERIFICATION = "FETCH_PASSWORD_VERIFICATION"
export const FETCH_PIN_VERIFICATION = "FETCH_PIN_VERIFICATION"
export const SEARCH_KYC1 = "SEARCH_KYC1"
export const SEARCH_KYC0 = "SEARCH_KYC0"
export const SEARCH_KYC2 = "SEARCH_KYC2"
export const SEARCH_ADDR_VERIFICATION = "SEARCH_ADDR_VERIFICATION"
export const SEARCH_PASSWORD_VERIFICATION = "SEARCH_pASSWORD_VERIFICATION"
export const SEARCH_PIN_VERIFICATION = "SEARCH_pIN_VERIFICATION"
export const APPROVE_KYC = "APPROVE_KYC"
export const APPROVE_KYC_ERROR = "APPROVE_KYC"
export const REJECT_KYC = "REJECT_KYC"
export const SET_KYC_LOADING = "SET_KYC_LOADING"
export const SET_CARD_ACTIVE = "SET_CARD_ACTIVE"

// SETTLEMENT
export const FETCH_CASH_REQUEST = "FETCH_CASH_REQUEST"
export const FETCH_FAILED_TRANSACTION = "FETCH_FAILED_TRASACTION"
export const FETCH_FAILED_TRANSACTION_ERROR = "FETCH_FAILED_TRASACTION_ERROR"
export const FETCH_BILL_HISTORY = "FETCH_BILL_HISTORY"
export const FETCH_CASH_REQUEST_ERROR = "FETCH_CASH_REQUEST_ERROR"
export const SEARCH_CASH_REQUEST = "SEARCH_CASH_REQUEST"
export const SEARCH_FAILED_TRANSACTION = "SEARCH_FAILED_TRANSACTION"
export const SEARCH_BILL_HISTORY = "SEARCH_BILL_HISTORY"


// APP CONTENT
export const FETCH_IN_APP_NOTIFICATION = "FETCH_IN_APP_NOTIFICATION"
export const SEARCH_IN_APP_NOTIFICATION = "SEARCH_IN_APP_NOTIFICATION"
export const FETCH_EMAIL_NOTIFICATION = "FETCH_EMAIL_NOTIFICATION"
export const SEARCH_EMAIL_NOTIFICATION = "SEARCH_EMAIL_NOTIFICATION"
export const FETCH_ARTICLES_NOTIFICATION = "FETCH_ARTICLES_NOTIFICATION"
export const SEARCH_ARTICLES_NOTIFICATION = "SEARCH_ARTICLES_NOTIFICATION"
export const FETCH_FAQS_NOTIFICATION = "FETCH_FAQS_NOTIFICATION"
export const SEARCH_FAQS_NOTIFICATION = "SEARCH_FAQS_NOTIFICATION"
export const FETCH_NOTIFICATION_ERROR = "FETCH_NOTIFICATION_ERROR"
export const SET_APP_CONTENT_CARD = "SET_APP_CONTENT_CARD"
export const SET_APP_CONTENT_TOGGLE = "SET_APP_CONTENT_TOGGLE"
export const SET_IN_APP_CONTENT_TOGGLE = "SET_IN_APP_CONTENT_TOGGLE"
export const SET_EMAIL_CONTENT_TOGGLE = "SET_EMAIL_CONTENT_TOGGLE"
export const CREATE_IN_APP_NOTIFICATION = "CREATE_IN_APP_NOTIFICATION"
export const UPDATE_IN_APP_NOTIFICATION = "UPDATE_IN_APP_NOTIFICATION"
export const DELETE_IN_APP_NOTIFICATION = "DELETE_IN_APP_NOTIFICATION"
export const CREATE_EMAIL_NOTIFICATION = "CREATE_EMAIL_NOTIFICATION"
export const UPDATE_EMAIL_NOTIFICATION = "UPDATE_EMAIL_NOTIFICATION"
export const DELETE_EMAIL_NOTIFICATION = "DELETE_EMAIL_NOTIFICATION"
export const DELETE_ARTICLES_NOTIFICATION = "DELETE_ARTICLES_NOTIFICATION"
export const DELETE_FAQ_NOTIFICATION = "DELETE_FAQ_NOTIFICATION"
export const CREATE_IN_APP_NOTIFICATION_ERROR = "CREATE_IN_APP_NOTIFICATION_ERROR"
export const DELETE_IN_APP_NOTIFICATION_ERROR = "DELETE_IN_APP_NOTIFICATION_ERROR"
export const CREATE_EMAIL_NOTIFICATION_ERROR = "CREATE_EMAIL_NOTIFICATION_ERROR"
export const DELETE_EMAIL_NOTIFICATION_ERROR = "DELETE_EMAIL_NOTIFICATION_ERROR"
export const DELETE_ARTICLES_NOTIFICATION_ERROR = "DELETE_ARTICLES_NOTIFICATION_ERROR"
export const DELETE_FAQ_NOTIFICATION_ERROR = "DELETE_FAQ_NOTIFICATION_ERROR"
export const SET_IN_APP_TOGGLE = "SET_IN_APP_TOGGLE"
export const SET_IN_APP_LOADING = "SET_IN_APP_LOADING"
export const SET_ACTIV_ID = "SET_ACTIV_ID"

// SUPPORT
export const PROFILE_SEARCH = "PROFILE_SEARCH"
export const PROFILE_SEARCH_ERROR = "PROFILE_SEARCH_ERROR"
export const FETCH_USER_PROFILE = "FETCH_USER_PROFILE"
export const FETCH_USER_LOGIN_HISTORY = "FETCH_USER_LOGIN_HISTORY"
export const FETCH_USER_PROFILE_VIEW_HISTORY = "FETCH_USER_PROFILE_VIEW_HISTORY"
export const CREATE_USER_PROFILE_VIEW = "CREATE_USER_PROFILE_VIEW"
export const FETCH_USER_TRANSACTION_HISTORY = "FETCH_USER_TRANSACTION_HISTORY"
export const SEARCH_USER_TRANSACTION_HISTORY = "SEARCH_USER_TRANSACTION_HISTORY"
export const FETCH_USER_PROFILE_ERROR = "FETCH_USER_PROFILE_ERROR"
export const SET_SUPPORT_LOADING = "SET_SUPPORT_LOADING"
export const SET_STATUS = "SETSTATUS"
export const GET_DOCUMENT_STATUS = "GET_DOCUMENT_STATUS"
export const FETCH_DOCUMENT_HISTORY = "FETCH_DOCUMENT_HISTORY"
export const FETCH_SUPPORT_TICKET = "FETCH_SUPPORT_TICKET"
export const SEARCH_SUPPORT_TICKET = "SEARCH_SUPPORT_TICKET"
export const SEARCH_USER_BY_PHONE = "SEARCH_USER_BY_PHONE"
export const GET_INTERNAL_USER = "GET_INTERNAL_USER"
export const CREATE_TICKET = "CREATE_TICKET"
export const UPDATE_TICKET = "UPDATE_TICKET"
export const SUPPORT_CREATE_TICKET_ERROR = "SUPPORT_CREATE_TICKET_ERROR"
export const SUPPORT_ERROR = "SUPPORT_ERROR"
