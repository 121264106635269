import { useState } from "react";

import "../../css/user-control/user-control-modal.css";
//import arrowdown from '../../img/chevdown.png'
import KYC from "../../components/kyc-top/kyc-top";
import Nav from "../../components/nav";

import Toggle from "../btn/toggle";
import {RoleCreationSuccess, RoleCreationError} from "../vendorModals/appNotificationModal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { userControlActions } from "../../state/actions";

export default function UserControlModal() {
  const dispatch = useDispatch()
  const {CreateRole,setUserControlToggle, setError } = bindActionCreators(userControlActions, dispatch)
  const {toggle, error, loading } = useSelector((state) => state.userControl)

  const [roleInput, setRoleInput] = useState("")

  const [toggleUsereItem, setToggleUserItem] = useState("users");
  const [toggleAppContentItems, setToggleAppContentItems] = useState("in-app notification");
  const [permissions, setPermissions] = useState([]);
  const [toggleActive, setToggleActive] = useState("Dashboard");

  const [dashboardToggle, setDashboardToggle] = useState(false);
  const [allItemToggle, setAllItemToggle] = useState(false);
  const [kycToggle, setKycToggle] = useState(false);
  const [supportToggle, setSupportToggle] = useState(false);
  const [appContentToggle, setAppContentToggle] = useState(false);
  const [usersToggle, setUsersToggle] = useState(false);
  const [settlementToggle, setSettlementToggle] = useState(false);

  // Dashboard toggle
  const [dashboardTabToggle, setDashboardTabToggle] = useState(false);
  const [allDashboard, setAllDashboard] = useState(false);

  // kyc toggle
  const [KycTabToggle, setKycTabToggle] = useState(false);
  const [KycRejectTabToggle, setKycRejectTabToggle] = useState(false);
  const [KycApproveTabToggle, setKycApproveTabToggle] = useState(false);
  const [allKyc, setAllKyc] = useState(false);

  // support toggle
  const [supportChatViewToggle, setSupportChatViewToggle] = useState(false);
  const [supportReplyToggle, setSupportChatReplyToggle] = useState(false);
  const [supportTicketViewToggle, setSupportTicketViewToggle] = useState(false);
  const [supportCommentToggle, setSupportCommentToggle] = useState(false);
  const [supportReassignTicketToggle, setSupportReassignTicketToggle] =
    useState(false);
  const [supportApproveToggle, setSupportApproveToggle] = useState(false);
  const [supportRejectToggle, setSupportRejectToggle] = useState(false);
  const [allSupport, setAllSupport] = useState(false);

  // Settlements
  const [failedTransactionView, setFailedTransactionView] = useState(false);
  const [failedTransactionSetle, setFailedTransactionSettle] = useState(false);
  const [billsHistoryView, setBillsHistoryView] = useState(false);
  const [billsHistoryDownload, setBillsHistoryDownload] = useState(false);
  const [cashRequestView, setCashRequestView] = useState(false);
  const [allSettlement, setAllSettlement] = useState(false);

  // User control
  const [userView, setUserView] = useState(false);
  const [userCreate, setUserCreate] = useState(false);
  const [userEdit, setUserEdit] = useState(false);
  const [userReactivate, setUserReactivate] = useState(false);
  const [userDeactivate, setUserDeactivate] = useState(false);
  const [loginHistoryView, setLoginHistoryView] = useState(false);
  const [rolesView, setRolesView] = useState(false);
  const [roleCreate, setRoleCreate] = useState(false);
  const [roleEdit, setRoleEdit] = useState(false);
  const [roleDelete, setRoleDelete] = useState(false);
  const [allUsers1, setAllUsers1] = useState(false);
  const [allUsers2, setAllUsers2] = useState(false);

  // App content
  const [inAppView, setInAppView] = useState(false);
  const [inAppCreate, setInAppCreate] = useState(false);
  const [inAppEdit, setInAppEdit] = useState(false);
  const [inAppDelete, setInAppDelete] = useState(false);
  const [emailView, setEmailView] = useState(false);
  const [emailCreate, setEmailCreate] = useState(false);
  const [emailEdit, setEmailEdit] = useState(false);
  const [emailDelete, setEmailDelete] = useState(false);
  const [articlesView, setArticlesView] = useState(false);
  const [articlesCreate, setArticlesCreate] = useState(false);
  const [articlesEdit, setArticlesEdit] = useState(false);
  const [articlesDelete, setArticlesDelete] = useState(false);
  const [faqView, setFaqView] = useState(false);
  const [faqCreate, setFaqCreate] = useState(false);
  const [faqEdit, setFaqEdit] = useState(false);
  const [faqDelete, setFaqDelete] = useState(false);
  const [allApp1, setAllApp1] = useState(false);
  const [allApp2, setAllApp2] = useState(false);
  const [allApp3, setAllApp3] = useState(false);
  const [allApp4, setAllApp4] = useState(false);

  // app content logic
  const setAllAppContent1Tab = (toggle) => {
    if (toggle) {
      setInAppView(true);
      setInAppCreate(true);
      setInAppEdit(true);
      setInAppDelete(true);
      setAllApp1(true)
      setPermissions([
        ...permissions,
        "can view in-app",
        "can create new in-app",
        "can edit in-app",
        "can delete in-app",
      ]);
    } else {
      setInAppView(false);
      setInAppCreate(false);
      setInAppEdit(false);
      setInAppDelete(false);
      setAllApp1(false)

      setPermissions(
        permissions.filter((perm) => {
          if (perm === "can view in-app") {
            return false;
          }
          if (perm === "can create new in-app") {
            return false;
          }
          if (perm === "can edit in-app") {
            return false;
          }
          if (perm === "can delete in-app") {
            return false;
          }
          return true;
        })
      );
    }
  };
  const setAllAppContent2Tab = (toggle) => {
    if (toggle) {
      setEmailView(true);
      setEmailCreate(true);
      setEmailEdit(true);
      setEmailDelete(true);
      setAllApp2(true)
      setPermissions([
        ...permissions,
        "can view email notification",
        "can create new email notification",
        "can edit email notification",
        "can delete email notification",
      ]);
    } else {
      setEmailView(false);
      setEmailCreate(false);
      setEmailEdit(false);
      setEmailDelete(false);
      setAllApp2(false)

      setPermissions(
        permissions.filter((perm) => {
          if (perm === "can view email notification") {
            return false;
          }
          if (perm === "can create new email notification") {
            return false;
          }
          if (perm === "can edit email notification") {
            return false;
          }
          if (perm === "can delete email notification") {
            return false;
          }
          return true;
        })
      );
    }
  };
  const setAllAppContent3Tab = (toggle) => {
    if (toggle) {
      setArticlesView(true);
      setArticlesCreate(true);
      setArticlesEdit(true);
      setArticlesDelete(true);
      setAllApp3(true)
      setPermissions([
        ...permissions,
        "can view articles",
        "can create new articles",
        "can edit articles",
        "can delete articles",
      ]);
    } else {
      setArticlesView(false);
      setArticlesCreate(false);
      setArticlesEdit(false);
      setArticlesDelete(false);
      setAllApp3(false)

      setPermissions(
        permissions.filter((perm) => {
          if (perm === "can view articles") {
            return false;
          }
          if (perm === "can create new articles") {
            return false;
          }
          if (perm === "can edit articles") {
            return false;
          }
          if (perm === "can delete articles") {
            return false;
          }
          return true;
        })
      );
    }
  };
  const setAllAppContent4Tab = (toggle) => {
    if (toggle) {
      setFaqView(true);
      setFaqCreate(true);
      setFaqEdit(true);
      setFaqDelete(true);
      setAllApp4(true)
      setPermissions([
        ...permissions,
        "can view faqs",
        "can create new faqs",
        "can edit faqs",
        "can delete faqs",
      ]);
    } else {
      setFaqView(false);
      setFaqCreate(false);
      setFaqEdit(false);
      setFaqDelete(false);
      setAllApp4(false)

      setPermissions(
        permissions.filter((perm) => {
          if (perm === "can view faqs") {
            return false;
          }
          if (perm === "can create new faqs") {
            return false;
          }
          if (perm === "can edit faqs") {
            return false;
          }
          if (perm === "can delete faqs") {
            return false;
          }
          return true;
        })
      );
    }
  };

  const setInAppViewTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setInAppView,
      setPermissions,
      "can view in-app"
    );
  };
  const setInAppCreateTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setInAppCreate,
      setPermissions,
      "can create new in-app"
    );
  };
  const setInAppEditTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setInAppEdit,
      setPermissions,
      "can edit in-app"
    );
  };
  const setInAppDeleteTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setInAppDelete,
      setPermissions,
      "can delete in-app"
    );
  };
  const setEmailViewTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setEmailView,
      setPermissions,
      "can view email notification"
    );
  };
  const setEmailCreateTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setEmailCreate,
      setPermissions,
      "can create new email notification"
    );
  };
  const setEmailEditTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setEmailEdit,
      setPermissions,
      "can edit email notification"
    );
  };
  const setEmailDeleteTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setEmailDelete,
      setPermissions,
      "can delete email notification"
    );
  };
  const setArticlesViewTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setArticlesView,
      setPermissions,
      "can view articles"
    );
  };
  const setArticlesCreateTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setArticlesCreate,
      setPermissions,
      "can create new articles"
    );
  };
  const setArticlesEditTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setArticlesEdit,
      setPermissions,
      "can edit articles"
    );
  };
  const setArticlesDeleteTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setArticlesDelete,
      setPermissions,
      "can delete articles"
    );
  };
  const setFaqViewTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setFaqView,
      setPermissions,
      "can view faqs"
    );
  };
  const setFaqCreateTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setFaqCreate,
      setPermissions,
      "can create new faqs"
    );
  };
  const setFaqEditTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setFaqEdit,
      setPermissions,
      "can edit faqs"
    );
  };
  const setFaqDeleteTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setFaqDelete,
      setPermissions,
      "can delete faqs"
    );
  };

  // users logic
  const checkAllUsers1Tab = (toggle) => {
    if (toggle) {
      setUserView(true);
      setUserCreate(true);
      setUserEdit(true);
      setUserReactivate(true);
      setUserDeactivate(true);
      setLoginHistoryView(true);
      setAllUsers1(true);
      setPermissions([
        ...permissions,
        "can view users tab",
        "can create new user",
        "can edit user",
        "can reactivate user",
        "can deactivate user",
        "can view login history",
      ]);
    } else {
      setUserView(false);
      setUserCreate(false);
      setUserEdit(false);
      setUserReactivate(false);
      setUserDeactivate(false);
      setLoginHistoryView(false);
      setAllUsers1(false);

      setPermissions(
        permissions.filter((perm) => {
          if (perm === "can view users tab") {
            return false;
          }
          if (perm === "can create new user") {
            return false;
          }
          if (perm === "can edit user") {
            return false;
          }
          if (perm === "can reactivate user") {
            return false;
          }
          if (perm === "can deactivate user") {
            return false;
          }
          if (perm === "can view login history") {
            return false;
          }
          return true;
        })
      );
    }
  };

  const checkAllUsers2Tab = (toggle) => {
    if (toggle) {
      setRolesView(true);
      setRoleCreate(true);
      setRoleEdit(true);
      setRoleDelete(true);
      setAllUsers2(true);
      setPermissions([
        ...permissions,
        "can view roles",
        "can create new role",
        "can edit role",
        "can delete role",
      ]);
    } else {
      setRolesView(false);
      setRoleCreate(false);
      setRoleEdit(false);
      setRoleDelete(false);
      setAllUsers2(false);

      setPermissions(
        permissions.filter((perm) => {
          if (perm === "can view roles") {
            return false;
          }
          if (perm === "can create new role") {
            return false;
          }
          if (perm === "can edit role") {
            return false;
          }
          if (perm === "can delete role") {
            return false;
          }
          return true;
        })
      );
    }
  };

  const checkUserViewTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setUserView,
      setPermissions,
      "can view users tab"
    );
  };
  const setUserCreateTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setUserCreate,
      setPermissions,
      "can create new user"
    );
  };
  const setUserEditTab = (toggle) => {
    handleGeneralToggle(toggle, setUserEdit, setPermissions, "can edit user");
  };
  const setUserReactivateTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setUserReactivate,
      setPermissions,
      "can reactivate user"
    );
  };
  const setUserDeactivateTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setUserDeactivate,
      setPermissions,
      "can deactivate user"
    );
  };
  const setLoginHistoryViewTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setLoginHistoryView,
      setPermissions,
      "can view login history"
    );
  };
  const setRolesViewTab = (toggle) => {
    handleGeneralToggle(toggle, setRolesView, setPermissions, "can view roles");
  };
  const setRoleCreateTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setRoleCreate,
      setPermissions,
      "can create new role"
    );
  };
  const setRoleEditTab = (toggle) => {
    handleGeneralToggle(toggle, setRoleEdit, setPermissions, "can edit role");
  };
  const setRoleDeleteTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setRoleDelete,
      setPermissions,
      "can delete role"
    );
  };

  const handleGeneralToggle = (
    toggle,
    toggleSetter,
    permissionSetter,
    value
  ) => {
    if (toggle) {
      toggleSetter(true);
      permissionSetter([...permissions, value]);
    } else {
      toggleSetter(false);
      permissionSetter(permissions.filter((perm) => perm !== value));
    }
  };

  const checkAllSettlementTab = (toggle) => {
    if (toggle) {
      setFailedTransactionView(true);
      setFailedTransactionSettle(true);
      setBillsHistoryView(true);
      setBillsHistoryDownload(true);
      setCashRequestView(true);
      setAllSettlement(true);
      setPermissions([
        ...permissions,
        "can view failed transaction",
        "can settle failed transaction",
        "can view bills history",
        "can download records",
        "can view cash request history",
      ]);
    } else {
      setFailedTransactionView(false);
      setFailedTransactionSettle(false);
      setBillsHistoryView(false);
      setBillsHistoryDownload(false);
      setCashRequestView(false);
      setAllSettlement(false);

      setPermissions(
        permissions.filter((perm) => {
          if (perm === "can view failed transaction") {
            return false;
          }
          if (perm === "can settle failed transaction") {
            return false;
          }
          if (perm === "can view bills history") {
            return false;
          }
          if (perm === "can download records") {
            return false;
          }
          if (perm === "can view cash request history") {
            return false;
          }
          return true;
        })
      );
    }
  };

  const checkViewFailedTransactionTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setFailedTransactionView,
      setPermissions,
      "can view failed transaction"
    );
  };
  const checkSettleFailedTransactionTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setFailedTransactionSettle,
      setPermissions,
      "can settle failed transaction"
    );
  };
  const checkViewBillHistoryTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setBillsHistoryView,
      setPermissions,
      "can view bills history"
    );
  };
  const checkDownloadBillHistoryTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setBillsHistoryDownload,
      setPermissions,
      "can download records"
    );
  };
  const checkViewCashRequestTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setCashRequestView,
      setPermissions,
      "can view cash request history"
    );
  };

  const CheckAllItemToggle = (toggle) => {
    if (toggle) {
      setDashboardToggle(true);
      setAllItemToggle(true);
      setKycToggle(true);
      setSupportToggle(true);
      setAppContentToggle(true);
      setUsersToggle(true);
      setSettlementToggle(true);
      setPermissions([
        ...permissions,
        "dashboard",
        "kyc",
        "app content",
        "user control",
        "settlement",
        "support",
      ]);
    } else {
      setDashboardToggle(false);
      setAllItemToggle(false);
      setKycToggle(false);
      setSupportToggle(false);
      setAppContentToggle(false);
      setUsersToggle(false);
      setSettlementToggle(false);

      setPermissions(
        permissions.filter((perm) => {
          if (perm === "dashboard") {
            return false;
          }
          if (perm === "kyc") {
            return false;
          }
          if (perm === "app content") {
            return false;
          }
          if (perm === "user control") {
            return false;
          }
          if (perm === "support") {
            return false;
          }
          if (perm === "settlement") {
            return false;
          }
          return true;
        })
      );
    }
  };
  const changeDashboard = (dashboardToggle) => {
    setDashboardToggle(dashboardToggle);
    if (dashboardToggle) {
      setPermissions([...permissions, "dashboard"]);
    } else {
      setPermissions(permissions.filter((perm) => perm !== "dashboard"));
    }
  };
  const changeSupport = (toggle) => {
    setSupportToggle(toggle);
    if (toggle) {
      setPermissions([...permissions, "support"]);
    } else {
      setPermissions(permissions.filter((perm) => perm !== "support"));
    }
  };
  const changeAppContent = (toggle) => {
    setAppContentToggle(toggle);
    if (toggle) {
      setPermissions([...permissions, "app content"]);
    } else {
      setPermissions(permissions.filter((perm) => perm !== "app content"));
    }
  };
  const changeKyc = (toggle) => {
    setKycToggle(toggle);
    if (toggle) {
      setPermissions([...permissions, "kyc"]);
    } else {
      setPermissions(permissions.filter((perm) => perm !== "kyc"));
    }
  };
  const changeUserControl = (toggle) => {
    setUsersToggle(toggle);
    if (toggle) {
      setPermissions([...permissions, "user control"]);
    } else {
      setPermissions(permissions.filter((perm) => perm !== "user control"));
    }
  };
  const changeSettltement = (toggle) => {
    setSettlementToggle(toggle);
    if (toggle) {
      setPermissions([...permissions, "settlement"]);
    } else {
      setPermissions(permissions.filter((perm) => perm !== "settlement"));
    }
  };

  const checkAllDashboardTab = (toggle) => {
    if (toggle) {
      setDashboardTabToggle(true);
      setAllDashboard(true);
      setPermissions([...permissions, "can view dashboard"]);
    } else {
      setDashboardTabToggle(false);
      setAllDashboard(false);

      setPermissions(
        permissions.filter((perm) => perm !== "can view dashboard")
      );
    }
  };

  const checkViewDashboardTab = (toggle) => {
    if (toggle) {
      setDashboardTabToggle(true);
      setAllDashboard(true);
      setPermissions([...permissions, "can view dashboard"]);
    } else {
      setDashboardTabToggle(false);
      setAllDashboard(false);
      setPermissions(
        permissions.filter((perm) => perm !== "can view dashboard")
      );
    }
  };

  const checkAllKycTab = (toggle) => {
    if (toggle) {
      setKycTabToggle(true);
      setKycApproveTabToggle(true);
      setKycRejectTabToggle(true);
      setAllKyc(true);
      setPermissions([
        ...permissions,
        "can view kyc",
        "can reject kyc",
        "can approve kyc",
      ]);
    } else {
      setKycTabToggle(false);
      setKycApproveTabToggle(false);
      setKycRejectTabToggle(false);
      setAllKyc(false);
      setAllDashboard(false);

      setPermissions(
        permissions.filter((perm) => {
          if (perm === "can view kyc") {
            return false;
          }
          if (perm === "can reject kyc") {
            return false;
          }
          if (perm === "can approve kyc") {
            return false;
          }
          return true;
        })
      );
    }
  };

  const checkViewKycTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setKycTabToggle,
      setPermissions,
      "can view kyc"
    );
  };
  const checkKycApproveTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setKycApproveTabToggle,
      setPermissions,
      "can approve kyc"
    );
  };
  const checkKycRejectTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setKycRejectTabToggle,
      setPermissions,
      "can reject kyc"
    );
  };

  const checkAllSupportTab = (toggle) => {
    if (toggle) {
      setSupportChatViewToggle(true);
      setSupportChatReplyToggle(true);
      setSupportTicketViewToggle(true);
      setSupportCommentToggle(true);
      setSupportReassignTicketToggle(true);
      setSupportApproveToggle(true);
      setSupportRejectToggle(true);
      setAllSupport(true);
      setPermissions([
        ...permissions,
        "can view live chat",
        "can reply chat",
        "can view support ticket",
        "can view comments",
        "can re-assign ticket",
        "can reject pending approvals",
        "can approve pending approvals",
      ]);
    } else {
      setSupportChatViewToggle(false);
      setSupportChatReplyToggle(false);
      setSupportTicketViewToggle(false);
      setSupportCommentToggle(false);
      setSupportReassignTicketToggle(false);
      setSupportApproveToggle(false);
      setSupportRejectToggle(false);
      setAllSupport(false);

      setPermissions(
        permissions.filter((perm) => {
          if (perm === "can view live chat") {
            return false;
          }
          if (perm === "can reply chat") {
            return false;
          }
          if (perm === "can view support ticket") {
            return false;
          }
          if (perm === "can view comments") {
            return false;
          }
          if (perm === "can re-assign ticket") {
            return false;
          }
          if (perm === "can reject pending approvals") {
            return false;
          }
          if (perm === "can approve pending approvals") {
            return false;
          }
          return true;
        })
      );
    }
  };

  const checkSupportApproveTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setSupportApproveToggle,
      setPermissions,
      "can approve pending approvals"
    );
  };
  const checkSupportRejectTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setSupportRejectToggle,
      setPermissions,
      "can reject pending approvals"
    );
  };
  const checkReassignTicketTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setSupportReassignTicketToggle,
      setPermissions,
      "can re-assign ticket"
    );
  };
  const checkSupportCommentTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setSupportCommentToggle,
      setPermissions,
      "can view comments"
    );
  };
  const checkSupportViewTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setSupportTicketViewToggle,
      setPermissions,
      "can view support ticket"
    );
  };
  const checkChatViewTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setSupportChatViewToggle,
      setPermissions,
      "can view live chat"
    );
  };
  const checkChatReplyTab = (toggle) => {
    handleGeneralToggle(
      toggle,
      setSupportChatReplyToggle,
      setPermissions,
      "can reply chat"
    );
  };


  const handleSubmit = (e) => {
    e.preventDefault()
    if (roleInput.trim() === "") {
        setError({role: "Please supply this field"})
    } else {
      setError({})
      let finalPermissions = permissions.filter((value, idx, perm) => perm.indexOf(value) === idx)
      CreateRole(roleInput, finalPermissions, 1)
    }
  }

  const navigate = useNavigate();

  const handleToggle = () => {
    if (toggle === 0) {
      return null;
    }
    if (toggle === 1) {
      return (
        <div className="close-deactivate">
          <RoleCreationSuccess
            notify={"You have successfully created a new Role"}
            setToggle={setUserControlToggle}
          />
        </div>
      );
    }
    if (toggle === 2) {
      return (
        <div className="close-deactivate">
          <RoleCreationError
            notify={`OOPS!!! something went wrong, ${error.roleError}`}
            setToggle={setUserControlToggle}
          />
        </div>
      );
    }
  };
  return (
    <>
      <div className="u-control-container-general">
        <div className="u-nav-container">
          <Nav Active={5} />
        </div>
        <div className="u-content-container right-content">
          <KYC url="User Control" suburl="/Role" />
          <div className="user-control-cont-container">
            <div className="user-control-cont">
              <div className="user-control-head">
                <h5>Add New Role</h5>
                <div className="headinput">
                  <label>Role Name <span className="text-danger">*</span></label>
                  <input placeholder="Enter Role Data" value={roleInput} onChange={(e) => setRoleInput(e.target.value)} required />
                  
                </div>
                {error.role && <span style={{marginLeft: "150px"}} className="text-danger">{error.role}</span>}
              </div>
              <div className="control-content">
                <div className="control-left">
                  <p>Permission</p>
                  <div className="inputboxx">
                    <p>Toggle All</p>
                    <div className="rbox">
                      <Toggle
                        checked={allItemToggle}
                        setRemember={CheckAllItemToggle}
                      />
                    </div>
                  </div>
                  <div
                    className={`inputbox ${
                      toggleActive === "Dashboard" ? "click-active" : ""
                    }`}
                    onClick={() => setToggleActive("Dashboard")}
                  >
                    <p>Dashboard</p>
                    <div className="rbox">
                      <Toggle
                        checked={dashboardToggle}
                        setRemember={changeDashboard}
                      />
                    </div>
                  </div>
                  <div
                    className={`inputbox ${
                      toggleActive === "Kyc" ? "click-active" : ""
                    }`}
                    onClick={() => setToggleActive("Kyc")}
                  >
                    <p>KYC</p>
                    <div className="rbox">
                      <Toggle checked={kycToggle} setRemember={changeKyc} />
                    </div>
                  </div>
                  <div
                    className={`inputbox ${
                      toggleActive === "Support" ? "click-active" : ""
                    }`}
                    onClick={() => setToggleActive("Support")}
                  >
                    <p>Support</p>
                    <div className="rbox">
                      <Toggle
                        checked={supportToggle}
                        setRemember={changeSupport}
                      />
                    </div>
                  </div>
                  <div
                    className={`inputbox ${
                      toggleActive === "Settlement" ? "click-active" : ""
                    }`}
                    onClick={() => setToggleActive("Settlement")}
                  >
                    <p>Settlements</p>
                    <div className="rbox">
                      <Toggle
                        checked={settlementToggle}
                        setRemember={changeSettltement}
                      />
                    </div>
                  </div>
                  <div
                    className={`inputbox ${
                      toggleActive === "user-control" ? "click-active" : ""
                    }`}
                    onClick={() => setToggleActive("user-control")}
                  >
                    <p>User Control</p>
                    <div className="rbox">
                      <Toggle
                        checked={usersToggle}
                        setRemember={changeUserControl}
                      />
                    </div>
                  </div>
                  <div
                    className={`inputbox ${
                      toggleActive === "app-content" ? "click-active" : ""
                    }`}
                    onClick={() => setToggleActive("app-content")}
                  >
                    <p>App Contents</p>
                    <div className="rbox">
                      <Toggle
                        checked={appContentToggle}
                        setRemember={changeAppContent}
                      />
                    </div>
                  </div>
                  <div className="base-control-btn">
                    <button disabled={loading} onClick={handleSubmit}>Add Role</button>
                    <button onClick={() => navigate("/user-control")}>
                      Cancel
                    </button>
                  </div>
                </div>
                <div className="control-right">
                {toggleActive === "app-content" ? (
                    <div className="inputbox2">
                      <div className="inputsbox-right-head">
                        <div className="inputright">
                          <div className="ione">
                            <select
                              className=" select-other"
                              value={toggleAppContentItems}
                              onChange={(e) =>
                                setToggleAppContentItems(e.target.value)
                              }
                            >
                              <option value="in-app notification">In-App</option>
                              <option value="email notification">Email</option>
                              <option value="articles">Articles</option>
                              <option value="faqs">Faqs</option>
                            </select>
                          </div>
                        </div>
                        {toggleAppContentItems === "in-app notification" ? (
                          <div className="toggleright-box">
                            <label>Toggle</label>
                            <Toggle checked={allApp1} setRemember={setAllAppContent1Tab} />
                          </div>
                        ) : null}
                        {toggleAppContentItems === "email notification" ? (
                          <div className="toggleright-box">
                            <label>Toggle</label>
                            <Toggle checked={allApp2} setRemember={setAllAppContent2Tab} />
                          </div>
                        ) : null}
                        {toggleAppContentItems === "articles" ? (
                          <div className="toggleright-box">
                            <label>Toggle</label>
                            <Toggle checked={allApp3} setRemember={setAllAppContent3Tab} />
                          </div>
                        ) : null}
                        {toggleAppContentItems === "faqs" ? (
                          <div className="toggleright-box">
                            <label>Toggle</label>
                            <Toggle checked={allApp4} setRemember={setAllAppContent4Tab} />
                          </div>
                        ) : null}
                      </div>
                      {toggleAppContentItems === "in-app notification" ? (
                        <div className="togglesright-content2">
                          <div className="llist tt">
                            <p>Access Rights</p>
                            <div className="ttbox">Status</div>
                          </div>
                          <div className="llist">
                            <p>Can view this tab</p>
                            <div className="ttbox">
                              <Toggle checked={inAppView} setRemember={setInAppViewTab} />
                            </div>
                          </div>
                          <div className="llist">
                            <p>Can create new {toggleAppContentItems}</p>
                            <div className="ttbox">
                              <Toggle checked={inAppCreate} setRemember={setInAppCreateTab} />
                            </div>
                          </div>
                          <div className="llist">
                            <p>Can edit {toggleAppContentItems}</p>
                            <div className="ttbox">
                              <Toggle checked={inAppEdit} setRemember={setInAppEditTab} />
                            </div>
                          </div>
                          <div className="llist">
                            <p>Can delete {toggleAppContentItems}</p>
                            <div className="ttbox">
                              <Toggle checked={inAppDelete} setRemember={setInAppDeleteTab} />
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {toggleAppContentItems === "email notification" ? (
                        <div className="togglesright-content2">
                          <div className="llist tt">
                            <p>Access Rights</p>
                            <div className="ttbox">Status</div>
                          </div>
                          <div className="llist">
                            <p>Can view this tab</p>
                            <div className="ttbox">
                              <Toggle checked={emailView} setRemember={setEmailViewTab} />
                            </div>
                          </div>
                          <div className="llist">
                            <p>Can create new {toggleAppContentItems}</p>
                            <div className="ttbox">
                              <Toggle checked={emailCreate} setRemember={setEmailCreateTab} />
                            </div>
                          </div>
                          <div className="llist">
                            <p>Can edit {toggleAppContentItems}</p>
                            <div className="ttbox">
                              <Toggle checked={emailEdit} setRemember={setEmailEditTab} />
                            </div>
                          </div>
                          <div className="llist">
                            <p>Can delete {toggleAppContentItems}</p>
                            <div className="ttbox">
                              <Toggle checked={emailDelete} setRemember={setEmailDeleteTab} />
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {toggleAppContentItems === "articles" ? (
                        <div className="togglesright-content2">
                          <div className="llist tt">
                            <p>Access Rights</p>
                            <div className="ttbox">Status</div>
                          </div>
                          <div className="llist">
                            <p>Can view this tab</p>
                            <div className="ttbox">
                              <Toggle checked={articlesView} setRemember={setArticlesViewTab} />
                            </div>
                          </div>
                          <div className="llist">
                            <p>Can create new {toggleAppContentItems}</p>
                            <div className="ttbox">
                              <Toggle checked={articlesCreate} setRemember={setArticlesCreateTab} />
                            </div>
                          </div>
                          <div className="llist">
                            <p>Can edit {toggleAppContentItems}</p>
                            <div className="ttbox">
                              <Toggle checked={articlesEdit} setRemember={setArticlesEditTab} />
                            </div>
                          </div>
                          <div className="llist">
                            <p>Can delete {toggleAppContentItems}</p>
                            <div className="ttbox">
                              <Toggle checked={articlesDelete} setRemember={setArticlesDeleteTab} />
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {toggleAppContentItems === "faqs" ? (
                        <div className="togglesright-content2">
                          <div className="llist tt">
                            <p>Access Rights</p>
                            <div className="ttbox">Status</div>
                          </div>
                          <div className="llist">
                            <p>Can view this tab</p>
                            <div className="ttbox">
                              <Toggle checked={faqView} setRemember={setFaqViewTab} />
                            </div>
                          </div>
                          <div className="llist">
                            <p>Can create new {toggleAppContentItems}</p>
                            <div className="ttbox">
                              <Toggle checked={faqCreate} setRemember={setFaqCreateTab} />
                            </div>
                          </div>
                          <div className="llist">
                            <p>Can edit {toggleAppContentItems}</p>
                            <div className="ttbox">
                              <Toggle checked={faqEdit} setRemember={setFaqEditTab} />
                            </div>
                          </div>
                          <div className="llist">
                            <p>Can delete {toggleAppContentItems}</p>
                            <div className="ttbox">
                              <Toggle checked={faqDelete} setRemember={setFaqDeleteTab} />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  {toggleActive === "Dashboard" ? (
                    <div className="inputbox2">
                      <div className="inputsbox-right-head">
                        <div className="inputright">
                          <div className="ione">
                            <select className=" select-other" disabled>
                              <option>Dashboard</option>
                            </select>
                          </div>
                        </div>
                        <div className="toggleright-box">
                          <label>Toggle</label>
                          <Toggle
                            checked={allDashboard}
                            setRemember={checkAllDashboardTab}
                          />
                        </div>
                      </div>
                      <div className="togglesright-content2">
                        <div className="llist tt">
                          <p>Access Rights</p>
                          <div className="ttbox">Status</div>
                        </div>
                        <div className="llist">
                          <p>Can view {toggleActive}</p>
                          <div className="ttbox">
                            <Toggle
                              checked={dashboardTabToggle}
                              setRemember={checkViewDashboardTab}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {toggleActive === "user-control" ? (
                    <div className="inputbox2">
                      <div className="inputsbox-right-head">
                        <div className="inputright">
                          <div className="ione">
                            <select
                              className=" select-other"
                              value={toggleUsereItem}
                              onChange={(e) =>
                                setToggleUserItem(e.target.value)
                              }
                            >
                              <option value="users">Users</option>
                              <option value="roles">Roles & Permission</option>
                            </select>
                          </div>
                        </div>
                        {toggleUsereItem === "users" ? (
                          <div className="toggleright-box">
                            <label>Toggle All</label>
                            <Toggle
                              checked={allUsers1}
                              setRemember={checkAllUsers1Tab}
                            />
                          </div>
                        ) : (
                          <div className="toggleright-box">
                            <label>Toggle All</label>
                            <Toggle
                              checked={allUsers2}
                              setRemember={checkAllUsers2Tab}
                            />
                          </div>
                        )}
                      </div>
                      {toggleUsereItem === "users" ? (
                        <div className="togglesright-content2">
                          <div className="llist tt">
                            <p>Access Rights</p>
                            <div className="ttbox">Status</div>
                          </div>
                          <div className="llist">
                            <p>Can view this tab</p>
                            <div className="ttbox">
                              <Toggle
                                checked={userView}
                                setRemember={checkUserViewTab}
                              />
                            </div>
                          </div>
                          <div className="llist">
                            <p>Can add new user</p>
                            <div className="ttbox">
                              <Toggle
                                checked={userCreate}
                                setRemember={setUserCreateTab}
                              />
                            </div>
                          </div>
                          <div className="llist">
                            <p>Can edit user</p>
                            <div className="ttbox">
                              <Toggle
                                checked={userEdit}
                                setRemember={setUserEditTab}
                              />
                            </div>
                          </div>
                          <div className="llist">
                            <p>Can re-activate user</p>
                            <div className="ttbox">
                              <Toggle
                                checked={userReactivate}
                                setRemember={setUserReactivateTab}
                              />
                            </div>
                          </div>
                          <div className="llist">
                            <p>Can de-activate user</p>
                            <div className="ttbox">
                              <Toggle
                                checked={userDeactivate}
                                setRemember={setUserDeactivateTab}
                              />
                            </div>
                          </div>
                          <div className="llist">
                            <p>Can view login history</p>
                            <div className="ttbox">
                              <Toggle
                                checked={loginHistoryView}
                                setRemember={setLoginHistoryViewTab}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="togglesright-content2">
                          <div className="llist tt">
                            <p>Access Rights</p>
                            <div className="ttbox">Status</div>
                          </div>
                          <div className="llist">
                            <p>Can view this tab</p>
                            <div className="ttbox">
                              <Toggle
                                checked={rolesView}
                                setRemember={setRolesViewTab}
                              />
                            </div>
                          </div>
                          <div className="llist">
                            <p>Can create role and permissions</p>
                            <div className="ttbox">
                              <Toggle
                                checked={roleCreate}
                                setRemember={setRoleCreateTab}
                              />
                            </div>
                          </div>
                          <div className="llist">
                            <p>Can edit role and permission</p>
                            <div className="ttbox">
                              <Toggle
                                checked={roleEdit}
                                setRemember={setRoleEditTab}
                              />
                            </div>
                          </div>
                          <div className="llist">
                            <p>Can delete role</p>
                            <div className="ttbox">
                              <Toggle
                                checked={roleDelete}
                                setRemember={setRoleDeleteTab}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : null}
                  {toggleActive === "Settlement" ? (
                    <div className="inputbox2">
                      <div className="inputsbox-right-head">
                        <div className="inputright">
                          <div className="ione">
                            <select className=" select-other" disabled>
                              <option>Settlement</option>
                            </select>
                          </div>
                        </div>
                        <div className="toggleright-box">
                          <label>Toggle</label>
                          <Toggle
                            checked={allSettlement}
                            setRemember={checkAllSettlementTab}
                          />
                        </div>
                      </div>
                      <div className="togglesright-content2">
                        <div className="llist tt">
                          <p>Access Rights</p>
                          <div className="ttbox">Status</div>
                        </div>
                        <div className="llist">
                          <p>Can view failed transaction tab</p>
                          <div className="ttbox">
                            <Toggle
                              checked={failedTransactionView}
                              setRemember={checkViewFailedTransactionTab}
                            />
                          </div>
                        </div>
                        <div className="llist">
                          <p>Can settle failed transaction</p>
                          <div className="ttbox">
                            <Toggle
                              checked={failedTransactionSetle}
                              setRemember={checkSettleFailedTransactionTab}
                            />
                          </div>
                        </div>
                        <div className="llist">
                          <p>Can view bills history tab</p>
                          <div className="ttbox">
                            <Toggle
                              checked={billsHistoryView}
                              setRemember={checkViewBillHistoryTab}
                            />
                          </div>
                        </div>
                        <div className="llist">
                          <p>Can download bills history</p>
                          <div className="ttbox">
                            <Toggle
                              checked={billsHistoryDownload}
                              setRemember={checkDownloadBillHistoryTab}
                            />
                          </div>
                        </div>
                        <div className="llist">
                          <p>Can view cash request history</p>
                          <div className="ttbox">
                            <Toggle
                              checked={cashRequestView}
                              setRemember={checkViewCashRequestTab}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {toggleActive === "Support" ? (
                    <div className="inputbox2">
                      <div className="inputsbox-right-head">
                        <div className="inputright">
                          <div className="ione">
                            <select className=" select-other" disabled>
                              <option>All Support</option>
                            </select>
                          </div>
                        </div>
                        <div className="toggleright-box">
                          <label>Toggle</label>
                          <Toggle
                            checked={allSupport}
                            setRemember={checkAllSupportTab}
                          />
                        </div>
                      </div>
                      <div className="togglesright-content2">
                        <div className="llist tt">
                          <p>Access Rights</p>
                          <div className="ttbox">Status</div>
                        </div>
                        <div className="llist">
                          <p>Can view live chat</p>
                          <div className="ttbox">
                            <Toggle
                              checked={supportChatViewToggle}
                              setRemember={checkChatViewTab}
                            />
                          </div>
                        </div>
                        <div className="llist">
                          <p>Can reply chat</p>
                          <div className="ttbox">
                            <Toggle
                              checked={supportReplyToggle}
                              setRemember={checkChatReplyTab}
                            />
                          </div>
                        </div>
                        <div className="llist">
                          <p>Can view support ticket</p>
                          <div className="ttbox">
                            <Toggle
                              checked={supportTicketViewToggle}
                              setRemember={checkSupportViewTab}
                            />
                          </div>
                        </div>
                        <div className="llist">
                          <p>Can view comments</p>
                          <div className="ttbox">
                            <Toggle
                              checked={supportCommentToggle}
                              setRemember={checkSupportCommentTab}
                            />
                          </div>
                        </div>
                        <div className="llist">
                          <p>Can Re-assign ticket</p>
                          <div className="ttbox">
                            <Toggle
                              checked={supportReassignTicketToggle}
                              setRemember={checkReassignTicketTab}
                            />
                          </div>
                        </div>
                        <div className="llist">
                          <p>Can reject [pending approvals]</p>
                          <div className="ttbox">
                            <Toggle
                              checked={supportRejectToggle}
                              setRemember={checkSupportRejectTab}
                            />
                          </div>
                        </div>
                        <div className="llist">
                          <p>Can approve [pending approvals]</p>
                          <div className="ttbox">
                            <Toggle
                              checked={supportApproveToggle}
                              setRemember={checkSupportApproveTab}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {toggleActive === "Kyc" ? (
                    <div className="inputbox2">
                      <div className="inputsbox-right-head">
                        <div className="inputright">
                          <div className="ione">
                            <select className=" select-other" disabled>
                              <option>Kyc</option>
                            </select>
                          </div>
                        </div>
                        <div className="toggleright-box">
                          <label>Toggle</label>
                          <Toggle
                            checked={allKyc}
                            setRemember={checkAllKycTab}
                          />
                        </div>
                      </div>
                      <div className="togglesright-content2">
                        <div className="llist tt">
                          <p>Access Rights</p>
                          <div className="ttbox">Status</div>
                        </div>
                        <div className="llist">
                          <p>Can view {toggleActive}</p>
                          <div className="ttbox">
                            <Toggle
                              checked={KycTabToggle}
                              setRemember={checkViewKycTab}
                            />
                          </div>
                        </div>
                        <div className="llist">
                          <p>Can approve {toggleActive}</p>
                          <div className="ttbox">
                            <Toggle
                              checked={KycApproveTabToggle}
                              setRemember={checkKycApproveTab}
                            />
                          </div>
                        </div>
                        <div className="llist">
                          <p>Can reject {toggleActive}</p>
                          <div className="ttbox">
                            <Toggle
                              checked={KycRejectTabToggle}
                              setRemember={checkKycRejectTab}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {handleToggle()}
    </>
  );
}
