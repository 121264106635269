// import "./css/support-dropdown.css"
//import { useDispatch } from "react-redux";
//import { useNavigate } from "react-router-dom";
//import { bindActionCreators } from "redux";
//import { AppContentActions } from "../../../state/actions";

function VendorDropDown({acceptToggle}) {
  //const navigate = useNavigate();

  //const dispatch = useDispatch();

  //const { setActiveID } = bindActionCreators(AppContentActions, dispatch);

//   const onClick = () => {
//     navigate(`/edit-article/${btoa(id)}`);
//   };
  const changeToggle = (value) => {
    acceptToggle(value);
  };
  return (
    <div className="btn-group">
      <button
        type="button"
        className="btn btn-default"
        style={{
          width: "35px",
          height: "35px",
          backgroundColor: "darkblue",
          borderRadius: "100%",
          color: "white",
        }}
        data-toggle="dropdown"
        aria-expanded="false"
      >
        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
      </button>
      <div className="dropdown-menu">
        <div className="dropdown-item"  
        onClick={()=>acceptToggle(1)}>
          View Details
        </div>
        <div className="dropdown-divider"></div>
        <div
          className="dropdown-item"
          onClick={() => {
            changeToggle(2)
          }}
        >
          Deactivate
        </div>
        <div className="dropdown-divider"></div>
        <div
          className="dropdown-item"
          onClick={() => {
            changeToggle(3)
          }}
        >
          Reactivate
        </div>
        <div className="dropdown-divider"></div>
        <div
          className="dropdown-item"
          onClick={() => {
            changeToggle(4);
          }}
        >
          Verification History
        </div>
        {/* <div class="dropdown-divider"></div>
          <a className="dropdown-item" href="#" onClick={() => { changeToggle(3) }}>
          Re-assign
          </a> */}
      </div>
    </div>
  );
}

export default VendorDropDown;
