import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Card from "./components/card/card";
import KycView from "./components/view-level/view";
import WelcomeBackLogin from "./pages/login/welcomeBackLogin";
import Login2 from "./pages/login/login2";
import Login from "./pages/login/login";
import Vendor from "./pages/vendors/vendor";
import Kyc from "./pages/kyc/kyc";
import Dashboard from "./pages/dashboard/dashboard";
import Support from "./pages/chat-support/root";
import Settlements from "./pages/settlements/settlements";
import MainChat from "./components/chat/chat";
import AddNewUser from "./components/modals/user-control-add-new-user";
import UserControl from "./pages/user-control/user-control";
import UserControlModal from "./components/user-control/user-control-modal";
import UserControlModal2 from "./components/user-control/user-control-modal2";
import Layout from "./pages/layout/layout";
import Layout2 from "./pages/layout/layout2";
import SupportSearchResult from "./pages/chat-support/search-result";
import SupportTicketCreationModal from "./pages/chat-support/support-modals/support-ticket-creation";
import SupportProfileReview from "./pages/chat-support/support-profile-review";
import DCModal from "./components/dcmodal";
import Comments from "./components/comments";
import Comments2 from "./components/comments2";
import UploadDocument from "./components/upload-document/upload_document";
import Level1View from "./components/kyc/level1/view";

// import { Mmode } from "./components/modals/list";
import ReasignTicket from "./components/reasign-ticket";
import UploadModalID from "./components/support/upload-modal-id";
import ViewSupportTicket from "./pages/chat-support/view-support-ticket";
import NewAppNotification from "./pages/app-contents/new-app-notification";
import TransactionHistory from "./components/support/transaction";
import AppContent from "./pages/app-content/app_content";
// import ViewUserControlModal from "./components/user-control/user-control-modal2";
import NewArticle from "./pages/app-contents/new-article-item";
import FrequentlyAskedQuestion from "./pages/app-contents/faq";
import ViewAppNotification from "./pages/app-contents/view-app-notification";
import NewEmailNotification from "./pages/app-contents/new-email-notification";
import ViewFrequentlyAskedQuestion from "./pages/app-contents/view-faq";
import ViewEmailNotification from "./pages/app-contents/view-email-notification";
import ViewArticle from "./pages/app-contents/view-article-item";
import LoginPrivateRoute from "./LoginPrivateRoute";
import Login2PrivateRoute from "./Login2PrivateRoute";
import PrivateRoute from "./PrivateRoute";
import AppPagination from "./pages/testing-pagination/real_page";
// import View from "./components/view-level/view";
import AgencyView from "./components/view-level/agency-view";
import ResetPassword from "./pages/login/reset-passcode";

import DoubleImage from "./components/image-comparison/double-image";
import ImageView from "./components/image-comparison/image-view";
import PasswordResetView from "./components/view-level/password-reset-view";
import PinResetView from "./components/view-level/pin-reset-view";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { authActions } from "./state/actions";
import * as types from "./state/types";

import Cookies from "js-cookie";

const App = () => {
  const dispatch = useDispatch();

  const { FetchUserDetail } = bindActionCreators(authActions, dispatch);

  useEffect(() => {
    if (localStorage.getItem(btoa(types.USERNAME))) {
      const { userID } = authActions.DecodeToken();
      if (userID && Cookies.get(btoa(types.TOKEN))) {
        FetchUserDetail(userID);
      }
    }

    //eslint-disable-next-line
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/reset-password/:email"
          element={<ResetPassword />}
        ></Route>
        <Route
          exact
          path="/login"
          element={<LoginPrivateRoute>{<Login />}</LoginPrivateRoute>}
        ></Route>

        <Route
          exact
          path="/welcome-back"
          element={
            <Login2PrivateRoute>{<WelcomeBackLogin />}</Login2PrivateRoute>
          }
        ></Route>

        <Route
          exact
          path="/"
          element={<PrivateRoute>{<Dashboard />}</PrivateRoute>}
        ></Route>
        <Route
          exact
          path="/user-control"
          element={<PrivateRoute>{<UserControl />}</PrivateRoute>}
        ></Route>

        <Route
          exact
          path="/create-role-permissions"
          element={<PrivateRoute>{<UserControlModal />}</PrivateRoute>}
        ></Route>
        <Route
          exact
          path="/edit-roles-permission/:roleID"
          element={<PrivateRoute>{<UserControlModal2 />}</PrivateRoute>}
        ></Route>
        <Route
          exact
          path="/kyc"
          element={<PrivateRoute>{<Kyc />}</PrivateRoute>}
        ></Route>
        <Route
          exact
          path="/kyc/level1/:id"
          element={<PrivateRoute>{<Level1View />}</PrivateRoute>}
        ></Route>

        <Route
          exact
          path="/kyc/level-two/:id"
          element={<PrivateRoute>{<KycView />}</PrivateRoute>}
        ></Route>
        <Route
          exact
          path="/kyc/agency/:id"
          element={<PrivateRoute>{<AgencyView />}</PrivateRoute>}
        ></Route>

        <Route
          exact
          path="/kyc/password-reset-view/:id"
          element={<PrivateRoute>{<PasswordResetView />}</PrivateRoute>}
        ></Route>
        <Route
          exact
          path="/kyc/pin-reset-view/:id"
          element={<PrivateRoute>{<PinResetView />}</PrivateRoute>}
        ></Route>

        <Route
          exact
          path="/settlements"
          element={<PrivateRoute>{<Settlements />}</PrivateRoute>}
        ></Route>

        <Route
          exact
          path="/app-content"
          element={<PrivateRoute>{<AppContent />}</PrivateRoute>}
        ></Route>

        <Route
          exact
          path="/edit-faq/:id"
          element={
            <PrivateRoute>{<ViewFrequentlyAskedQuestion />}</PrivateRoute>
          }
        ></Route>

        <Route
          exact
          path="/create-faq"
          element={<PrivateRoute>{<FrequentlyAskedQuestion />}</PrivateRoute>}
        ></Route>

        <Route
          exact
          path="/create-article"
          element={<PrivateRoute>{<NewArticle />}</PrivateRoute>}
        ></Route>

        <Route
          exact
          path="/edit-article/:id"
          element={<PrivateRoute>{<ViewArticle />}</PrivateRoute>}
        ></Route>

        <Route
          exact
          path="/create-email-notification"
          element={<PrivateRoute>{<NewEmailNotification />}</PrivateRoute>}
        ></Route>

        <Route
          exact
          path="/edit-email-notification/:id"
          element={<PrivateRoute>{<ViewEmailNotification />}</PrivateRoute>}
        ></Route>

        <Route
          exact
          path="/create-in-app-notification"
          element={<PrivateRoute>{<NewAppNotification />}</PrivateRoute>}
        ></Route>

        <Route
          exact
          path="/edit-in-app-notification/:id"
          element={<PrivateRoute>{<ViewAppNotification />}</PrivateRoute>}
        ></Route>

        <Route
          exact
          path="/support"
          element={<PrivateRoute>{<Support />}</PrivateRoute>}
        ></Route>

        <Route
          exact
          path="/profile-review/:id"
          element={<PrivateRoute>{<SupportProfileReview />}</PrivateRoute>}
        ></Route>

        {/* TODO: delete all the components in this category once we're done with the implementation generally - meaning they are all not useful */}

        <Route
          exact
          path="/bank"
          element={<TransactionHistory label="new" />}
        ></Route>

        <Route exact path="/card" element={<Card />}></Route>
        <Route exact path="/settlements" element={<Settlements />}></Route>
        <Route exact path="/kyc" element={<Kyc />}></Route>
        <Route exact path="/Kview" element={<KycView />}></Route>
        <Route exact path="/level1view" element={<Level1View />}></Route>
        <Route
          exact
          path="/kyc/password-reset-view/:id"
          element={<PasswordResetView />}
        ></Route>
        <Route
          exact
          path="/kyc/pin-reset-view/:id"
          element={<PinResetView />}
        ></Route>

        <Route exact path="/login2" element={<Login2 />}></Route>
        <Route exact path="/new" element={<AddNewUser />}></Route>
        <Route exact path="/vendor" element={<Vendor />}></Route>

        <Route exact path="/main-chat" element={<MainChat />}></Route>

        <Route exact path="/layout" element={<Layout />}></Route>
        <Route exact path="/layout2" element={<Layout2 />}></Route>
        <Route exact path="/double-image" element={<DoubleImage />}></Route>
        <Route exact path="/image-view" element={<ImageView />}></Route>

        <Route
          exact
          path="/document-history-modal"
          element={<DCModal />}
        ></Route>
        <Route exact path="/comments" element={<Comments />}></Route>
        <Route exact path="/comments-2" element={<Comments2 />}></Route>
        <Route exact path="/Reasignticket" element={<ReasignTicket />}></Route>

        <Route exact path="/user-control" element={<UserControl />}></Route>
        <Route
          exact
          path="/profile-search"
          element={<SupportSearchResult />}
        ></Route>
        <Route
          exact
          path="/ticket"
          element={<SupportTicketCreationModal />}
        ></Route>
        <Route
          exact
          path="/profile-review"
          element={<SupportProfileReview />}
        ></Route>
        <Route exact path="/upload-doc" element={<UploadDocument />}></Route>
        <Route exact path="/upload-id" element={<UploadModalID />}></Route>
        <Route
          exact
          path="/view-suppport"
          element={<ViewSupportTicket />}
        ></Route>

        <Route exact path="/pagination" element={<AppPagination />}></Route>
        <Route exact path="/agency-view" element={<AgencyView />}></Route>
      </Routes>
    </Router>
  );
};

export default App;
