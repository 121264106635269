export default function UserBtnLight(){
    return (
<svg width="36" height="40" viewBox="0 0 46 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.5258 24.9167C27.8027 24.9167 32.891 19.7866 32.891 13.4583C32.891 7.13007 27.8027 2 21.5258 2C15.2491 2 10.1607 7.13007 10.1607 13.4583C10.1607 19.7866 15.2491 24.9167 21.5258 24.9167Z" stroke="#162082" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2 47.8337C2 38.9649 10.7512 31.792 21.5254 31.792" stroke="#162082" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M35.6184 46.4587C39.6355 46.4587 42.8921 43.1754 42.8921 39.1254C42.8921 35.0753 39.6355 31.792 35.6184 31.792C31.6012 31.792 28.3446 35.0753 28.3446 39.1254C28.3446 43.1754 31.6012 46.4587 35.6184 46.4587Z" stroke="#162082" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M44.2559 47.8337L41.9828 45.542" stroke="#162082" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    )
}