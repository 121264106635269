import axios from "../../axios";
import * as types from "../types";

export const LoadDashboard = () => async (dispatch) => {
  SetLoading()
    try {
        const res = await axios.get("/dashboard")
        dispatch({
            type: types.LOAD_DASHBOARD,
            payload: res.data.data
        })
    } catch (err) {
        if (err.response.status === 500) {
            dispatch({
              type: types.LOAD_DASHBOARD_ERROR,
              payload: { top: err.response.data.error.message },
            });
          }
    }
}

export const LoadDashboardAnalytics = (duration) => async (dispatch) => {
  duration = duration || "monthly"
    try {
        const res = await axios.get(`/user-analytics?duration=${duration}`)
        
        dispatch({
            type: types.LOAD_DASHBOARD_ANAYTICS,
            payload: res.data.data.counts
        })
    } catch (err) {
        if (err.response.status === 500) {
            dispatch({
              type: types.LOAD_DASHBOARD_ERROR,
              payload: { top: err.response.data.error.message },
            });
          }
    }
}
export const SetLoading = () => (dispatch) => {
    dispatch({
      type: types.SET_LOADING,
    });
  };
