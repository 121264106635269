import Card from "../../components/app-content/card";
import KYCTOP from "../../components/kyc-top/kyc-top";
import { useState } from "react";
import Nav from "../../components/nav";

export default function AppContent() {
    const [text, setText] = useState("");
    // const [hidden, setHidden] = useState({
    //     display: "none",
    // });

    return (
        <>
            <div className="gen-cont">
                <div>
                    <Nav Active={6}/>
                </div>
                <div className="right-content">
                    <KYCTOP url="App Contents" suburl={text} />
                    <Card setText={setText} />
                </div>
            </div>
        </>
    );
}
