import "./css/address-verify-history.css";
import Closebtn1 from "../../img/Close-Square.svg";
import { useState } from "react";

function VendorAddressVerificationHistory({setToggle}) {
  const [display, setDisplay] = useState("");
  const closeDisplay = () => {
    setDisplay("close-details");
    setToggle(0)
  }

  const vData = [
    {
      id: 1,
      name: "Wade Warren",
      assigned: "Jan-10-2022",
      returned: "N/A",
      status: "Pending",
    },
    {
      id: 2,
      name: "Wade Warren",
      assigned: "Jan-10-2022",
      returned: "Jan-10-2022",
      status: "Failed",
    },
    {
      id: 3,
      name: "Wade Warren",
      assigned: "Jan-10-2022",
      returned: "Jan-10-2022",
      status: "Successful",
    },
  ];
  return (
    <>
      <div className={`display-details ${display}`}>
        <div className={`modal-container`}>
          <div className="modal-contentt">
            <img
              className="cancel-icon4"
              src={Closebtn1}
              alt=""
              onClick={closeDisplay}
            />
            <div className={`closecase1 ${display}`}>

              <div style={{
                display: "flex",
                justifyContent: "center",
                color: "#462C94",
                fontSize: "20px"

              }}><h2>Address Verification History</h2></div>
              <div className="bill-cont">

                <div className="bills">
                  <div className="heading1">
                    <div className="search-section1">
                      <div className="input-cont">
                        <input type="date" />
                        <input type="date" />
                        <button>Display Records</button>
                        <p className="verify-pass">Displaying Record for Today</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-content">
              <table style={{ width: "100%" }} className={`${display}`}>
                <tr>
                  <th>#</th>
                  <th>Profile Name</th>
                  <th>Date Assigned</th>
                  <th>Date Returned</th>
                  <th>Status</th>
                </tr>
                {vData.map((item) => (
                  <tr className="item-tr">
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.assigned}</td>
                    <td>{item.returned}</td>
                    <td>{item.status}</td>

                    <td>
                      <button className="table-view-btn">View</button>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VendorAddressVerificationHistory;
