import "./compare.css";
import Closebtn from "../../img/Close-Square.svg";
import Rotatebtn from "../../img/rotate.png";
import twox from "../../img/twox.png";
import Minus from "../../img/minus.png";
import { useState } from "react";
export default function ImageView({ setToggle, image }) {
  const [close, setClose] = useState(false);
  const [rotate, setRotate] = useState(0);
  const [height, setHeight] = useState(0);
  const [zoom, setZoom] = useState(80);
  const [zoom2, setZoom2] = useState(80);
  
  function handleRotate() {
    const moveRotate = rotate + 1;
    if (moveRotate === 4) {
      return setRotate(0);
    }
    return setRotate(moveRotate);
  }
  const handlezoom = () => {
    let increamentH = height + 1;
    setHeight(increamentH);
    if (zoom <= 145) {
        setZoom(zoom+15)
        setZoom2(zoom+15)
    }
  };
  const handlezoom2 = () => {
    let increamentH = height - 1;
    setHeight(increamentH);
    if (zoom >= 80) {
        setZoom(zoom-15)
        setZoom2(zoom-15)
    }
  };
  const handleClose = () => {
    setClose(true);
    setToggle(0);
  };
  return (
    <>
      {close ? null : (
        <div className="image_view_cont">
          <p>Image View</p>
          <img onClick={handleClose} src={Closebtn} alt="clasebtn" />
          <div className="innerBox">
            <div className="comparebox">
              <img
                onClick={handleRotate}
                src={image}
                style={{ height: zoom + "%", width: zoom2+"%" }}
                className={`moved${rotate}`}
                alt=""
              />
            </div>

            <div className="view-image-btngroup">
              <div className="comparebtn" onClick={handleRotate}>
                <div className="imgfcont">
                  <img src={Rotatebtn} alt="" />
                </div>
                <h6 onClick={handleRotate}>Rotate</h6>
              </div>
              <div className="comparebtn" onClick={handlezoom}>
                <img src="" alt="" />
                <div className="imgfcont">
                  <img src={twox} alt="" />
                </div>
                <h6>Zoom in</h6>
              </div>
              <div className="comparebtn" onClick={handlezoom2}>
                <div className="imgfcont">
                  <img  src={Minus} alt="" />
                </div>
                <h6>Zoom out</h6>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
