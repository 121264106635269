import "../../css/settlements/failed-transaction.css";
import { SettlementInput } from "../input/input";
import kimg from "../../img/ksearch.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { SettlementActions } from "../../state/actions";
import Moment from "react-moment";
import Pagination from "../footer-pagination";

export default function FailedTransaction({ acceptToggle }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");

  const {
    failedTransactionMeta,
    failedTransactionLoading,
    failedTransaction,
    searchFailedTransactionMeta, searchFailedTransactionData
  } = useSelector((state) => state.settlement);

  const dispatch = useDispatch();
  const { FetchFailedTransactions, SearchFailedTransactions, DownloadFailedTransactions  } = bindActionCreators(
    SettlementActions,
    dispatch
  );

  useEffect(() => {
    if (search.trim() === "") {
      FetchFailedTransactions(currentPage);
    } else {
      SearchFailedTransactions(search, currentPage)
    }
    
    // eslint-disable-next-line
  }, [currentPage]);

  const { per_page, total } = searchFailedTransactionMeta ? searchFailedTransactionMeta : 
  failedTransactionMeta;

  let userData =
    searchFailedTransactionData !== null ? searchFailedTransactionData : failedTransaction;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <>
      <div className="failed-kyc-cont">
        <div className="failed-search-cont">
          <div className="">
            <button className="download-records" onClick={DownloadFailedTransactions}>Download Records</button>
          </div>
          <div className="kscont">
            <SettlementInput label="Search Records" searchValue={SearchFailedTransactions} search={search} setSearch={setSearch} />
            <img src={kimg} alt="" />
          </div>
        </div>
        <div className="settlement-failed-table-content">
          <table className="transaction-table">
            <thead className="thead">
              <tr>
                <td></td>
                <td>Customer</td>
                <td>Transaction ID</td>
                <td>Amount</td>
                <td>Failure Reason</td>
                <td>Time</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {userData.map((item, idx) => (
                <tr key={item.TransactionID} className="tbody">
                  <td>{(currentPage - 1) * per_page + 1 + idx}</td>
                  <td>{(item.source && item.source.name) || "-"}</td>
                  <td>{item.transaction_reference || "-"}</td>
                  <td>{(item.amount && "₦" + item.amount) || "-"}</td>
                  <td>{item.Failure || "-"}</td>
                  <td>
                    {item.created_at && (
                      <Moment format="DD/MM/YYYY H:mm">{item.created_at}</Moment>
                    )}
                  </td>
                  {/* <td>
                    <a
                      href="#settle"
                      className="kbtn"
                      onClick={() => acceptToggle(2)}
                    >
                      Settle
                    </a>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
        {!failedTransactionLoading && (
          <Pagination
            className="users-pagination"
            currentPage={currentPage}
            totalCount={total}
            pageSize={per_page}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </>
  );
}
