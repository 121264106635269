import { authActions } from "../../../state/actions";
import axios from "../../../axios";

function UserDropDown({ setToggle, id, status, roles }) {
  const changeToggle = (value) => {
    setToggle(value, id);
  };

  const { userID } = authActions.DecodeToken();

  const passwordReset = async () => {
    try {
      await axios.post(`users/${id}/reset-password`);
      setToggle(16);
    } catch (err) {
      setToggle(17);
    }
  };

  return (
    <div className="btn-group">
      <button
        type="button"
        className="btn btn-default"
        style={{
          width: "35px",
          height: "35px",
          backgroundColor: "darkblue",
          borderRadius: "100%",
          color: "white",
        }}
        data-toggle="dropdown"
        aria-expanded="false"
      >
        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
      </button>
      <div className="dropdown-menu">
        <span
          style={{ cursor: "pointer" }}
          className="dropdown-item"
          onClick={() => {
            changeToggle(1);
          }}
        >
          Edit
        </span>
        <div class="dropdown-divider"></div>
        {userID !== id ? (
          <>
            {status === "active" ? (
              <span
                style={{ cursor: "pointer" }}
                className="dropdown-item"
                onClick={() => {
                  changeToggle(2);
                }}
              >
                Deactivate
              </span>
            ) : (
              <span
                style={{ cursor: "pointer" }}
                className="dropdown-item"
                onClick={() => {
                  changeToggle(3);
                }}
              >
                Reactivate
              </span>
            )}
            <div class="dropdown-divider"></div>
          </>
        ) : null}

        <span
          style={{ cursor: "pointer" }}
          className="dropdown-item"
          onClick={passwordReset}
        >
          Resend Password Link
        </span>
        <div class="dropdown-divider"></div>
        <span
              style={{cursor: "pointer"}}
          className="dropdown-item"
          
          onClick={() => {
            changeToggle(4);
          }}
        >
          View Login History
        </span>
      </div>
    </div>
  );
}

export default UserDropDown;
