import { useNavigate } from "react-router-dom";

import "../../css/modal/successNotify.css";
// import CloseBtn from "../../img/Close-Square.svg"
import SNotify from "../../img/successNotify.svg";

export default function SuccessNotify({ setView, setModal, close }) {
  // const [display, setDisplay] = useState("")
  // const closeDisplay = () => {
  //     setView("hidden")
  //     setModal(0)
  //     setDisplay("close")
  // }
  const navigate = useNavigate();

  const onSubmit = () => {
    if (close === "close") {
      setView("hidden");
      setModal(0);
    } else {
      setView("");
      setModal(0);
      navigate("/kyc");
    }
  };
  return (
    <>
      <div class={`modbox`}>
        {/* <div onClick={closeDisplay}><strong class="bold-text">Cancel</strong></div> */}
        <div class="flexedcont">
          <img
            src={SNotify}
            loading="lazy"
            width="63"
            height="63"
            alt=""
            class="image"
          />
          <div class="text-block-2">
            You have successfully approved the customer`s selfie
          </div>
          <button onClick={onSubmit} className="close-button w-button">
            <strong>Close</strong>
          </button>
        </div>
        {/* <img src={CloseBtn} loading="lazy" width="24" height="24" alt="" class="image-2" onClick={closeDisplay} /> */}
      </div>
    </>
  );
}
