import * as types from "../types";

const initialState = {
  analytics: null,
  data: null,
  loading: false,
  error: null
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_DASHBOARD_ANAYTICS:
      return {
        ...state,
        analytics: action.payload
      }
    case types.LOAD_DASHBOARD:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
      case types.LOAD_DASHBOARD_ERROR:
        return {
            ...state,
            error: action.payload,
            loading: false
        }
      case types.SET_LOADING:
        return {
            ...state,
            loading: true
        }

    default:
      return state;
  }
};

export default dashboardReducer;
