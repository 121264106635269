export default function UserBtn() {
  return (
    <svg
      width="36"
      height="40"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.5 27.5002C33.8282 27.5002 38.9583 22.3701 38.9583 16.0418C38.9583 9.71357 33.8282 4.5835 27.5 4.5835C21.1717 4.5835 16.0416 9.71357 16.0416 16.0418C16.0416 22.3701 21.1717 27.5002 27.5 27.5002Z"
        fill="#162082"
      />
      <path
        opacity="0.4"
        d="M7.1687 50.4165C7.1687 41.291 16.2004 33.729 27.5 33.729C38.7997 33.729 47.8313 41.291 47.8313 50.4165C47.8313 50.782 47.551 51.0623 47.1855 51.0623H7.81453C7.44901 51.0623 7.1687 50.782 7.1687 50.4165Z"
        fill="#162082"
        stroke="#162082"
      />
      <path
        d="M52.1813 47.3918L50.4396 45.6501C51.3563 44.2751 51.8834 42.6252 51.8834 40.8606C51.8834 36.0252 47.9646 32.1064 43.1292 32.1064C38.2938 32.1064 34.375 36.0252 34.375 40.8606C34.375 45.696 38.2938 49.6148 43.1292 49.6148C44.8938 49.6148 46.5437 49.0877 47.9187 48.171L49.6604 49.9127C50.0041 50.2565 50.4624 50.4398 50.9208 50.4398C51.3791 50.4398 51.8375 50.2565 52.1813 49.9127C52.8917 49.2023 52.8917 48.0793 52.1813 47.3918Z"
        fill="#162082"
      />
    </svg>
  );
}
