import "../../css/issues-card.css";

export default function IssuesCard({ total, done, undone }) {
  return (
    <div className="issues-container dashboard-card-color">
      <div className=" issues-detail issues-total-section">
        <span>{total.title}</span>
        <h3>{total.value}</h3>
      </div>
      <div className="issues-sub-section">
        <div className="issues-detail">
          <span>{done.title}</span>
          <h3>{done.value}</h3>
        </div>
        <div className="issues-detail">
          <span>
              {undone.title}
          </span>
          <h3 style={{color: "red"}}>{undone.value}</h3>
        </div>
      </div>
    </div>
  );
}
