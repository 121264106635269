// import Sidebar from "../../components/dashboard/sidebar/sidebar";
import Card from "../../components/settlements/card";
import KYCTOP from "../../components/kyc-top/kyc-top";
import { useState } from "react";
import Nav from "../../components/nav";

export default function Settlements() {
  const [text, setText] = useState("");

  return (
    <>
      <div className="gen-cont">
        <div>
          <Nav Active={4}/>
        </div>
        <div className="right-content">
          <KYCTOP url="Settlements" suburl={text} />
          <Card setText={setText} />
        </div>
      </div>
    </>
  );
}
