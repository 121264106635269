import "../../css/modal/add_new_user.css";
import { useEffect, useState } from "react";
import CloseBtn from "../../img/Close-Square.svg";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { userControlActions } from "../../state/actions";
import axios from "../../axios";

export default function AddNewUser({ isUpdate }) {
  const { error } = useSelector((state) => state.userControl);
  const dispatch = useDispatch();

  const { setError, setUserControlToggle, CreateUser } = bindActionCreators(userControlActions, dispatch);

  let close = "";
  const [display, setDisplay] = useState("");
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [role, setRole] = useState("");
  const [roles, setRoles] = useState([]);
  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
        try {
          const res = await axios.get("/role-dropdown")
          setRoles(res.data.data.roles.data)
        } catch (err) {
          setError({top: "OOPS!!!, Something went wrong, please reload and try again"})
        }
        // setLoading(false)
    })()

    //eslint-disable-next-line
  }, [])
  const closeDisplay = () => {
    if (close === null) {
      setDisplay("");
    } else {
      setDisplay("close");
      setUserControlToggle(0);
      setError("")
    }
  };

  const onSubmit = (e) => {
    e.preventDefault()
    if (email.trim() === "") {
      setError({ email: "Email field is required" });
    } else if (firstname.trim() === "") {
      setError({ firstname: "First Name field is required" });
    }else if (lastname.trim() === "") {
      setError({ lastname: "Last Name field is required" });
    }else if (role.trim() === "") {
      setError({ role: "Role field is required" });
    }else {
      if (isUpdate) {
        setUserControlToggle(10);
      } else {
        CreateUser(email, firstname, lastname, role, 14)
        // FetchUsers()
      }
    }
  };

  return (
    <div className={`display-new-user-details ${display}`}>
      <div className="add-new-user-container">
        <div className="canc-icon" onClick={closeDisplay}>
          <img
            src={CloseBtn}
            loading="lazy"
            width="24"
            height="24"
            alt="cancel icon"
            onClick={closeDisplay}
          />
          {/* <div className="canc"><Cancel /></div> */}
        </div>
        <p
          style={{
            margin: "auto",
            marginBottom: "0px",
          }}
        >
          {isUpdate ? "Edit User Details" : "Add New User"}
        </p>
        {error.top && <span className="top-error-span">{error.top}</span>}
        <form className="add-new-user-content" onSubmit={onSubmit}>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            placeholder="jdoe@gmail.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {error.email && <span className="user-error-span">{error.email}</span>}
          <label htmlFor="first_name">First Name</label>
          <input
            type="type"
            name="first_name"
            placeholder="John"
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
          />
          {error.firstname && <span className="user-error-span">{error.firstname}</span>}
          <label htmlFor="email">Last Name</label>
          <input
            type="text"
            name="last_name"
            placeholder="Doe"
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          />
          {error.lastname && <span className="user-error-span">{error.lastname}</span>}

          <label htmlFor="email">Role</label>
          <select value={role} onChange={(e) => setRole(e.target.value)}>
          
              <option value="">Select Role...</option>
            {roles.map((role, idx) => (
              <option key={idx} value={role.name}>
                {role.name.toUpperCase()}
              </option>
            ))}
            
          </select>
          {error.role && <span className="user-error-span">{error.role}</span>}
          <div className="add-user-btn-sec">
            <button className="add-user-btn">
              {isUpdate ? "Update" : "Create User"}
            </button>
            <button className="add-user-cancel" onClick={closeDisplay}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

// function Cancel() {
//   return (
//     <svg
//       width="24"
//       height="24"
//       viewBox="0 0 24 24"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M17.208 0C21.276 0 24 2.856 24 7.104V16.9092C24 21.1452 21.276 24 17.208 24H6.804C2.736 24 0 21.1452 0 16.9092V7.104C0 2.856 2.736 0 6.804 0H17.208ZM15.612 8.3652C15.204 7.956 14.544 7.956 14.124 8.3652L12 10.5L9.864 8.3652C9.444 7.956 8.784 7.956 8.376 8.3652C7.968 8.7732 7.968 9.4452 8.376 9.852L10.512 11.9892L8.376 14.1132C7.968 14.5332 7.968 15.1932 8.376 15.6C8.58 15.804 8.856 15.9132 9.12 15.9132C9.396 15.9132 9.66 15.804 9.864 15.6L12 13.4772L14.136 15.6C14.34 15.8172 14.604 15.9132 14.868 15.9132C15.144 15.9132 15.408 15.804 15.612 15.6C16.02 15.1932 16.02 14.5332 15.612 14.1252L13.476 11.9892L15.612 9.852C16.02 9.4452 16.02 8.7732 15.612 8.3652Z"
//         fill="#162082"
//       />
//     </svg>
//   );
// }
