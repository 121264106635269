import KycInput from "../../components/input/input"
import kimg from "../../img/ksearch.svg"
import VendorDropdown from "./VendorDropdown"
// import VendorModalUserAction from "../../components/vendorModals/vendorModal"
// import { useState } from "react"

export default function VendorTable({ parse }) {
    // const [toggle, setToggle] = useState(false)

    const vData = [
        {
            id: 1,
            name: "Vendor Name 1",
            assigned: 43,
            returned: 39,
            pending: 4,
            status: "Active",
        },
        {
            id: 2,
            name: "Vendor Name 1",
            assigned: 21,
            returned: 21,
            pending: 0,
            status: "Active",
        },
        {
            id: 3,
            name: "Vendor Name 1",
            assigned: 45,
            returned: 40,
            pending: 5,
            status: "Active",
        },
        {
            id: 4,
            name: "Vendor Name 1",
            assigned: 32,
            returned: 30,
            pending: 2,
            status: "Active",
        }
    ]

    // const changeToggle = (value) => {
    //     setToggle(!toggle)
    // }
    return (
        <>
            <div className="vendor-table">
                <div className="search-section2">
                    <div className="kscont"><KycInput label="Search Records" /><img src={kimg} alt="" /></div>

                </div>
                <div className="vtable-wrapper">
                    <div className="vendor-table-content">
                        <table className="vendor-table-main">
                            <tr>
                                <th></th>
                                <th>Vendor Name</th>
                                <th>No Assigned</th>
                                <th>Returned</th>
                                <th>Pending</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                            {vData.map((item) => (
                                <tr className="item-tr">
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>{item.assigned}</td>
                                    <td>{item.returned}</td>
                                    <td>{item.pending}</td>
                                    <td className="green">{item.status}</td>
                                    <td>                                       
                                        {<VendorDropdown acceptToggle={parse}/>}
                                    </td>
                                </tr>
                            ))}
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

