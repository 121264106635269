import axios from "axios";

import Cookies from "js-cookie";

import * as types from "./state/types";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL + "/admin",
});
instance.defaults.headers.common["Accept"] = "application/json";

let AuthToken = Cookies.get(btoa(types.TOKEN));
// if (token) {
//     instance.defaults.headers.common["Authorization"] = "Bearer " + token
// }

instance.interceptors.request.use((config) => {
  if (config.url === "/logout"){
     config.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
    //  "https://api.zojapay.com/api/v1"
  }
  
  if (config.url !== "/login" && config.url !== "/password/reset") {
    config.headers["Authorization"] = "Bearer " + AuthToken;
  }
  return config;
});

instance.interceptors.response.use(
  (config) => {
    return config;
  },
  (err) => {
    console.log(err.response)
    if (err.response.status === 403 && !err.response.request.responseURL.includes("verifications")) {
        Cookies.remove(btoa(types.TOKEN))
        window.location.href="/"
    }
    return Promise.reject(err);
  }
);

export default instance;
