import "../../../css/user-control/login-history.css";
import CloseBtn from "../../../img/Close-Square.svg";
import { useLayoutEffect, useState } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { userControlActions } from "../../../state/actions";
import Pagination from "../../footer-pagination";

function UserControlLoginHistory({ setToggle, id }) {
  const [display, setDisplay] = useState("");
  const closeDisplay = () => {
    setDisplay("close-details");
    setToggle(0);
  };

  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();

  const { GetLoginHistory } = bindActionCreators(userControlActions, dispatch);

  const { loginHistory, error, loginHistoryLoading, userID } = useSelector(
    (state) => state.userControl
  );

  useLayoutEffect(() => {
    GetLoginHistory(currentPage, userID);

    //eslint-disable-next-line
  }, [currentPage]);

  // const { per_page, total } =
  //   loginHistory === null ? { per_page: 0, total: 0 } : loginHistory.meta;

  let userData = loginHistory === null ? [] : loginHistory.data;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  // if (loginHistoryLoading === false && error.err === "loading error") {
  //   return null;
  // }
  return (
    <div className={`display-details ${display}`}>
      <div className={`uControl-login-container`}>
        <div className="uControl-login-header">
          <div className="uControl-login-details">
            <h5>Login History</h5>
          </div>
        </div>

        <div className="cancel-icon6">
          <img
            src={CloseBtn}
            loading="lazy"
            width="24"
            height="24"
            alt="cancel icon"
            onClick={closeDisplay}
          />
        </div>
        {loginHistoryLoading ? null : (
          <>
            {error.err ? (
              <span className="text-danger error-message">OOPS!! an error occurred, Please reload and try again</span>
            ) : (
              <>
                <div className="uControl-table-content">
                  <table className={`u-control-table${display}`}>
                    <tr>
                      <th>Time</th>
                      <th>Machine</th>
                    </tr>
                    {userData.map((item) => (
                      <tr className="user-login-item-tr">
                        <td>
                          <Moment format="DD/MM/YYYY hh:mm A">{item.login_at}</Moment>
                        </td>
                        <td>{`${item.data.platformFamily} (${item.data.browserFamily})`}</td>
                      </tr>
                    ))}
                  </table>
                </div>
                <Pagination
                  className="users-pagination"
                  currentPage={currentPage}
                  totalCount={loginHistory.meta.total}
                  pageSize={loginHistory.meta.per_page}
                  onPageChange={onPageChange}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default UserControlLoginHistory;
