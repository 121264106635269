import "../css/comments.css"
import Closeimg from "../img/Close-Square.svg"
import { useState } from "react"

export default function Comments2 (){
    const [close, setClose] = useState(false)
    return (
        <>
        {close ? (
            null
        ):(
            <div className="comments-container height2">
            <div className="comments-top">
                <h3>Comments</h3>
                <img onClick={()=>setClose(true)} src={Closeimg} alt="img"/>
            </div>
            <div className="commentblock">
                <div className="innerr">
                    <h5>Oladele Oluwadare</h5>
                    <span>09:14am 22/12/2021</span>
                </div>
                <p>Please help approve this material requisition as we currently have a low stock</p>
            </div>
            <div className="commentblock">
                <div className="innerr">
                    <h5>Festus Oluwajere</h5>
                    <span>09:14am 22/12/2021</span>
                </div>
                <p>Please help approve this material requisition as we currently have a low stock</p>
            </div>
            <div className="commentblock">
                <div className="innerr">
                    <h5>Oladele Oluwadare</h5>
                    <span>09:14am 22/12/2021</span>
                </div>
                <p>Please help approve this material requisition as we currently have a low stock</p>
            </div>
     
            <div className="comment-btnhold">
                <button>Submit</button>
            </div>
         </div> 
        )}
      
        </>
    )
}