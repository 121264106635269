import { Link } from 'react-router-dom'
import '../../css/login2.css'
import unsplash from '../../img/unsplash.png'
import avatar from '../../img/avatar.svg'

function Login2() {

    const handleLogin = (e) => {
        e.preventDefault();
    
      }

  return (
    <div className="dashboard">
        <div className="welcome-log2-container">
         <div className="welcome-login2-container">
        <h4 className="welcome-header2">Welcome Back !</h4>
        <form onSubmit={handleLogin}>
          <div className="welcome-login2-contents">
              <div className="welcome-email-section2">
                <img src={unsplash} alt="female" />
              </div>
              <p className="username">Bola Soetan</p>
              <Link to='/welcomeBackLogin'className="btn-primary-1" >Login</Link>
          </div>
        </form>
         </div>
      </div>
      <div className="new-login">
        <img className="avata" src={avatar} alt="uparrow" /><Link to='/login' className="btn" >Login as Another User</Link>
        </div>
    </div>
  )
}

export default Login2