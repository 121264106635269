import * as types from "../types";
import Cookies from "js-cookie";

const initialState = {
  currentUser: {},
  currentUserRole: {},
  loading: false,
  error: {},
  authenticate: false,
  user: localStorage.getItem(btoa(types.USERNAME)),
  token: Cookies.get(btoa(types.TOKEN)),
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN:
      const value = btoa(
        JSON.stringify({
          email: action.payload.user.email,
          name: action.payload.user.name,
          userID: action.payload.user.id,
        })
      );
      localStorage.setItem(btoa(types.USERNAME), value);
      setCookie(action.payload.token, action.payload.expires_in);

      return {
        ...state,
        loading: false,
        authenticate: true,
        error: "",
        user: value,
        token: action.payload.token,
      };
    case types.LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.LOGOUT:
      Cookies.remove(btoa(types.TOKEN));
      return {
        ...state,
        loading: false,
        authenticate: false,
        error: "",
      };
    case types.CLEAR_LOCAL:
      Cookies.remove(btoa(types.TOKEN));
      delete localStorage.removeItem(btoa(types.USERNAME));
      return {
        ...state,
        loading: false,
        authenticate: false,
        error: "",
        user: null,
      };
    case types.SET_USER:
      delete localStorage.removeItem(btoa(types.USERNAME));
      return {
        ...state,
        user: null,
        loading: false,
      };
    case types.SET_LOADING:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case types.FETCH_USER:
      return {
        ...state,
        currentUser: action.payload,
        currentUserRole: action.roles,
      };
    default:
      return state;
  }
};

function setCookie(token, expire) {
  const t = new Date();
  t.setTime(expire);

  Cookies.set(btoa(types.TOKEN), token, { path: "", expires: t.getDate() });
}
export default reducer;
