import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "../../../css/kyc/kyc-table.css";
import { KycLevelsInput } from "../../input/input";
import kimg from "../../../img/ksearch.svg";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../footer-pagination";
import { bindActionCreators } from "redux";
import { KYC } from "../../../state/actions";

export default function KycTable() {
  const [currentPage, setCurrentPage] = useState(1);
  // const [pageSize, setPageSize] = useState(3);

  const [search, setSearch] = useState("");

  const {
    kycLevelOne,
    kycLevelOneMeta,
    levelOneLoading,
    searchKyc1Data,
    searchKyc1Meta,
  } = useSelector((state) => state.kyc);

  const dispatch = useDispatch();
  const { searchKycLevelOne, FetchKYCLevelOne } = bindActionCreators(
    KYC,
    dispatch
  );

  const navigate = useNavigate();

  const navigatePage = (id) => {
    const newId = btoa(id);
    navigate(`/kyc/level-two/${newId}`);
  };

  let userData = searchKyc1Data !== null ? searchKyc1Data : kycLevelOne;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (search.trim() === "") {
      FetchKYCLevelOne(currentPage);
    } else {
      searchKycLevelOne(search, currentPage);
    }

    //eslint-disable-next-line
  }, [currentPage]);

  const { per_page, total } = searchKyc1Meta ? searchKyc1Meta : kycLevelOneMeta;

  return (
    <>
      <div className="kyc-cont1">
        <div className="kyc-search-cont">
          <div className="kscont">
          <KycLevelsInput
              label="Search Records"
              searchValue={searchKycLevelOne}
              search={search}
              setSearch={setSearch}
            />
            <img src={kimg} alt="" />
          </div>
        </div>
        <div className="kyc-table-cont">
          <table className="kyc-level-table">
            <tr style={{ display: "fixed" }}>
              <th>#</th>
              <th>Profile Name</th>
              <th>BVN</th>
              <th>Phone Number</th>
            </tr>
            {userData.map((item, idx) => (
              <tr className="kyc-table-tr">
                <th>{(currentPage - 1) * per_page + 1 + idx}</th>
                <th>{item.name || "-"}</th>
                <th>{(item.bvn && item.bvn.bvn_number) || "-"}</th>
                <th>{item.telephone || "-"}</th>
                <td>
                  <button
                    onClick={() => navigatePage(item.id)}
                    className="kyc2-kbtn"
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
            {userData.length === 0 ? (
              <td colSpan={5} className="text-danger">
                No record found
              </td>
            ) : null}
          </table>
        </div>

        {!levelOneLoading && (
          <Pagination
            className="users-pagination"
            currentPage={currentPage}
            totalCount={total}
            pageSize={per_page}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </>
  );
}
