import "./compare.css";
import Closebtn from "../../img/Close-Square.svg";
import Rotatebtn from "../../img/rotate.png";
import { useState } from "react";
import ImageViewModal from "./image-view";
// import ImageViewModal2 from "./image-view2";

export default function DoubleImage({ setToggle, images }) {
  const [close, setClose] = useState(false);
  const [modal, setModal] = useState(0);
  const [rotate, setRotate] = useState(0);
  const [rotate2, setRotate2] = useState(0);
  const [image, setImage] = useState("");

  function handleRotate() {
    const moveRotate = rotate + 1;
    if (moveRotate === 4) {
      return setRotate(0);
    }
    return setRotate(moveRotate);
  }
  function handleRotate2() {
    const moveRotate2 = rotate2 + 1;
    if (moveRotate2 === 4) {
      return setRotate2(0);
    }
    return setRotate2(moveRotate2);
  }

  function closeModal() {
    setClose(true);
    setToggle(0);
  }
  function handletoggle(value) {
    setImage(value)
    setModal(1);
    setClose(true);
  }
  // function handletoggle2(value) {
  //   setModal(2);
  //   setClose(true);
  // }
  const showModal = () => {
    if (modal === 1) {
      return <ImageViewModal setToggle={setToggle} image={image} />;
    }
    // else
    // if (modal === 2) {
    //     return <ImageViewModal2 setToggle={setToggle}/>;
    // }
  };

  return (
    <>
      {close ? (
        ""
      ) : (
        <div className="double_cover">
          <img onClick={closeModal} src={Closebtn} alt="clasebtn" />
          <div className="comparehead">
            <p>Image Comparison</p>
          </div>
          <div className="comparebody">
            <div className="comparee_selfie cimg">
              <div className="imgcompareholder">
                <img
                  className={`moved${rotate}`}
                  onClick={() => handletoggle(images[0].value)}
                  src={images[0].value}
                  alt="id"
                />
              </div>
              <div className="coampare_base">
                <p>{images[0].key}</p>
                <img onClick={handleRotate} src={Rotatebtn} alt="rotate-icon" />
              </div>
            </div>
            <div className="comparee_selfie cimg">
              <div className="imgcompareholder">
                <img
                  className={`movedd${rotate2}`}
                  onClick={() => handletoggle(images[1].value)}
                  src={images[1].value}
                  alt="selfie"
                />
              </div>
              <div className="coampare_base">
                <p>{images[1].key}</p>
                <img
                  onClick={handleRotate2}
                  src={Rotatebtn}
                  alt="rotate-icon"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {modal ? showModal() : null}
    </>
  );
}
