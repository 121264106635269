import React, { forwardRef, useRef, useState } from "react";

import "./css/new-app-notification.css";
import KYCTOP from "../../components/kyc-top/kyc-top";
import Nav from "../../components/nav";
import fileImg from "../../img/file.svg";
import date from "../../img/date.svg";
import { IoImageOutline } from "react-icons/io5";
import AppNotificationModal, {
  RoleCreationError,
} from "../../components/vendorModals/appNotificationModal";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { AppContentActions } from "../../state/actions";
import moment from "moment";

function NewEmailNotification() {
  const dispatch = useDispatch();
  const { emailCreateToggle, emailCreateLoading, emailError } = useSelector(
    (state) => state.appContent
  );
  const { createEmailNotification, setCreateEmailToggle } = bindActionCreators(
    AppContentActions,
    dispatch
  );

  const file = useRef();
  const file2 = useRef();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  // const [toggle, setToggle] = useState(0);
  const [currentDate, setCurrentDate] = useState(null);

  const [hour, setHour] = useState(9);
  const [minute, setMinute] = useState(30);
  const [meridian, setMeridian] = useState("AM");
  const [link, setLink] = useState("");

  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [notificationInterval, setNotificationInterval] = useState("");
  const [recipient, setRecipient] = useState("all user");
  const [spreadSheetFile, setSpreadSheetFile] = useState(null);
  const [spreadSheetFileName, setSpreadSheetFileName] = useState("");
  const [displayUpload, setDisplayUpload] = useState(false);

  const [error, setError] = useState({});

  const handleHourUp = () => {
    if (hour < 12) {
      setHour(hour + 1);
    } else if (hour === 12) {
      setHour(0);
    }
  };
  const handleHourDown = () => {
    if (hour > 0) {
      setHour(hour - 1);
    } else if (hour === 0) {
      setHour(12);
    }
  };

  const handleMinuteUp = () => {
    if (minute < 59) {
      setMinute(minute + 1);
    } else if (minute === 59) {
      setMinute(0);
    }
  };
  const handleMinuteDown = () => {
    if (minute > 0) {
      setMinute(minute - 1);
    } else if (minute === 0) {
      setMinute(59);
    }
  };

  const handleMeridian = () => {
    if (meridian === "AM") {
      setMeridian("PM");
    } else {
      setMeridian("AM");
    }
  };

  const navigate = useNavigate();

  const onUpload = () => {
    file.current.click();
  };
  const onUpload2 = () => {
    file2.current.click();
  };

  const onFileUpload = (e) => {
    setSelectedFile(e.target.files[0]);
    if (e.target.files[0]) {
      setFileName(e.target.files[0].name);
    }
  };

  const onFileUpload2 = (e) => {
    setSpreadSheetFile(e.target.files[0]);
    if (e.target.files[0]) {
      setSpreadSheetFileName(e.target.files[0].name);
    }
  };

  const onClickRecipient1 = (e) => {
    setRecipient("specific users");
    setDisplayUpload(true);
  };
  const onClickRecipient2 = (e) => {
    setRecipient("all user");
    setDisplayUpload(false);
  };

  const DateFormat = forwardRef(({ value, onClick }, ref) => (
    <>
      <div className="notify-date-section" onClick={onClick} ref={ref}>
        {value || "DD-MM-YYYY"}
        <img src={date} loading="lazy" width="15" height="20" alt="date icon" />
      </div>
    </>
  ));

  const onSubmit = () => {
    if (title.trim() === "") {
      setError({ title: "This field is required" });
    } else if (message.trim() === "") {
      setError({ message: "This field is required" });
    } else {
      setError({});
      let body = new FormData();
      body.append("title", title);
      body.append("message", message);
      body.append("link", link);
      body.append("image", selectedFile);
      body.append("interval", notificationInterval.toLowerCase());
      if (currentDate) {
        body.append("delivery_date", moment(currentDate).format("YYYY-MM-DD"));
      }

      body.append(
        "delivery_time",
        String(hour).padStart(2, 0) + ":" + String(minute).padStart(2, 0)
      );
      body.append("recipients", recipient);
      if (recipient === "specific users") {
        body.append("spreadsheet", spreadSheetFile);
      }
      body.append("delivery_meridiem", meridian.toLocaleLowerCase());
      body.append("type", "email notification");
      body.append("status", "active");

      createEmailNotification(body);
    }
  };

  const handleToggle = () => {
    if (emailCreateToggle === 0) {
      return null;
    }
    if (emailCreateToggle === 1) {
      return (
        <div className="close-deactivate">
          <AppNotificationModal
            notify={"You have successfully created a new Email Notification"}
            setToggle={setCreateEmailToggle}
          />
        </div>
      );
    }
    if (emailCreateToggle === 2) {
      return (
        <div className="close-deactivate">
          <RoleCreationError
            notify={`OOPS! Something went wrong, Please try again"`}
            setToggle={setCreateEmailToggle}
            close="close"
          />
        </div>
      );
    }
  };
  return (
    <div className="app-notification">
      <div className="">
        <Nav Active={6} />
      </div>
      <div className="right-content app-notification-content">
        <div className="">
          <KYCTOP url="App Contents" suburl="/App Notifications" />
        </div>
        {emailError.top && (
          <span className="text-danger">{emailError.top}</span>
        )}
        <div className="notification-content-section">
          <h6 className="notification-title">New Email Notification</h6>
          <div className="notification-title-text-input">
            <h6>Notification Title</h6>
            <input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              type="text"
              name="name"
              id="name"
              placeholder="Enter Mail Title"
            />
          </div>
          {error.title && <span className="text-danger">{error.title}</span>}

          <div className="notification-textArea">
            <h6>Notification Message</h6>
            <textarea
              name="message"
              id=""
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows="10"
              placeholder="Enter mail body"
            ></textarea>
          </div>
          {error.message && (
            <span className="text-danger">{error.message}</span>
          )}

          <div className="notification-image-section">
            <div className="">
              <h5>Attach Image (Optional)</h5>
              <div className="image-section-div" onClick={onUpload}>
                <IoImageOutline size={20} />
                <div className="notification-img-txt">
                  {fileName === "" ? (
                    "Click here to attach image"
                  ) : (
                    <div style={{ width: "250px" }}>{fileName}</div>
                  )}
                </div>
                <input
                  type="file"
                  ref={file}
                  style={{ display: "none" }}
                  onChange={onFileUpload}
                ></input>
              </div>
            </div>

            <div className="select-section">
              <h5>Embed Link in Image (Optional)</h5>
              <div className="image-section-div">
              <i class="fa fa-link" aria-hidden="true"></i>
                <input
                  type="input"
                  className="embed-link-class"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                  placeholder="Paste embed link"
                />
              </div>
            </div>
          </div>

          <div className="notification-date-section">
            <div>
              <h5>Notification Interval (Optional)</h5>
              <div className="">
                <select
                  className="select-option"
                  name=""
                  id=""
                  value={notificationInterval}
                  onChange={(e) => setNotificationInterval(e.target.value)}
                >
                  <option value="">Select an action</option>
                  <option value="one time">One Time</option>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                </select>{" "}
              </div>
            </div>

            <div className="notification-date">
              <h5>Select Delivery Date (Optional)</h5>
              <DatePicker
                closeOnScroll={true}
                selected={currentDate}
                onChange={(date) => setCurrentDate(date)}
                isClearable
                customInput={<DateFormat />}
              />
            </div>

            <div className="notification-date">
              <h5>Enter Delivery Time</h5>
              <div className="notification-time">
                <div className="time-section">
                  <div className="time-arrow" onClick={handleHourUp}>
                    <i className="fa fa-angle-up" aria-hidden="true"></i>
                  </div>
                  <div className="time-box">
                    <span>{String(hour).padStart(2, 0)}</span>
                  </div>
                  <div className="time-arrow" onClick={handleHourDown}>
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </div>
                </div>
                <div className="time-section column">:</div>
                <div className="time-section">
                  <div className="time-arrow" onClick={handleMinuteUp}>
                    <i className="fa fa-angle-up" aria-hidden="true"></i>
                  </div>
                  <div className="time-box">
                    <span>{String(minute).padStart(2, 0)}</span>
                  </div>
                  <div className="time-arrow" onClick={handleMinuteDown}>
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </div>
                </div>
                <div className="time-section" style={{ marginLeft: "5px" }}>
                  <div className="time-arrow" onClick={handleMeridian}>
                    <i className="fa fa-angle-up" aria-hidden="true"></i>
                  </div>
                  <div className="time-box">
                    <span>{meridian}</span>
                  </div>
                  <div className="time-arrow" onClick={handleMeridian}>
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="notification-radio-section">
            <h5>Notification Receipients</h5>
            <div className="notification-radio-divs">
              <div class="">
                <input
                  type="radio"
                  name="action"
                  value={recipient}
                  onChange={onClickRecipient1}
                  checked={recipient === "specific users"}
                />
                <label for="uploadspecificusers">Upload Specific Users</label>
              </div>
              <div class="new-app-wrapper-class">
                <input
                  type="radio"
                  name="action"
                  value={recipient}
                  onChange={onClickRecipient2}
                  checked={recipient === "all user"}
                />
                <label for="allusers">All Users</label>
              </div>
            </div>
          </div>
          {error.recipient && (
            <span className="text-danger">{error.recipient}</span>
          )}

          {displayUpload ? (
            <>
              <div className="notification-footer-2" onClick={onUpload2}>
                <img src={fileImg} width="25px" height="21px" alt="" />{" "}
                <div>
                  {spreadSheetFileName === "" ? (
                    "Import File"
                  ) : (
                    <div style={{ width: "250px", wordWrap: "break-word" }}>
                      {spreadSheetFileName.substring(0, 26)}
                    </div>
                  )}
                </div>
              </div>
              <input
                type="file"
                ref={file2}
                style={{ display: "none" }}
                onChange={onFileUpload2}
              ></input>
            </>
          ) : null}

          <div className="notification-footer-3">
            <button
              className="notification-footer-botton"
              onClick={onSubmit}
              disabled={emailCreateLoading}
            >
              Create Notification
            </button>
            <button
              onClick={() => navigate("/app-content")}
              className="notification-footer-close"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      {handleToggle()}
    </div>
  );
}

export default NewEmailNotification;
