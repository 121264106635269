import "../../css/reset-password.css";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { useState } from "react";
// import Toggle from "../../components/btn/toggle";

import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import { authActions } from "../../state/actions";
import axios from "../../axios"

import Spinner from "./spinner";
import { useNavigate, useParams } from "react-router-dom";
// import { setError } from "../../state/actions/userControlActions";

function ResetPassword() {
  const { email } = useParams();

  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [internalerror, setInternalerror] = useState("");
  // const [remember, setRemember] = useState(false);

  const { error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { SetError, ClearLocalStorage } = bindActionCreators(
    authActions,
    dispatch
  );

  const handleResetPassword = (e) => {
    e.preventDefault();
    setLoading(true);
    let password = passwordInput.trim()
    let confirm = confirmPassword.trim()
    if ( password === "") {
      SetError({ email: "Password field is required" });
      setLoading(false);
    } else if (password.length < 6) {
      SetError({ email: "Please provide at least 6 characters" });
      setLoading(false);
    }else if ( confirm === "") {
      SetError({ password: "Confirm Password field is required" });
      setLoading(false);
    }else if (password !== confirm) {
      SetError({ top: "Password do not match" });
      setLoading(false);
    } else {
      Submit()
    }
  };


  const navigate = useNavigate()

  const Submit = async () => {
    const body = {
      password: passwordInput,
      password_confirmation: confirmPassword,
      email: email
    }
    try {
      await axios.post("/password/reset", body)
      // await axios.post("https://thingproxy.freeboard.io/fetch/http://143.110.226.45/v1/admin/password/reset", body)
      // await axios.post("https://api.zojapay.com/api/v1/admin/password/reset", body)
      

      ClearLocalStorage()
      setLoading(false)
      navigate("/login")
      
    } catch (err) {
      console.log(err.response.data.error.message)
      setInternalerror(err.response.data.error.message)
      setLoading(false)
    }
  }

  const handlePasswordChange = (e) => {
    setPasswordInput(e.target.value);
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div className="dashboard-reset">
      <div className="login-containers">
        <div className="login-container">
          <form className="login-form-section" onSubmit={handleResetPassword}>
            <h4 className="login-header">Reset Password</h4>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                color: "#222B88"
              }}
            >
              {/* <p style={{ width: "80%" }}>
                Welcome <strong>Kendrick Lamar</strong>, you have been profiled
                on this admin tool as a <strong>Support Officer</strong>
              </p> */}
              {/* <p style={{ width: "80%", fontWeight: "300", fontSize: "14px" }}>
                Please complete your signup by securing your profile with a
                password
              </p> */}
              <p style={{ width: "100%", fontWeight: "300", fontSize: "15px" }}>
                Kindly complete your password reset below
              </p>
            </div>
            {error.top && <span className="top-span">{error.top}</span>}
            {internalerror && <span className="top-span">{internalerror}</span>}
            <div className="login-contents">
              <div className="login-content">
                {error.email && <span className="span">{error.email}</span>}
                <div className="login-input-section login-password-section">
                  <label className="pass" htmlFor="password">
                    Password
                  </label>
                  <input
                    type={passwordType}
                    name="password"
                    value={passwordInput}
                    onChange={handlePasswordChange}
                    placeholder="Create password"
                  />
                  <span onClick={togglePassword}>
                    {passwordType === "password" ? (
                      <BsEyeSlash color="#162082" size={25} />
                    ) : (
                      <BsEye color="#162082" size={25} />
                    )}
                  </span>
                </div>
                {error.password && (
                  <span className="span">{error.password}</span>
                )}
                <div className="login-input-section login-password-section">
                  <label className="pass" htmlFor="password">
                    Reconfirm Password
                  </label>
                  <input
                    type={passwordType}
                    name="password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    placeholder="*************"
                  />
                  <span onClick={togglePassword}>
                    {passwordType === "password" ? (
                      <BsEyeSlash color="#162082" size={25} />
                    ) : (
                      <BsEye color="#162082" size={25} />
                    )}
                  </span>
                </div>
              </div>

              {/* <div className="toggle">
                <Toggle checked={remember} setRemember={setRemember} />
                <p>Remember me</p>
              </div> */}
            </div>
            <button
              type="submit"
              className="login-btn-primary"
              disabled={loading ? true : false}
            >
              {loading ? <Spinner /> : "Set New Password"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
