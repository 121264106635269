import { useNavigate } from "react-router-dom";

import "../../../css/kyc/pin-reset.css";
import { KycLevelsInput } from "../../input/input";
import kimg from "../../../img/ksearch.svg";
import Pagination from "../../footer-pagination";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { KYC } from "../../../state/actions";

export default function PinReset() {
  const [currentPage, setCurrentPage] = useState(1);
  // const [pageSize, setPageSize] = useState(3);

  const [search, setSearch] = useState("");

  const {
    kycPinVerification,
    kycPinVerificationMeta,
    searchKycPinVerificationData,
    searchKycPinVerificationMeta,
  } = useSelector((state) => state.kyc);

  const dispatch = useDispatch();
  const { FetchPinVerification, searchKycPin } = bindActionCreators(
    KYC,
    dispatch
  );

  const filterIDVerification = (verifications) => {
    const verification = verifications.filter(
      (v) => v.verification_type === "reset-pin-selfie-verification"
    );
    return verification;
  };

  useEffect(() => {
    if (search.trim() === "") {
      FetchPinVerification(currentPage);
    } else {
      searchKycPin(search, currentPage);
    }

    // eslint-disable-next-line
  }, [currentPage]);

  const { per_page, total } = searchKycPinVerificationMeta
    ? searchKycPinVerificationMeta
    : kycPinVerificationMeta;

  const navigate = useNavigate();

  const navigatePage = (id) => {
    const newId = btoa(id);
    navigate(`/kyc/pin-reset-view/${newId}`);
  };

  let userData =
  (searchKycPinVerificationData !== null) ? searchKycPinVerificationData : kycPinVerification;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="kyc-cont1">
        <div className="kyc-search-cont">
          <div className="kscont">
            <KycLevelsInput
              label="Search Records"
              searchValue={searchKycPin}
              search={search}
              setSearch={setSearch}
            />
            <img src={kimg} alt="" />
          </div>
        </div>
        <div className="kyc-table-cont">
          <table className="kyc-level-table">
            <tr style={{ display: "fixed" }}>
              <th>#</th>
              <th>Profile Name</th>
              <th>BVN</th>
              <th>Phone Number</th>
            </tr>
            {userData.map((item, idx) => (
              <tr key={idx}  className="kyc-table-tr">
                <th>{(currentPage - 1) * per_page + 1 + idx}</th>
                <th>
                  {item.name ||
                    (item.bvn &&
                      item.bvn.first_name + " " + item.bvn.last_name) ||
                    "-"}
                </th>
                <th>{(item.verifications && filterIDVerification(item.verifications)[0].bvn) || "-"}</th>
                <th>{item.telephone || "-"}</th>
                <td>
                  <button
                    onClick={() => navigatePage(item.id)}
                    className="kyc2-kbtn"
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
            {userData.length === 0 ? (
              <td colSpan={5} className="text-danger">
                No record found
              </td>
            ) : null}
          </table>
        </div>
        {userData.length > 0 && (
          <Pagination
            className="users-pagination"
            currentPage={currentPage}
            totalCount={total}
            pageSize={per_page}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </>
  );
}
