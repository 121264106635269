import '../../css/welcomeBackLogin.css'
import { BsEyeSlash, BsEye } from 'react-icons/bs'
import { useState } from 'react'
import unsplash from '../../img/unsplash.png'
import uparrow from '../../img/uparrow.svg'
import avatar from '../../img/avatar.svg'
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux"
import { authActions } from '../../state/actions'
import Spinner from './spinner'

function WelcomeBackLogin() {
  const [passwordType, setPasswordType] = useState("password")
  const [passwordInput, setPasswordInput] = useState("")

  const { name, email } = authActions.DecodeToken()

  const dispatch = useDispatch()

  const { error, loading } = useSelector(state => state.auth)

  const { Login, SetError, SetLoading, ClearLocalStorage } = bindActionCreators(authActions, dispatch)
  let navigate = useNavigate();

  const ClickLogin = (e) => {
    e.preventDefault()
    ClearLocalStorage()
    navigate("/login")
  }

  const handleLogin = (e) => {
    e.preventDefault();
    SetLoading()
    if (passwordInput.trim() === "") {
      SetError({ top: "password field is required" })
    } else {
      Login(email, passwordInput, true)
    }

  }

  const handlePasswordChange = (e) => {
    setPasswordInput(e.target.value);
  }

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return
    }
    setPasswordType("password")
  }

  return (
    <div className="dashboard">
      <div className="welcome-log-container">
        <div className="welcome-login-container">
          <h4 className="welcome-header">Welcome Back !</h4>
          <form onSubmit={handleLogin}>
            <div className="welcome-login-contents">

              <div className="welcome-email-section">
                <img src={unsplash} alt="female" />
              </div>
              <div className='welcome-name-section'>{name}</div>
              {error.top && <span className='error-span'>{error.top}</span>}
              <div className="welcome-input-section">

                <label className="welcome-pass" htmlFor="password">Password</label>
                <input type={passwordType} name="password" value={passwordInput} onChange={handlePasswordChange} placeholder='Enter your AD password' />
                <span className="eye-icon" onClick={togglePassword}>
                  {passwordType === "password" ? <BsEyeSlash /> : <BsEye />}
                </span>
                <div className="welcome-span">
                  {loading ? <Spinner /> : <img onClick={handleLogin} className="img" src={uparrow} alt="uparrow" />}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="new-login">
        <img className="avata" src={avatar} alt="uparrow" /><a href="#!" className="btn" onClick={ClickLogin} >Login as Another User</a>
      </div>

    </div>
  )
}

export default WelcomeBackLogin