export default function SupportBtn() {
  return (
    <svg
      width="45"
      height="50"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.8749 6.1248C28.5999 4.6498 31.4249 4.6498 33.1749 6.1248L37.125 9.52507C37.875 10.1751 39.2749 10.7 40.2749 10.7H44.5249C47.1749 10.7 49.3499 12.8748 49.3499 15.5248V19.775C49.3499 20.75 49.8749 22.175 50.5249 22.925L53.925 26.8749C55.4 28.5999 55.4 31.425 53.925 33.175L50.5249 37.1249C49.8749 37.8749 49.3499 39.2749 49.3499 40.2749V44.5251C49.3499 47.1751 47.1749 49.3499 44.5249 49.3499H40.2749C39.2999 49.3499 37.875 49.8748 37.125 50.5248L33.1749 53.9248C31.4499 55.3998 28.6249 55.3998 26.8749 53.9248L22.925 50.5248C22.175 49.8748 20.775 49.3499 19.775 49.3499H15.4499C12.7999 49.3499 10.6249 47.1751 10.6249 44.5251V40.2499C10.6249 39.2749 10.1 37.8749 9.47497 37.1249L6.09995 33.15C4.64995 31.425 4.64995 28.625 6.09995 26.9L9.47497 22.925C10.1 22.175 10.6249 20.775 10.6249 19.8V15.5248C10.6249 12.8748 12.7999 10.7 15.4499 10.7H19.775C20.75 10.7 22.175 10.1751 22.925 9.52507L26.8749 6.1248Z"
        stroke="#162082"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.7251 37.05L28.3501 39.8499C28.8251 40.3249 29.8751 40.55 30.5751 40.55H35.0001C36.4001 40.55 37.9251 39.5001 38.2751 38.1001L41.075 29.55C41.65 27.925 40.6001 26.5 38.8501 26.5H34.1751C33.4751 26.5 32.9001 25.9252 33.0001 25.1002L33.575 21.3502C33.8 20.3002 33.1001 19.1251 32.0501 18.7751C31.1251 18.4251 29.9501 18.9 29.4751 19.6L24.675 26.7249"
        stroke="#162082"
        stroke-width="3"
        stroke-miterlimit="10"
      />
      <path
        d="M18.75 37.0499V25.6C18.75 23.95 19.45 23.375 21.1 23.375H22.275C23.9 23.375 24.625 23.95 24.625 25.6V37.0499C24.625 38.6749 23.925 39.275 22.275 39.275H21.1C19.45 39.275 18.75 38.6999 18.75 37.0499Z"
        stroke="#162082"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
