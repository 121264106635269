import { useEffect} from "react";
import "../../css/app-content/card.css";
import Kycimg from "../kyc/kyc-img/kyc-img";
import InAppNotifications from "./in_app_notifications";
import EmailNotification from "./email_notification";
import ArticleNotifications from "./articles_notifications";
import FaqNotifications from "./faq_notifications";
import VendorSuccessNotify from "../vendorModals/vendorSuccessNotification";
import AppNotificationModal, { RoleCreationError } from "../vendorModals/appNotificationModal";
import InAppDeleteModal from "../../pages/app-contents/app-content-modal/app-delete-modal";
import EmailDeleteModal from "../../pages/app-contents/app-content-modal/email-delete-modal";
import ArticleDeleteModal from "../../pages/app-contents/app-content-modal/article-delete-modal";
import FaqDeleteModal from "../../pages/app-contents/app-content-modal/faq-delete-modal";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { AppContentActions } from "../../state/actions";

export default function SettlementCard({ setText }) {
//   const [active, setActive] = useState(0);
//   const [toggle, setToggle] = useState(0);

  const {
    inAppNotificationMeta,
    emailNotificationMeta,
    articlesNotificationMeta,
    faqsNotificationMeta,
    inAppLoading,
    emailLoading,
    articlesLoading,
    faqsLoading,
    activeCard,
    toggle
  } = useSelector((state) => state.appContent);
  const dispatch = useDispatch();
  const { FetchInAppNotification, FetchEmailNotification, FetchArticlesNotification, FetchFAQsNotification, setActiveCard, setToggle } = bindActionCreators(AppContentActions, dispatch);
  // const [id, setId] = useState("")
  useEffect(() => {
    FetchInAppNotification();
    FetchEmailNotification();
    FetchArticlesNotification();
    FetchFAQsNotification();
    // eslint-disable-next-line
  }, []);
  const HandleToggle = () => {
    if (toggle === 0) {
      return null;
    }
    if (toggle === 3) {
      return (
        <div className="display-deactivate-control close-deactivate">
          <VendorSuccessNotify
            notify={"User details successfully updated"}
            setToggle={setToggle}
          />
        </div>
      );
    }

    if (toggle === 4) {
      return (
        <div className="user-ven-detail">
          {" "}
          <InAppDeleteModal
            content={"Are you sure you want to delete this notifications"}
            value={5}
          />
        </div>
      );
    }
    if (toggle === 5) {
      return (
        <div className="close-deactivate">
          <AppNotificationModal
            notify={"You have successfully deleted the App Notification"}
            setToggle={setToggle}
          />
        </div>
      );
    }
    if (toggle === 6) {
      return (
        <div className="user-ven-detail">
          {" "}
          <EmailDeleteModal
            content={"Are you sure you want to delete this notifications"}
            value={7}
          />
        </div>
      );
    }
    if (toggle === 7) {
      return (
        <div className="close-deactivate">
          <AppNotificationModal
            notify={"You have successfully deleted the App Notification"}
            setToggle={setToggle}
          />
        </div>
      );
    }
    if (toggle === 8) {
      return (
        <div className="user-ven-detail">
          {" "}
          <ArticleDeleteModal
            
            content={"Are you sure you want to delete this notifications"}
            value={9}
          />
        </div>
      );
    }
    if (toggle === 9) {
      return (
        <div className="close-deactivate">
          <AppNotificationModal
            notify={"You have successfully deleted the App Notification"}
            setToggle={setToggle}
          />
        </div>
      );
    }
    if (toggle === 10) {
      return (
        <div className="user-ven-detail">
          {" "}
          <FaqDeleteModal
            content={"Are you sure you want to delete this notifications"}
            value={11}
          />
        </div>
      );
    }
    if (toggle === 11) {
      return (
        <div className="close-deactivate">
          <AppNotificationModal
            notify={"You have successfully deleted the App Notification"}
            setToggle={setToggle}
          />
        </div>
      );
    }
    if (toggle === 20) {
        return (
            <div className="close-deactivate">
                <RoleCreationError
          notify={`OOPS!!! something went wrong, Please try again`}
          setToggle={setToggle}
        />
            </div>
        )
    }
  };

  const prps = [
    {
      id: 1,
      level: "In-App Notifications",
      num: inAppNotificationMeta.total || 0,
      numText: "Total Records",
    },
    {
      id: 2,
      level: "Email Notifications",
      num: emailNotificationMeta.total || 0,
      numText: "Total Records",
    },
    {
      id: 3,
      level: "Articles",
      num: articlesNotificationMeta.total || 0,
      numText: "Total Records",
    },
    {
      id: 4,
      level: "FAQs",
      num: faqsNotificationMeta.total || 0,
      numText: "Total Records",
    },
  ];
  function handleActive(id, text) {
    setActiveCard(id);
    if (id === 1) {
      setText("/" + text);
    } else if (id === 2) {
      setText("/" + text);
    } else if (id === 3) {
      setText("/" + text);
    } else if (id === 4) {
      setText("/" + text);
    }
  }

  function renderTable() {
    if (activeCard === 0) {
      return <Kycimg />;
    } else if (activeCard === 1) {
      return <InAppNotifications acceptToggle={setToggle} />;
    } else if (activeCard === 2) {
      return <EmailNotification acceptToggle={setToggle} />;
    } else if (activeCard === 3) {
      return <ArticleNotifications acceptToggle={setToggle} />;
    } else if (activeCard === 4) {
      return <FaqNotifications acceptToggle={setToggle} />;
    }
  }

  return (
    <>
    {(inAppLoading && emailLoading && articlesLoading && faqsLoading) &&  (<div className="user-preloader"></div>)}

      <div className="app-content-cont">
        {prps.map((item, index) => (
          <div
            className={
                activeCard === item.id
                ? `app-content-card active`
                : `app-content-card`
            }
            key={index}
            onClick={() => handleActive(item.id, item.level)}
          >
            <p style={{ width: "90%", marginTop: "8px" }}>{item.level}</p>

            <div className="app-content-record-box">
              <h2>{item.num}</h2>
              <p>{item.numText}</p>
            </div>
          </div>
        ))}
      </div>
      {HandleToggle()}
      {renderTable()}
    </>
  );
}
