import "./supportTicket.css";

// import VendorModalList from "../../components/vendorModals/vendorModal"
// import { SupportTicketInput } from "../../components/input/input";
import { useState, useEffect } from "react";
// import kimg from "../../img/ksearch.svg";
// import SupportTicketAction from "./support-modals/support-ticket-actions";
import Pagination from "../../components/footer-pagination";
import SupportDropDown from "./support-modals/Support-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { SupportActions } from "../../state/actions";
import Moment from "react-moment";

export default function SupportTicket({ setToggle, handleEscalate, setID }) {
  const [currentPage, setCurrentPage] = useState(1);
  // const [search, setSearch] = useState("");

  const {
    supportTickets,
    supportTicketsMeta,
    searchSupportTicketsMeta,
    searchSupportTicketsData,
  } = useSelector((state) => state.support);

  const dispatch = useDispatch();
  const { FetchSupportTicket } = bindActionCreators(
    SupportActions,
    dispatch
  );

  useEffect(() => {
    // if (search.trim() === "") {
      FetchSupportTicket(currentPage);
    // } else {
    //   SearchSupportTickets(search, currentPage);
    // }

    // eslint-disable-next-line
  }, [currentPage]);

  const { per_page, total } = searchSupportTicketsMeta
    ? searchSupportTicketsMeta
    : supportTicketsMeta;

  let userData =
    searchSupportTicketsData !== null
      ? searchSupportTicketsData
      : supportTickets;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="support-ticket-supportDiv">
        <div className="support-search-cont">
          {/* <div className="sdrop">
            <label></label>
            <div></div>
          </div> */}
          <button className="create-ticket" onClick={handleEscalate}>
            Create Ticket
          </button>
          {/* <div className="support-ticket-kscont">
            <SupportTicketInput
              label="Search Records"
              searchValue={SearchSupportTickets}
              search={search}
              setSearch={setSearch}
            />
            <img src={kimg} alt="" style={{margin: "20px 0px 0px -20px"}} />
          </div> */}
        </div>

        <div className="table-content table-cont">
          <div className="vendmodcont">
            
          </div>

          <table className="support-table">
            <tr>
              <th></th>
              <th>Ticket ID</th>
              <th>Customer</th>
              <th>Created By</th>
              <th>Escalated To</th>
              <th>Priority Level</th>
              <th>Date Created</th>
            </tr>
            {userData.map((item, idx) => (
              <tr className="item-tr" key={idx}>
                <td>{(currentPage - 1) * per_page + 1 + idx}</td>
                <td>{item.ticket_reference}</td>
                <td>{(item.customer && item.customer.name) || "-"}</td>
                <td>{(item.author && item.author.name) || "-"}</td>
                <td>
                  {(item.internal_user && item.internal_user.name) || "-"}
                </td>
                <td>{item.priority_level}</td>
                <td>
                  {item.created_at && (
                    <Moment format="DD/MM/YYYY H:mm">{item.created_at}</Moment>
                  )}
                </td>
                <td>
                  {<SupportDropDown setID={setID} id={item.id} setToggle={setToggle} />}
                </td>
              </tr>
            ))}
            {
              userData.length === 0 ? (
                <td colSpan={5} className="text-danger">No record found</td>
              ) : null
            }
          </table>
        </div>
        {userData.length > 0 && (
          <Pagination
            className="support-ticket-pagination-bar"
            currentPage={currentPage}
            totalCount={total}
            pageSize={per_page}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </>
  );
}
