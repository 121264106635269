import { useEffect, useState } from "react";
import "../css/comments.css";
import Closeimg from "../img/Close-Square.svg";

import axios from "../axios";
import { useSelector } from "react-redux";
import Moment from "react-moment";

export default function Comments({ setToggle, id, setID }) {
  const [close, setClose] = useState(false);

  const { currentUser } = useSelector((state) => state.auth);

  const closeDisplay = () => {
    setClose(true);
    setToggle(0);
  };

  const [ticket, setTicket] = useState(null);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const [error, setError] = useState("");
  const [successError, setSuccessError] = useState("");
  const [loading, setLoading] = useState(false);
  const [change, setChange] = useState(1);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`/tickets/${id}`);
        setTicket(res.data.data.ticket);
      } catch (err) {}
    })();

    (async () => {
      try {
        const res = await axios.get(`/tickets/${id}/comment`);
        setComments(res.data.data.comments);
      } catch (err) {}
    })();

    // eslint-disable-next-line
  }, [change]);

  const createComment = async () => {
    setLoading(true);
    if (comment.trim().length === 0) {
      setError("please type something here...");
      setLoading(false);
      return;
    }
    try {
      await axios.post(`/tickets/${id}/comment`, {
        author_id: currentUser.id,
        internal_user_id: ticket.internal_user.id,
        comment: comment,
      });
      setLoading(false);
      setSuccessError("comment added successfully");
      setComment("");
      setChange(Math.random());
    } catch (err) {
      setError("something went wrong");
      setLoading(false);
    }
  };
  return (
    <>
      {close ? null : (
        <div className="comments-modal-container">
          <div className="supports-comment">
            <div className="comments-container">
              <div className="comments-top">
                <h3>Comments</h3>
                <img onClick={closeDisplay} src={Closeimg} alt="img" />
              </div>
              <div className="comment-block-section">
                {comments.map((comment, idx) => (
                  <div key={idx} className="commentblock">
                    <div className="innerr">
                      <h5>{comment.author && comment.author.name}</h5>
                      <span>
                        <Moment format="hh:mma DD-MM-YYYY">
                          {comment && comment.created_at}
                        </Moment>
                      </span>
                    </div>
                    <p>{comment.comment}</p>
                  </div>
                ))}
              </div>

              <textarea
                placeholder="input comment..."
                style={{ padding: "10px" }}
                cols={10}
                rows={5}
                value={comment}
                onChange={(e) => {
                  setError("");
                  setSuccessError("");
                  setComment(e.target.value);
                }}
              ></textarea>
              {error && <span className="text-danger">{error}</span>}
              {successError && (
                <span className="text-success">{successError}</span>
              )}
              <div className="comment-btnhold">
                {loading ? (
                  <button className="btn-default">Loading...</button>
                ) : (
                  <button onClick={createComment}>Submit</button>
                )}
                <button onClick={closeDisplay}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
