import "./view.css";
import KYCTOP from "../../components/kyc-top/kyc-top";
import Nav from "../../components/nav";
import DoubleImage from "../image-comparison/double-image";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../axios";
import Moment from "react-moment";
import Lilmodal from "../modals/mfCreation";
import MfRed from "../modals/mfCreationRed";
import SuccessNotify from "../modals/successNotify";
import Level1RejectModal from "../kyc/level1/rejectModal";
import RejectConfirmation from "../kyc/level1/rejectConfirmation";
import { RoleCreationError } from "../vendorModals/appNotificationModal";
import ImageView from "../image-comparison/image-view"
// import { authActions } from "../../state/actions";
// import { useSelector } from "react-redux";

export default function View() {
  const [view, setView] = useState("hidden");
  const [comment, setComment] = useState("");
  const [compare, setCompare] = useState([]);
  const [compareList, setCompareList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [verifications, setVerifications] = useState([]);
  const [verification, setVerification] = useState("");
  const [modal, setModal] = useState(0);
  const [data, setData] = useState(null);
  const { id } = useParams();
  const [image, setImage] = useState("")
  const [image1, setImage1] = useState("")
  const navigate = useNavigate()

  const filterSelfieVerification = () => {
    const verification = verifications.filter(
      (v) => v.verification_type === "bvn-selfie-verification"
    );
    return verification;
  };

  const filterIDVerification = () => {
    const verification = verifications.filter(
      (v) => v.verification_type === "identity-card-verification"
    );
    return verification;
  };

  const handleClick = (value) => {
    if (filterSelfieVerification().length === 0) {
      value = 7;
    } else {
      setVerification(filterSelfieVerification()[0].id);
    }

    setView("");
    setModal(value);
  };

  const handleIDClick = (value) => {
    if (filterIDVerification().length === 0) {
      value = 7;
    } else {
      setVerification(filterIDVerification()[0].id);
    }

    setView("");
    setModal(value);
  };

  useEffect(() => {
    async function fetchuser() {
      try {
        const newId = atob(id);
        const res = await axios.get(`/kycs/${newId}?include=verifications,kyc`);
        setData(res.data.data);
        setVerifications(res.data.data.user.verifications);
      } catch (error) {
        console.log(error);
      }
    }
    fetchuser();
  }, [id]);

  // const { CheckPermission } = authActions;

  // const { currentUser, currentUserRole } = useSelector((state) => state.auth);
  // const { roles } = currentUser;

  const handleInputBox = (e, value) => {
    if (!compare.includes(e.target.value) && compareList.length < 2) {
      setCompare([...compare, e.target.value]);
      setCompareList([...compareList, { key: e.target.name, value: value }]);
    } else if (compare.includes(e.target.value)) {
      setCompare(compare.filter((c) => c !== e.target.value));
      setCompareList(compareList.filter((c) => c.key !== e.target.name));
    }
  };

  const handleComapare = () => {
    setView("");
    setModal(1);
  };

  const showDoubleImage = (image) => {
    setImage1(image)
    if (image.trim() !== "") {
      setView("")
      
      setModal(14)
    }
    
  }

  const RejectKyc = async () => {
    setLoading(true);
    try {
      await axios.patch(`/verifications/${verification}`, {
        status: "rejected",
        comment,
      });
      // setError()
      setModal(5);
      setVerifications(
        verifications.map((v) => {
          if (v.id === verification) {
            v.status = "rejected";
          }
          return v;
        })
      );
      setLoading(false);
    } catch (err) {
      // setError(err.response.data.error.message)
      setModal(6);
      setLoading(false);
    }
  };

  const ApproveKyc = async () => {
    setLoading(true);
    try {
      await axios.patch(`/verifications/${verification}`, {
        status: "approved",
      });
      // setError()
      
      setModal(3);
      setVerifications(
        verifications.map((v) => {
          if (v.id === verification) {
            v.status = "approved";
          }
          return v;
        })
      );
      setLoading(false);
    } catch (err) {
      // setError(err.response.data.error.message)
      setModal(6);
      setLoading(false);
    }
  };

  const showModal = () => {
    if (modal === 0) {
      return null;
    }
    if (modal === 1) {
      return <DoubleImage setToggle={setModal} images={compareList} />;
    } else if (modal === 2) {
      return (
        <MfRed
          setView={setView}
          setModal={setModal}
          handleSubmit={RejectKyc}
          setComment={setComment}
          loading={loading}
        />
      );
    } else if (modal === 3) {
      return <SuccessNotify setView={setView} setModal={setModal} close="close" />;
    } else if (modal === 4) {
      return (
        <Level1RejectModal
          setView={setView}
          setModal={setModal}
          setComment={setComment}
        />
      );
    } else if (modal === 5) {
      return (
        <RejectConfirmation
          setView={setView}
          setModal={setModal}
          close="close"
        />
      );
    } else if (modal === 6) {
      return (
        <RoleCreationError
          notify={`OOPS!!! something went wrong, Please try again`}
          setToggle={setModal}
        />
      );
    } else if (modal === 7) {
      return (
        <RoleCreationError
          notify={`OOPS!!! No document to approve or reject`}
          setToggle={setModal}
          close="close"
        />
      );
    } else if (modal === 10) {
      return (
        <Lilmodal
          setView={setView}
          loading={loading}
          setModal={setModal}
          handleSubmit={ApproveKyc}
        />
      ); 
    } 
    else if (modal === 11) {
      return (
        <ImageView
          setToggle={setModal}
          image={image}
        />
      ); 
    } 
    else if (modal === 12) {
      return (
        <ImageView
          setToggle={setModal}
          image={image}
        />
      ); 
    } 
    else if (modal === 13) {
      return (
        <ImageView
          setToggle={setModal}
          image={image}
        />
      ); 
    }
    else if (modal === 14) {
      return (
        <ImageView
          setToggle={setModal}
          image={image1}
        />
      ); 
    }
  };
  //eslint-disable-next-line
const handleImage = (value, modal)=>{
  setImage(value)
  setModal(modal)
}
  return (
    <>
      <div className="containDash">
        <Nav Active={2} />
        <div className="control right-content">
          <div className="view-kyc-top">
            <KYCTOP url="KYC/Level 2" suburl="/Customer's Doc" />
          </div>
          <div class="vcont">
            <div className="textleft">
              <div className="divh1p">
                <div className="txthouse">
                  <div className="t1">
                    <h2>Full Name</h2>
                    <p>{data ? data.user.name : ""}</p>
                    <p />
                  </div>

                  <div className="t1">
                    <h2>Email Address</h2>
                    <p>{data ? data.user.email : ""}</p>
                  </div>

                  <div className="t1">
                    <h2>Phone Number</h2>
                    <p>{data ? data.telephone : ""}</p>
                  </div>

                  <div className="t1">
                    <h2>BVN</h2>
                    <p>
                      {data
                        ? (data.user.bvn && data.user.bvn.bvn_number) || "-"
                        : ""}
                    </p>
                  </div>

                  <div className="t1">
                    <h2>Residential Address</h2>
                    <p>
                      {data
                        ? (data.user.bvn &&
                            data.user.bvn.residential_address) ||
                          "-"
                        : ""}
                    </p>
                  </div>

                  <div className="t1">
                    <h2>Date of Birth</h2>
                    <p>
                      {data
                        ? (data.user.bvn && (
                            <Moment format="MMMM Do YYYY">
                              {data.user.bvn.date_of_birth}
                            </Moment>
                          )) ||
                          "-"
                        : ""}
                    </p>
                  </div>

                  <div className="t1">
                    <h2>ID Number</h2>
                    <p>
                      {filterIDVerification().length !== 0 &&
                        filterIDVerification()[0].identity_card_number}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="picright">
              <div className="up">
                <div className="upbox">
                  <div className="chk">
                    <div>
                      <p>BVN picture</p>
                    </div>
                    <input
                      className="bvn-input-box"
                      type="radio"
                      name="BVN Picture"
                      value="bvn"
                      checked={compare.includes("bvn")}
                      onClick={(e) => handleInputBox(e, data.media.bvn_photo)}
                    />
                  </div>
                  <div onClick={() => showDoubleImage(data ? data.media.bvn_photo : "")}>
                  <img
                    height={150}
                    width={150}
                    src={data ? data.media.bvn_photo : ""}
                    alt=""
                  />
                  </div>
                </div>
                <div className="upbox long">
                  <div className="chk">
                    <p className="text-danger">Selfie Picture</p>
                    <input
                      name="Selfie Picture"
                      className="bvn-input-box"
                      type="radio"
                      value="selfie"
                      checked={compare.includes("selfie")}
                      onClick={(e) =>
                        handleInputBox(e, data.media.selfie_photos.bvn)
                      }
                    />
                  </div>
                  <img
                    height={150}
                    width={150}
                    src={data ? data.media.selfie_photos.bvn : ""}
                    alt={data && data.user.name}
                    onClick={() => showDoubleImage(data ? data.media.selfie_photos.bvn : "")}

                  />
                  <div className="chk2">
                    { 
                    // roles &&
                    // roles.length > 0 &&
                    // CheckPermission(currentUserRole, "can approve kyc") &&

                    ((filterSelfieVerification().length !== 0 &&
                      filterSelfieVerification()[0].status === "approved") ? (
                      <button className="kyc-view-approved">Approved</button>
                    ) : (
                      <div className="kyc-approve-tick"><Approve handleClick={() => handleClick(10)} /></div>
                    ))}
                    { 
                    // roles &&
                    // roles.length > 0 &&
                    // CheckPermission(currentUserRole, "can reject kyc") &&

                    ((filterSelfieVerification().length !== 0 &&
                      filterSelfieVerification()[0].status === "rejected") ? (
                      <button className="kyc-view-rejected">Rejected</button>
                    ) : (
                      <button
                        className="kyc-view-cancel-btn"
                        onClick={() => handleClick(4)}
                      >
                        X
                      </button>
                    ))}
                  </div>
                </div>
              </div>

              <div className="down">
                <div className="downbox">
                  <div className="chk">
                    <p>ID Card</p>
                    <input
                      className="bvn-input-box"
                      type="radio"
                      name="ID Card"
                      value="id"
                      checked={compare.includes("id")}
                      onClick={(e) =>
                        handleInputBox(e, data.media.identity_card)
                      }
                    />
                  </div>
                  <img
                    height={120}
                    width={200}
                    src={data ? data.media.identity_card : ""}
                    alt={data && data.user.name}       
                    onClick={() => showDoubleImage(data ? data.media.identity_card : "")}  
                  />
                 
                  <div className="chk3">
                    { 
                    // roles &&
                    // roles.length > 0 &&
                    // CheckPermission(currentUserRole, "can approve kyc") &&

                    ((filterIDVerification().length !== 0 &&
                      filterIDVerification()[0].status === "approved") ? (
                      <button className="kyc-view-approved">Approved</button>
                    ) : (
                      <div className="kyc-approve-tick" style={{pointer: "cursor"}}><Approve handleClick={() => handleIDClick(10)} /></div>
                    ))}

                    { 
                    // roles &&
                    // roles.length > 0 &&
                    // CheckPermission(currentUserRole, "can reject kyc") &&

                    ((filterIDVerification().length !== 0 &&
                      filterIDVerification()[0].status === "rejected") ? (
                      <button className="kyc-view-rejected">Rejected</button>
                    ) : (
                      <button
                        className="kyc-view-cancel-btn"
                        onClick={() => handleIDClick(4)}
                      >
                        X
                      </button>
                    ))}
                  </div>
                </div>
              </div>
              <div className="upbox">
                <button onClick={() => navigate("/kyc")} className="kyc-view-submit-btn">Submit</button>
              </div>
            </div>
            <div className="compare-img">
              <button
                onClick={handleComapare}
                className={`kyc-view-submit-btn ${
                  compareList.length < 2 ? "compare-disable" : ""
                }`}
                disabled={compareList.length < 2}
              >
                Compare image
              </button>
            </div>
          </div>
        </div>
        {modal > 0 ? (
          <div className={`content1-modal ${view}`}>{showModal()}</div>
        ) : null}
      </div>
    </>
  );
}

function Approve({ handleClick }) {
  return (
    <svg
      onClick={handleClick}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="10" fill="#089E17" />
      <path
        d="M10.1099 21.499L23.1205 8.48838C23.9425 7.66641 25.2752 7.66641 26.0971 8.48838V8.48838C26.9191 9.31035 26.9191 10.643 26.0971 11.465L13.0865 24.4756L10.1099 21.499Z"
        fill="white"
      />
      <rect
        x="7.55322"
        y="13.8457"
        width="10.0896"
        height="3.6105"
        rx="1.80525"
        transform="rotate(45 7.55322 13.8457)"
        fill="white"
      />
    </svg>
  );
}
