import React, { useState, useEffect } from "react";

import "../../css/modal/pin-reset-view.css";
import KYCTOP from "../kyc-top/kyc-top";
import axios from "../../axios";
import Nav from "../nav";
import Lilmodal from "../modals/mfCreation";
import MfRed from "../modals/mfCreationRed";
import SuccessNotify from "../modals/successNotify";
import Level1RejectModal from "../kyc/level1/rejectModal";
import RejectConfirmation from "../kyc/level1/rejectConfirmation";
import { useParams } from "react-router-dom";
import { RoleCreationError } from "../vendorModals/appNotificationModal";

import ImageView from "../../components/image-comparison/image-view";
// import { authActions } from "../../state/actions";
// import { useSelector } from "react-redux";

const PinResetView = () => {
  const [view, setView] = useState("hidden");
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(0);
  const [data, setData] = useState(null);
  const [selfie, setSelfie] = useState(null);

  const [image1, setImage1] = useState("");

  const { id } = useParams();

  const handleClick = (value) => {
    if (data.media.pin === null) {
      value = 7;
    }

    setView("");
    setModal(value);
  };

  const showDoubleImage = (image) => {
    setImage1(image);
    if (image.trim() !== "") {
      setView("");

      setModal(14);
    }
  };

  // const { CheckPermission } = authActions;

  // const { currentUser, currentUserRole } = useSelector((state) => state.auth);
  // const { roles } = currentUser;

  useEffect(() => {
    async function fetchuser() {
      try {
        const newId = atob(id);
        const res = await axios.get(
          `/pin/password/${newId}?type=reset-pin-selfie-verification&include=bvn`
        );

        setData(res.data.data);

        const res1 = await axios.get(`/kycs/${newId}?include=verifications`);
        setSelfie(res1.data.data);
        // setVerifications(res.data.data.user.verifications);
      } catch (error) {
        // console.log(error);
      }
    }
    fetchuser();
  }, [id]);

  const ApproveDocs = async () => {
    const newId = data.verification.id;
    setLoading(true);
    try {
      await axios.patch(`/verifications/${newId}`, {
        status: "approved",
      });
      // setError()
      setModal(3);
      setLoading(false);
    } catch (err) {
      // setError(err.response.data.error.message)
      setModal(6);
      setLoading(false);
    }
  };

  const RejectDocs = async () => {
    const newId = data.verification.id;

    setLoading(true);
    try {
      await axios.patch(`/verifications/${newId}`, {
        status: "rejected",
        comment,
      });
      // setError()
      setModal(5);
      setLoading(false);
    } catch (err) {
      // setError(err.response.data.error.message)
      setModal(6);
      setLoading(false);
    }
  };

  const showModal = () => {
    if (modal === 1) {
      return (
        <Lilmodal
          setView={setView}
          loading={loading}
          setModal={setModal}
          handleSubmit={ApproveDocs}
        />
      );
    } else if (modal === 2) {
      return (
        <MfRed
          setView={setView}
          setModal={setModal}
          handleSubmit={RejectDocs}
          setComment={setComment}
          loading={loading}
        />
      );
    } else if (modal === 3) {
      return <SuccessNotify setView={setView} setModal={setModal} />;
    } else if (modal === 4) {
      return (
        <Level1RejectModal
          setView={setView}
          setModal={setModal}
          setComment={setComment}
        />
      );
    } else if (modal === 5) {
      return <RejectConfirmation setView={setView} setModal={setModal} />;
    } else if (modal === 6) {
      return (
        <RoleCreationError
          notify={`OOPS!!! something went wrong, Please try again`}
          setToggle={setModal}
        />
      );
    } else if (modal === 7) {
      return (
        <RoleCreationError
          notify={`OOPS!!! No document to approve or reject`}
          setToggle={setModal}
        />
      );
    } else if (modal === 14) {
      return <ImageView setToggle={setModal} image={image1} />;
    }
  };
  return (
    <>
      <div className="containDash">
        <div>
          <Nav Active={2} />
        </div>

        <div className=" right-content pin-view-kyctop">
          <KYCTOP url="KYC/Pin Reset" suburl="/Customer's Doc" />
          <div className="pin-view-content">
            <div className="pin-view-content1">
              <div className="pin-content1-container">
                <div className="pin-content1-body1">
                  <h2>Full Name</h2>
                  <p>
                    {(data &&
                      data.verification &&
                      data.verification.owner &&
                      data.verification.owner.name) ||
                      "-"}
                  </p>
                </div>
                <div className="pin-content1-body1">
                  <h2>Email Address</h2>
                  <p>
                    {(data &&
                      data.verification &&
                      data.verification.owner &&
                      data.verification.owner.email) ||
                      "-"}
                  </p>
                </div>
                <div className="pin-content1-body1">
                  <h2>Phone Number</h2>
                  <p>
                    {(data &&
                      data.verification &&
                      data.verification.owner &&
                      data.verification.owner.telephone) ||
                      "-"}
                  </p>
                </div>
                <div className="pin-content1-body1">
                  <h2>BVN</h2>
                  <p>
                    {(data && data.verification && data.verification.bvn) ||
                      "-"}
                  </p>
                </div>
                <div className="pin-content1-body1">
                  <h2>Residential Address</h2>
                  <p>
                    {(selfie &&
                      selfie.user &&
                      selfie.user.bvn &&
                      selfie.user.bvn.residential_address) ||
                      "-"}
                  </p>
                </div>
              </div>
            </div>
            <div className="pin-view-content2">
              <div className="pin-content2-container">
                <div className="pin-content2-body2">
                  <h2>Profile Picture</h2>
                  <div className="pin-content2-image">
                    <img
                      width="174"
                      height="174"
                      alt="bvn"
                      src={
                        selfie && selfie.media && selfie.media.selfie_photos
                          ? selfie.media.selfie_photos.bvn
                          : ""
                      }
                      onClick={() =>
                        showDoubleImage(
                          selfie && selfie.media && selfie.media.selfie_photos
                            ? selfie.media.selfie_photos.bvn
                            : ""
                        )
                      }
                    />
                  </div>
                </div>
                <div className="pin-content2-body2">
                  <h2>Selfie Picture</h2>
                  <div className="pin-content2-image">
                    <img
                      width="174"
                      height="174"
                      alt="bvn"
                      src={data ? data.media.pin : ""}
                      onClick={() =>
                        showDoubleImage(data ? data.media.pin : "")
                      }
                    />
                  </div>
                </div>
                <div className="pin-content2-footer">
                  {/* {roles &&
                    roles.length > 0 &&
                    CheckPermission(currentUserRole, "can approve kyc") && ( */}
                      <button
                        className="pin-content2-approve"
                        onClick={() => handleClick(1)}
                      >
                        Approve
                      </button>
                    {/* )} */}
                  {/* {roles &&
                    roles.length > 0 &&
                    CheckPermission(currentUserRole, "can reject kyc") && ( */}
                      <button
                        className="pin-content2-reject"
                        onClick={() => handleClick(4)}
                      >
                        Reject
                      </button>
                    {/* )} */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {modal ? (
          <div className={`pin-content1-modal ${view}`}>{showModal()}</div>
        ) : null}
      </div>
    </>
  );
};

export default PinResetView;
