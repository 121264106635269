import { useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
import {useDispatch} from 'react-redux';
import { bindActionCreators } from "redux";

import "./dashboard.css";
import TransactionInsight from "../../components/dashboard/transaction-insight";
import CashProvider from "../../components/dashboard/cash-provider";
import IssueComp from "../../components/issues/issues-comp";
import LineChart from "../../components/issues/line-chart";
import KYCDistribution from "../../components/kyc-distr";
// import { Link } from "react-router-dom";
import KYCTOP from "../../components/kyc-top/kyc-top";
import Nav from "../../components/nav";
import { authActions, dashboardActions } from "../../state/actions";

import "charts.css";
export default function Dashboard() {
  const name = authActions.DecodeToken().name;

  // const { data, loading } = useSelector((state) => state.dashboard);

  const { data, loading } = {
    data: {
      user_count: 19873,
      daily_user_count: 8045,
      weekly_user_count: 10927,
      monthly_count: 14052,
      active_user_count: 15683,
      address_verification: {
        total_count: 8447,
        approved_count: 8349,
        pending_count: 98
      },
      bills: {
        airtime_bill_count:43,
        other_bill_count:61,
        total_count:61,
        tv_bill_count: 1
      },
      cash_request:{
        pending_cash_request_count: 0,
        processed_cash_request_count: 0,
        total_count: 0
      },
      complaints:{
        pending_count:18,
        resoled_count:37,
        total_count:55
      },
      current_active_cash_provider: 1,
      inactive_user_count: 3190,
      kyc: {
        agent_level :4,
        level_one :2,
        level_two :1,
        level_zero :2,
        pending_kyc_count :1,
        total_count :6
      },
      transaction:{
        failed_count: 18,
        pending_count: 214,
        success_count: 7122,
        total_count: 7354,
        total_volume: 12457980.00
    },
  },
    loading: false
  };

  const dispatch = useDispatch();

  const { LoadDashboard, LoadDashboardAnalytics } = bindActionCreators(
    dashboardActions,
    dispatch
  );


  useEffect(() => {

    LoadDashboard();
    LoadDashboardAnalytics();

    //eslint-disable-next-line
  }, []);

  // static dummy data..
  const total_transaction_count = Math.round(data.transaction.total_volume/1000)
  const total_active =  Math.round(2/3 *  total_transaction_count)
  const total_customer = Math.round(total_active + (1/5 * total_active))


  const complaint = [
    {
      total: {
        title: "Complaints",
        value: (data && data.complaints && data.complaints.total_count) || 0,
      },
      done: {
        title: "Resolved",
        value: (data && data.complaints && data.complaints.resoled_count) || 0,
      },
      undone: {
        title: "Pending",
        value: (data && data.complaints && data.complaints.pending_count) || 0,
      },
    },
    {
      total: {
        title: "Total User Count",
        // value: data !== null ? data.user_count : 0,
        value: total_customer
      },
      done: {
        title: "Active users",
        // value: data !== null ? data.active_user_count : 0,
        value: total_active
      },
      undone: {
        title: "Inactive users",
        // value: data !== null ? data.inactive_user_count : 0,
        value: total_customer - total_active
      },
    },
    {
      total: {
        title: "Address Verification",
        // value: (data && data.address_verification && data.address_verification.total_count) || 0,
        value: 744 //dummy data
      },
      done: {
        title: "Approved",
        // value: (data && data.address_verification && data.address_verification.approved_count) || 0,
        value: 798 //dummy data
      },
      undone: {
        title: "Pending",
        // value: (data && data.address_verification && data.address_verification.pending_count) || 0,
        value: 48 //dummy data
      },
    },
    {
      total: {
        title: "KYC vendors",
        value: 0,
      },
      done: {
        title: "Active",
        value: 0,
      },
      undone: {
        title: "Inactive",
        value: 0,
      },
    },
    {
      total: {
        title: "Bill Vendors",
        value: 1,
      },
      done: {
        title: "Active",
        value: 1,
      },
      undone: {
        title: "Inactive",
        value: 0,
      },
    },
  ];

  return (
    <>
      {loading && <div className="dashboard-user-preloader"></div>}
      <div className="dashboard-container">
        <div className="dashboard-nav">
          <Nav Active={1} />
        </div>
        <div className="right-content dashboard-content-wrapper">
          <KYCTOP url={"Welcome, "} suburl={name.split(" ")[1]} />
          <div
            className="dashboard-body-wrapper"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="dashboard-content">
              <div style={{ width: "48%", marginRight: "0px" }}>
                <LineChart data={data} />
              </div>
              <div style={{ width: "50%" }}>
                <TransactionInsight />
              </div>
            </div>
            <div className="kyc-dist-cash-provider">
              <div className="kyc-issue">
                <div>
                  <KYCDistribution />
                </div>

                <IssueComp data={complaint[4]} />
              </div>
              <div style={{ width: "53%" }}>
                <div style={{ marginBottom: "20px" }}>
                  {/* <Link to="/vendor" className="rm-link-dec"> */}
                  <CashProvider />
                  {/* </Link> */}
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "30px",
                  }}
                >
                  <IssueComp data={complaint[1]} />
                  <IssueComp data={complaint[0]} />
                  <IssueComp data={complaint[2]} />
                  <IssueComp data={complaint[3]} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
