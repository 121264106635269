import axios from "../../axios";
import * as types from "../types";

const parseError = (err) => {
  if (err.response.status === 500 || err.response.status === 404) {
    return err.response.data.error.message;
  } else {
    return err.response.data.error.message.join("\n");
  }
};

export const FetchInAppNotification = (page) => async (dispatch) => {
  page = page || 1;
  setLoading();
  try {
    const res = await axios.get(
      `/custom-notifications?type=in%20app%20notification&page%5Bnumber%5D=${page}`
    );
    dispatch({
      type: types.FETCH_IN_APP_NOTIFICATION,
      payload: res.data.data.custom_notification.data,
      meta: res.data.data.custom_notification.meta,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: types.FETCH_NOTIFICATION_ERROR,
      payload: { top: error.response.data.error.message },
    });
  }
};
export const FetchEmailNotification = (page) => async (dispatch) => {
  page = page || 1;
  setLoading();
  try {
    const res = await axios.get(
      `/custom-notifications?type=email%20notification&page%5Bnumber%5D=${page}`
    );
    dispatch({
      type: types.FETCH_EMAIL_NOTIFICATION,
      payload: res.data.data.custom_notification.data,
      meta: res.data.data.custom_notification.meta,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: types.FETCH_NOTIFICATION_ERROR,
      payload: { top: error.response.data.error.message },
    });
  }
};
export const FetchArticlesNotification = (page) => async (dispatch) => {
  page = page || 1;
  setLoading();
  try {
    const res = await axios.get(`/articles?page%5Bnumber%5D=${page}`);
    dispatch({
      type: types.FETCH_ARTICLES_NOTIFICATION,
      payload: res.data.data.articles.data,
      meta: res.data.data.articles.meta,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: types.FETCH_NOTIFICATION_ERROR,
      payload: { top: error.response.data.error.message },
    });
  }
};
export const FetchFAQsNotification = (page) => async (dispatch) => {
  page = page || 1;
  setLoading();
  try {
    const res = await axios.get(`/faq?page%5Bnumber%5D=${page}`);
    dispatch({
      type: types.FETCH_FAQS_NOTIFICATION,
      payload: res.data.data.faqs.data,
      meta: res.data.data.faqs.meta,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: types.FETCH_NOTIFICATION_ERROR,
      payload: { top: error.response.data.error.message },
    });
  }
};

export const searchInAppNotification = (search) => (dispatch) => {
  search = search.trim() === "" ? null : search.trim();
  dispatch({
    type: types.SEARCH_IN_APP_NOTIFICATION,
    payload: search,
  });
};
export const searchEmailNotification = (search) => (dispatch) => {
  search = search.trim() === "" ? null : search.trim();
  dispatch({
    type: types.SEARCH_EMAIL_NOTIFICATION,
    payload: search,
  });
};
export const searchArticlesNotification = (search) => (dispatch) => {
  search = search.trim() === "" ? null : search.trim();
  dispatch({
    type: types.SEARCH_ARTICLES_NOTIFICATION,
    payload: search,
  });
};
export const searchFaqsNotification = (search) => (dispatch) => {
  search = search.trim() === "" ? null : search.trim();
  dispatch({
    type: types.SEARCH_FAQS_NOTIFICATION,
    payload: search,
  });
};

export const createInAppNotification = (data) => async (dispatch) => {
  setLoading();
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  try {
    const res = await axios.post(`/custom-notifications`, data, config);
    dispatch({
      type: types.CREATE_IN_APP_NOTIFICATION,
      payload: res.data.data.notification,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: types.CREATE_IN_APP_NOTIFICATION_ERROR,
      payload: { top: parseError(err) },
    });
  }
};

export const updateInAppNotification = (data, id) => async (dispatch) => {
  setLoading();
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  try {
    const res = await axios.post(`/custom-notifications/${id}`, data, config);
    dispatch({
      type: types.UPDATE_IN_APP_NOTIFICATION,
      payload: res.data.data.notification,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: types.CREATE_IN_APP_NOTIFICATION_ERROR,
      payload: { top: parseError(err) },
    });
  }
};

export const deleteInAppNotification = (value, id) => async (dispatch) => {
  setLoading();

  try {
     await axios.delete(`/custom-notifications/${id}`);
    dispatch({
      type: types.DELETE_IN_APP_NOTIFICATION,
      payload: id,
      toggle: value,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: types.DELETE_IN_APP_NOTIFICATION_ERROR,
      payload: { top: parseError(err) },
      toggle: 20
    });
  }
};

export const createEmailNotification = (data) => async (dispatch) => {
  setLoading();
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  try {
    const res = await axios.post(`/custom-notifications`, data, config);
    dispatch({
      type: types.CREATE_EMAIL_NOTIFICATION,
      payload: res.data.data.notification,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: types.CREATE_EMAIL_NOTIFICATION_ERROR,
      payload: { top: parseError(err) },
    });
  }
};

export const updateEmailNotification = (data, id) => async (dispatch) => {
  setLoading();
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  try {
    const res = await axios.post(`/custom-notifications/${id}`, data, config);
    dispatch({
      type: types.UPDATE_EMAIL_NOTIFICATION,
      payload: res.data.data.notification,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: types.CREATE_EMAIL_NOTIFICATION_ERROR,
      payload: { top: parseError(err) },
    });
  }
};

export const deleteEmailNotification = (value, id) => async (dispatch) => {
  setLoading();

  try {
    await axios.delete(`/custom-notifications/${id}`);
    dispatch({
      type: types.DELETE_EMAIL_NOTIFICATION,
      payload: id,
      toggle: value,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: types.DELETE_EMAIL_NOTIFICATION_ERROR,
      payload: { top: parseError(err) },
      toggle: 20
    });
  }
};

export const deleteArticleNotification = (value, id) => async (dispatch) => {
  setLoading();

  try {
    await axios.delete(`/articles/${id}`);
    dispatch({
      type: types.DELETE_ARTICLES_NOTIFICATION,
      payload: id,
      toggle: value,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: types.DELETE_ARTICLES_NOTIFICATION_ERROR,
      payload: { top: parseError(err) },
      toggle: 20
    });
  }
};

export const deleteFaqNotification = (value, id) => async (dispatch) => {
  setLoading();

  try {
    await axios.delete(`/faq/${id}`);
    dispatch({
      type: types.DELETE_FAQ_NOTIFICATION,
      payload: id,
      toggle: value,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: types.DELETE_FAQ_NOTIFICATION_ERROR,
      payload: { top: parseError(err) },
      toggle: 20
    });
  }
};

export const setActiveCard = (value) => async (dispatch) => {
  dispatch({
    type: types.SET_APP_CONTENT_CARD,
    payload: value,
  });
};
export const setCreateInAppToggle = (value) => async (dispatch) => {
  dispatch({
    type: types.SET_IN_APP_CONTENT_TOGGLE,
    payload: value,
  });
};
export const setCreateEmailToggle = (value) => async (dispatch) => {
  dispatch({
    type: types.SET_EMAIL_CONTENT_TOGGLE,
    payload: value,
  });
};

export const setToggle = (value, id) => async (dispatch) => {
  id = id || 0;
  dispatch({
    type: types.SET_APP_CONTENT_TOGGLE,
    payload: value,
    id: id,
  });
};

export const setLoading = () => (dispatch) => {
  dispatch({
    type: types.SET_LOADING,
  });
};

export const setActiveID = (id) => (dispatch) => {
  dispatch({
    type: types.SET_ACTIV_ID,
    payload: id
  })
}
