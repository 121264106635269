import React from "react";
import "./css/vendor-profile-deactivation.css";
import CloseBtn from "../../img/Close-Square.svg";
import ProfileX from "../../img/profilex.svg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { SupportActions, userControlActions } from "../../state/actions";

import axios from "../../axios"
// import VendorSuccessNotify from "./vendorSuccessNotification";

function VendorProfileDeactivation({ setToggle, value }) {
  const [display, setDisplay] = useState("");
  const [reason, setReason] = useState("")
  const [errorX, setErrorX] = useState("")
  
  const closeDisplay = () => {
    setDisplay("close");
    setToggle(0)
  };

  const dispatch = useDispatch();

  const { DeactivateUser } = bindActionCreators(userControlActions, dispatch);

  const { loading, userID, error } = useSelector(
    (state) => state.userControl
  );

  const submitReason = () => {
    if (reason.trim() === "") {
        setErrorX("Please specify the reason for deactivation")
    } else {
      setErrorX("")
      DeactivateUser(userID, reason, value)
    }
  }


  return (
    <>
      <div className={`display-details ${display}`}>
        <div class={`profile-deactivate `}>
          <div onClick={closeDisplay}>
            <strong class="profile-deactivate-bold-text">Cancel</strong>
          </div>
          <div class="profile-deactivate-flexedcont">
            <img
              src={ProfileX}
              loading="lazy"
              width="63"
              height="63"
              alt=""
              class="profile-deactivate-image"
            />
            <div class="profile-deactivate-text-block-2">
              Please Input reason for deactivation
            </div>
            <textarea
              className="profile-deactivate-text"
              name=""
              id=""
              cols="50"
              rows="10"
              placeholder="  please input reason for deactivation"
              required
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            ></textarea>
            {errorX && <span className="text-danger">{errorX}</span>}
            {error.active && <span className="text-danger">{errorX.active}</span>}
            <button
              className="profile-deactivate-button profile-deactivate-w-button"
              disabled={loading}
              onClick={submitReason}
            >
              <strong>Deactivate</strong>
            </button>
          </div>
          <img
            src={CloseBtn}
            loading="lazy"
            width="24"
            height="24"
            alt=""
            class="profile-deactivate-image-2"
            onClick={closeDisplay}
          />
        </div>
      </div>

    </>
  );
}

export default VendorProfileDeactivation;

export const UserSearchDeactivation = ({ setToggle, value, id }) => {
  const [display, setDisplay] = useState("");
  const [reason, setReason] = useState("")
  const [errorX, setErrorX] = useState("")
  
  const closeDisplay = () => {
    setDisplay("close");
    setToggle(0)
  };

  const dispatch = useDispatch();

  const { setStatus } = bindActionCreators(SupportActions, dispatch);

  const { loading, error } = useSelector(
    (state) => state.support
  );

  const submitReason = () => {
    if (reason.trim() === "") {
        setErrorX("Please specify the reason for deactivation")
    } else {
      setErrorX("")
      DeactivateUser()
    }
  }

  const DeactivateUser = async () => {
    try {
      await axios.patch(`/users/${id}/status`, {
        status: "inactive",
        comment: reason
      });
      setStatus("inactive", reason)
      setToggle(value)
    } catch (err) {
      setToggle(13)
    }
  }


  return (
    <>
      <div className={`display-details ${display}`}>
        <div class={`profile-deactivate `}>
          <div onClick={closeDisplay}>
            <strong class="profile-deactivate-bold-text">Cancel</strong>
          </div>
          <div class="profile-deactivate-flexedcont">
            <img
              src={ProfileX}
              loading="lazy"
              width="63"
              height="63"
              alt=""
              class="profile-deactivate-image"
            />
            <div class="profile-deactivate-text-block-2">
              Please Input reason for deactivation
            </div>
            <textarea
              className="profile-deactivate-text"
              name=""
              id=""
              cols="50"
              rows="10"
              placeholder="  please input reason for deactivation"
              required
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            ></textarea>
            {errorX && <span className="text-danger">{errorX}</span>}
            {error.active && <span className="text-danger">{errorX.active}</span>}
            <button
              className="profile-deactivate-button profile-deactivate-w-button"
              disabled={loading}
              onClick={submitReason}
            >
              <strong>Deactivate</strong>
            </button>
          </div>
          <img
            src={CloseBtn}
            loading="lazy"
            width="24"
            height="24"
            alt=""
            class="profile-deactivate-image-2"
            onClick={closeDisplay}
          />
        </div>
      </div>

    </>
  );
}
