
import "../../../css/support/profile-view-reactivation.css"
import CloseBtn from "../../../img/Close-Square.svg"
import SNotify from "../../../img/successNotify.svg"
import Rejected from "../../../img/rejected.svg"
import { useState } from "react"

export default function ProfileViewReactivation({ notify, setToggle }) {
    const [display1, setDisplay] = useState("")
    const closeDisplay1 = () => {
        setDisplay("deactivate-close")
        setToggle(0)
    }
    return (
        <>
            <div style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }} className={`profile-view-display-details ${display1}`}>
                <div className={`profile-view-notification`}>
                    <div className="profile-view-notification-flexedcont"><img src={SNotify} loading="lazy" width="63" height="63" alt="" className="profile-view-notification-image" />
                        <div className="profile-view-notification-text-block-2">{notify}</div>
                        <a href="#/" className="profile-view-notification-button profile-view-notification-w-button" onClick={closeDisplay1}><strong>Close</strong></a>
                    </div>
                    <img onClick={closeDisplay1} src={CloseBtn} loading="lazy" width="24" height="24" alt="" className="profile-view-notification-image-2" />
                </div>
            </div>
        </>
    )
}

export const SupportUserReactivateError = ({ setToggle }) => {
    const [display1, setDisplay] = useState("")
    const closeDisplay1 = () => {
        setDisplay("deactivate-close")
        setToggle(0)
    }
    return (
        <>
            <div style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }} className={`profile-view-display-details ${display1}`}>
                <div className={`profile-view-notification`}>
                    <div className="profile-view-notification-flexedcont"><img src={Rejected} loading="lazy" width="63" height="63" alt="" className="profile-view-notification-image" />
                        <div className="profile-view-notification-text-block-2">OOPS!!! Something went wrong</div>
                        <a href="#/" className="profile-view-notification-button profile-view-notification-w-button" onClick={closeDisplay1}><strong>Close</strong></a>
                    </div>
                    <img onClick={closeDisplay1} src={CloseBtn} loading="lazy" width="24" height="24" alt="" className="profile-view-notification-image-2" />
                </div>
            </div>
        </>
    )
}