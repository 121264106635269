import "../../../components/vendorModals/css/vendor-success-notification.css";
import CloseBtn from "../../../img/Close-Square.svg";
import Skite from "../../../img/skite.svg";
import Skitesuccess from "../../../img/skitesuccess.svg";
// import { useState } from "react";

export default function UserControlOnboarding({ notify, setToggle }) {
//   const [display1, setDisplay] = useState("");
  const closeDisplay1 = () => {
    // setDisplay("deactivate-close");
    setToggle(0);
  };
  return (
    <>
      <div
        style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
        className={`support-display-details`}
      >
        <div className={`success-notification`}>
          <div className="success-notification-flexedcont">
            <img
              src={Skite}
              loading="lazy"
              width="63"
              height="63"
              alt=""
              className="success-notification-image"
            />
            <img
              src={Skitesuccess}
              loading="lazy"
              width="20"
              height="20"
              alt=""
              className="success-notification-image2"
            />
            <div className="success-notification-text-block-2">{notify}</div>
            <a
              href="#/"
              className="success-notification-button success-notification-w-button"
              onClick={closeDisplay1}
            >
              <strong>Close</strong>
            </a>
          </div>
          <img
            onClick={closeDisplay1}
            src={CloseBtn}
            loading="lazy"
            width="24"
            height="24"
            alt=""
            className="success-notification-image-2"
          />
        </div>
      </div>
    </>
  );
}
