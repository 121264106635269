export default function PeopleBtndark(){
    return (
<svg width="35" height="40" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" d="M40.1972 17.3048C40.0211 17.2834 39.8444 17.2834 39.6682 17.3048C36.4057 17.1785 33.8213 14.4986 33.8213 11.2059C33.8213 7.83719 36.5548 5.08301 39.9442 5.08301C43.3124 5.08301 46.0663 7.81568 46.0671 11.2044C46.0451 14.4993 43.4587 17.1785 40.1972 17.3048Z" fill="#162082" stroke="#162082"/>
<path opacity="0.4" d="M47.3661 33.2718L47.3659 33.272C45.0874 34.7977 41.9199 35.4491 38.8859 35.1886C39.6088 33.4114 39.9876 31.4764 40.0086 29.4531L40.0087 29.4531V29.4479C40.0087 27.3363 39.5938 25.3122 38.7929 23.4957C41.8799 23.2186 45.0462 23.8702 47.3447 25.3957L47.3463 25.3967C49.0522 26.5197 49.8372 27.9539 49.8397 29.3256C49.8421 30.6978 49.0618 32.1377 47.3661 33.2718Z" fill="#162082" stroke="#162082"/>
<path opacity="0.4" d="M15.2631 17.3048C15.0869 17.2834 14.9103 17.2834 14.7341 17.3048C11.4726 17.1785 8.88621 14.4993 8.86426 11.2043C8.86513 7.81566 11.619 5.08301 14.9872 5.08301C18.3766 5.08301 21.1101 7.83719 21.1101 11.2059C21.1101 14.4986 18.5257 17.1785 15.2631 17.3048Z" fill="#162082" stroke="#162082"/>
<path opacity="0.4" d="M7.56463 25.396L7.56464 25.396L7.56703 25.3944C9.73074 23.9384 12.7557 23.2718 15.706 23.4667C14.9249 25.2954 14.5108 27.318 14.5108 29.4483C14.5108 31.4946 14.9058 33.4482 15.6291 35.241C12.7097 35.4164 9.75563 34.7343 7.56366 33.2937C5.85711 32.1593 5.07227 30.7194 5.07227 29.3452C5.07227 27.9706 5.85741 26.5305 7.56463 25.396Z" fill="#162082" stroke="#162082"/>
<path d="M27.775 36.3687C27.5917 36.3458 27.3854 36.3458 27.1792 36.3687C22.9625 36.2312 19.5938 32.7708 19.5938 28.5083C19.5938 24.1542 23.1 20.625 27.4771 20.625C31.8313 20.625 35.3604 24.1542 35.3604 28.5083C35.3604 32.7708 32.0146 36.2312 27.775 36.3687Z" fill="#162082"/>
<path d="M20.3268 41.1123C16.8663 43.4269 16.8663 47.2311 20.3268 49.5227C24.2684 52.1581 30.7309 52.1581 34.6726 49.5227C38.133 47.2081 38.133 43.404 34.6726 41.1123C30.7538 38.4769 24.2913 38.4769 20.3268 41.1123Z" fill="#162082"/>
</svg>
    )
}