import Cancel from "../btn/cancel";
import "../../css/support/login-history.css";
import LoginHistoryButton from "../btn/login-history-btn";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { SupportActions } from "../../state/actions";
import Moment from "react-moment";
import Pagination from "../footer-pagination";
export default function LoginHistory({ setToggle, id }) {
  const [current, setCurrent] = useState(1);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);

  const dispatch = useDispatch();
  const { userLoginHistory, userLoginHistoryMeta, userProfileView, userProfileViewMeta } = useSelector(
    (state) => state.support
  );
  const { FetchUserLoginHistory, FetchUserProfileView } = bindActionCreators(
    SupportActions,
    dispatch
  );

  const closeDisplay = () => {
    setToggle(0);
  };

  useEffect(() => {
    if (current === 1) {
      FetchUserLoginHistory(id, currentPage);
    } else {
      FetchUserProfileView(id, currentPage)
    }

    //eslint-disable-next-line
  }, [current, currentPage]);

  const sample = {
    per_page: 10,
    total: 0
  }

  const { per_page, total } = userLoginHistoryMeta ? userLoginHistoryMeta : sample;

  let userData = userLoginHistory.length > 0 ? userLoginHistory : [];

  let userData2 = userProfileView.length > 0 ? userProfileView : [];
  
  const profileView = userProfileViewMeta ? userProfileViewMeta : sample;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };
  const onPageChange2 = (page) => {
    setCurrentPage2(page);
  };

  return (
    <div className="login-history-container">
      <div className="login-history-content">
        <div className="login-history-cancel" onClick={closeDisplay}>
          <Cancel />
        </div>
        <p className="login-history-title">Login History</p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <LoginHistoryButton setCurrent={setCurrent} />
        </div>
        {current === 1 ? (
          <>
            <div className="login-history-table-wrapper">
              <table className="login-history-table">
                <tr>
                  <th>Time</th>
                  <th>Device</th>
                  <th>Location</th>
                  <th>Ip Address</th>
                </tr>

                {userData.map((item, idx) => (
                  <tr key={idx} className="history-items">
                    <td>
                      {item.created_at && (
                        <Moment format="DD/MM/YYY - HH:mm">
                          {item.created_at}
                        </Moment>
                      )}
                    </td>
                    <td>
                      {(item.data &&
                        item.data.deviceFamily + " " + item.data.platformName) ||
                        "-"}
                    </td>
                    <td>{item.location || "-"}</td>
                    <td>{item.ipAddress || "-"}</td>
                  </tr>
                ))}
              </table>
            </div>
            {userData.length > 0 && (
              <Pagination
                className="users-pagination"
                currentPage={currentPage}
                totalCount={total}
                pageSize={per_page}
                onPageChange={onPageChange}
              />
            )}
          </>
        ) : (
          <>
            <div className="login-history-table-wrapper">
              <table className="login-history-table">
                <tr>
                  <th>Time</th>
                  <th>Staff Name</th>
                  <th>Machine Name</th>
                </tr>

                {userData2.map((item, idx) => (
                  <tr key={idx} className="history-items">
                    <td>
                      {item.created_at && (
                        <Moment format="DD/MM/YYY - HH:mm">
                          {item.created_at}
                        </Moment>
                      )}
                    </td>
                    <td>
                      {((item.admin_name &&
                        item.admin_name) || "-" ) ||
                        "-"}
                    </td>
                    <td>{(item.device && item.device.deviceFamily + " " + item.device.platformName) || "-"}</td>
                  </tr>
                ))}
                {userData2.length === 0 && <tr>
                  <td colSpan={5}><span className="text-danger">No record found</span></td></tr>}
              </table>
            </div>
            {userData2.length > 0 && (
              <Pagination
                className="users-pagination"
                currentPage={currentPage2}
                totalCount={profileView.total}
                pageSize={profileView.per_page}
                onPageChange={onPageChange2}
              />
            )}
          </>
        )}

        {/* {current === 1 ?  <div className="login-history-button-section">
          <button className="login-history-close">Revoke all Sessions</button>
        </div> : null} */}
       
      </div>
    </div>
  );
}
