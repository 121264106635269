import { useState } from "react";
import Cancel from "../btn/cancel";
import "../../css/support/document-status.css";
import { useSelector } from "react-redux";
export default function DocumentStatus({ setToggle }) {
  const [close, setClose] = useState("");

  const { documentStatus } = useSelector((state) => state.support);

  const passport = documentStatus ? documentStatus.filter(
    (v) => v.verification_type === "bvn-selfie-verification"
  ) : []

  const IDCard = documentStatus ? documentStatus.filter(
    (v) => v.verification_type === "identity-card-verification"
  ) : []

  const Resident = documentStatus.filter(
    (v) => v.verification_type === "cac document verification"
  )

  const handleClick = () => {
    setClose("close");
    setToggle(0);
  };
  function getStatus(status) {
    if (status === "approved") {
      return (
        <td className="document-status-approved">
          <button>Approved</button>
        </td>
      );
    }
    if (status === "rejected") {
      return (
        <td className="document-status-reject">
          <button>Rejected</button>
        </td>
      );
    }
    if (status === "N/A") {
      return (
        <td className="document-status-nil">
          <button>N/A</button>
        </td>
      );
    }
    if (status === "pending") {
      return (
        <td className="document-status-pending">
          <button>Pending</button>
        </td>
      );
    }
  }
  return (
    <div className="document-status-container">
      <div className="document-status-content">
        <div
          className={`document-status-cancel ${close}`}
          onClick={handleClick}
        >
          <Cancel />
        </div>
        <p className="document-status-title">Document Status</p>
        <div>
          <table className="document-status-table">
            <tr style={{ textAlign: "center" }}>
              <th>Documents</th>
              <th>Number of Uploads</th>
              <th>Status</th>
              <th></th>
            </tr>
            <tr className="item-tr">
                <td>Passport</td>
                <td>{passport.length < 1 ? 0 : passport[0].count || 1}</td>
                <td>{passport.length < 1 ? getStatus(`N/A`) : getStatus(`${passport[0].status}`)}</td>
              </tr>
              <tr className="item-tr">
                <td>ID Card</td>
                <td>{IDCard.length < 1 ? 0 : IDCard[0].count || 1}</td>
                <td>{IDCard.length < 1 ? getStatus(`N/A`) : getStatus(`${IDCard[0].status}`)}</td>
              </tr>
              <tr className="item-tr">
                <td>Agency Doc</td>
                <td>{Resident.length < 1 ? 0 : Resident[0].count || 1}</td>
                <td>{Resident.length < 1 ? getStatus(`N/A`) : getStatus(`${Resident[0].status}`)}</td>
              </tr>
          </table>
        </div>
        <div className="document-status-button-section">
          <button className="document-status-close" onClick={handleClick}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

