// import "./css/support-dropdown.css"
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { AppContentActions } from "../../../state/actions";

function FaqDropDown({ acceptToggle, id }) {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { setActiveID } = bindActionCreators(AppContentActions, dispatch);

  const onClick = () => {
    navigate(`/edit-faq/${btoa(id)}`);
  };
  const changeToggle = (value) => {
    setActiveID(id);
    acceptToggle(value);
  };
  return (
    <div className="btn-group">
      <button
        type="button"
        className="btn btn-default"
        style={{
          width: "35px",
          height: "35px",
          backgroundColor: "darkblue",
          borderRadius: "100%",
          color: "white",
        }}
        data-toggle="dropdown"
        aria-expanded="false"
      >
        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
      </button>
      <div className="dropdown-menu">
        <span
          style={{ cursor: "pointer" }}
          className="dropdown-item"
          onClick={onClick}
        >
          View Details
        </span>
        <div class="dropdown-divider"></div>
        <span
          style={{ cursor: "pointer" }}
          className="dropdown-item"
          onClick={() => {
            changeToggle(10);
          }}
        >
          Delete Entry
        </span>
        {/* <div class="dropdown-divider"></div>
          <a className="dropdown-item" href="#" onClick={() => { changeToggle(3) }}>
          Re-assign
          </a> */}
      </div>
    </div>
  );
}

export default FaqDropDown;
