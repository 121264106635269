export default function Cancel() {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.208 0C21.276 0 24 2.856 24 7.104V16.9092C24 21.1452 21.276 24 17.208 24H6.804C2.736 24 0 21.1452 0 16.9092V7.104C0 2.856 2.736 0 6.804 0H17.208ZM15.612 8.3652C15.204 7.956 14.544 7.956 14.124 8.3652L12 10.5L9.864 8.3652C9.444 7.956 8.784 7.956 8.376 8.3652C7.968 8.7732 7.968 9.4452 8.376 9.852L10.512 11.9892L8.376 14.1132C7.968 14.5332 7.968 15.1932 8.376 15.6C8.58 15.804 8.856 15.9132 9.12 15.9132C9.396 15.9132 9.66 15.804 9.864 15.6L12 13.4772L14.136 15.6C14.34 15.8172 14.604 15.9132 14.868 15.9132C15.144 15.9132 15.408 15.804 15.612 15.6C16.02 15.1932 16.02 14.5332 15.612 14.1252L13.476 11.9892L15.612 9.852C16.02 9.4452 16.02 8.7732 15.612 8.3652Z"
          fill="#162082"
        />
      </svg>
    );
  }