
import "./css/vendor-success-notification.css"
import CloseBtn from "../../img/Close-Square.svg"
import SNotify from "../../img/successNotify.svg"
import { useState } from "react"

export default function VendorSuccessNotify({ notify, handleReject, handleModalUpload, setToggle }) {
    const [display1, setDisplay] = useState("")
    const closeDisplay1 = () => {
        setDisplay("deactivate-close")
        setToggle(0)
        handleModalUpload()
    }
    return (
        <>
            <div style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }} className={`success-display-details ${display1}`}>
                <div className={`success-notification`}>
                    <div className="success-notification-flexedcont"><img src={SNotify} loading="lazy" width="63" height="63" alt="" className="success-notification-image" />
                        <div className="success-notification-text-block-2">{notify}</div>
                        <a href="#/" className="success-notification-button success-notification-w-button" onClick={closeDisplay1}><strong>Close</strong></a>
                    </div>
                    <img onClick={closeDisplay1} src={CloseBtn} loading="lazy" width="24" height="24" alt="" className="success-notification-image-2" />
                </div>
            </div>
        </>
    )
}
