import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "../../css/user-control/roles-permission.css";
import KycInput from "../input/input";
import kimg from "../../img/ksearch.svg";
import plus from "../../img/plus.svg";
// import UserControlModalRoleAction from "./user-modal/roles-action-modal";

import Pagination from "../footer-pagination";
import RoleDropDown from "./user-modal/roles-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { userControlActions } from "../../state/actions";

function RolesPermission() {
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();

  const { LoadRoles, searchRoles, setRoleToggle } = bindActionCreators(
    userControlActions,
    dispatch
  );

  useEffect(() => {
    LoadRoles(currentPage);

    //eslint-disable-next-line
  }, [currentPage]);

  const { roles, searchRoleData } = useSelector((state) => state.userControl);

  const { per_page, total } = roles.meta;

  let roleData = searchRoleData ? searchRoleData : roles.data;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="rolesP-cont">
        <Link to="/create-role-permissions">
          <div>
            <button className="rolesP-btn-primary">
              <img src={plus} alt="plus icon" />
              New Role
            </button>
          </div>
        </Link>

        <div className="rolesP-kscont">
          <KycInput label="Search Records" searchValue={searchRoles} />
          <img src={kimg} alt="search icon" />
        </div>
      </div>

      <div>
        <div className="rolesP-table-content">
          <table className="rolesP-table">
            <tr>
              <td></td>
              <td>Role Title</td>
              <td>Permission Count</td>
              <td>User Count</td>
              <td>Created by</td>
            </tr>
            {roleData.map((item, idx) => (
              <tr className="rolesP-item-tr">
                <td>{(currentPage - 1) * per_page + 1 + idx}</td>
                <td>{item.name}</td>
                <td>{item.permissions.length}</td>
                <td>{item.user || "0"}</td>
                <td>{item.publisher || "-"}</td>
                <td>
                  <RoleDropDown name={item.name} id={item.id} setToggle={setRoleToggle} />
                </td>
              </tr>
            ))}
          </table>
        </div>
        <Pagination
          className="users-pagination"
          currentPage={currentPage}
          totalCount={total}
          pageSize={per_page}
          onPageChange={onPageChange}
        />
      </div>
    </>
  );
}
export default RolesPermission;
