import React from 'react'
import "./css/support-ticket-creation.css"
import CloseBtn from "../../../img/Close-Square.svg"
import Ticket from "../../../img/ticket.svg"
import { useState } from "react"



function SupportTicketCreationModal({handleReject, value, notify}) {

    const [display, setDisplay] = useState("")
    const closeDisplay = ()=> {
        setDisplay("close")
    }

  return (
    <>
     <div className={`display-details ${display}`}>
    <div className={`ticket-creation ${display}`}>
       <div onClick={closeDisplay}><strong class="ticket-creation-bold-text">Cancel</strong></div>
       <div className="ticket-creation-flexedcont"><img src={Ticket} loading="lazy" width="63" height="63" alt="" className="profile-reactivate-image"/>
       <div className="ticket-creation-text-block-2">{notify}</div>
       <textarea className="ticket-creation-textarea" name="" id="" cols="40" rows="6" placeholder="  Please input resolution comment (optional)"></textarea>
       <a href="#/" className="ticket-creation-button ticket-creation-w-button" onClick={() => {handleReject(value)}}><strong>Resolve</strong></a>
       </div>
       <img src={CloseBtn} loading="lazy" width="24" height="24" alt="" class="profile-reactivate-image-2" onClick={closeDisplay}/>
    </div>
     </div>
</>
  )
}

export default SupportTicketCreationModal