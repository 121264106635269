import React, { useEffect } from "react";
import KYCTOP from "../../components/kyc-top/kyc-top";
import Nav from "../../components/nav";
import "./profile-review.css";
import DCModal from "../../components/dcmodal";
import UploadDocument from "../../components/upload-document/upload_document";
import DocumentStatus from "../../components/support/document-status";
import BankCard from "../../components/support/bank-card";
import LoginHistory from "../../components/support/login-history";
import ProfileViewReactivation, {
  SupportUserReactivateError,
} from "../../components/support/modals/profileViewReactivateSuccess";
import { SupportUserReactivate } from "../../components/support/modals/profile-view-reactivate";
import TransactionHistory from "../../components/support/transaction";
import UserControlDeleteModal from "../../components/user-control/user-modal/role-delete-modal";
import { bindActionCreators } from "redux";
import { SupportActions, authActions } from "../../state/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Moment from "react-moment";
import { UserSearchDeactivation } from "../../components/vendorModals/vendorProfileDeactivation";

function SupportProfileReview() {
  // const [toggle, setToggle] = useState(0);

  const dispatch = useDispatch();

  const { userProfile, userLoginHistory, userSearchToggle, documentHistory } =
    useSelector((state) => state.support);
  const {
    FetchUserProfile,
    FetchUserLoginHistory,
    FetchUserTransactionHistory,
    setSupportSearchToggle,
    GetDocumentStatus,
    FetchUserProfileView,
    CreateUserProfileView,
    FetchDocumentHistory,
  } = bindActionCreators(SupportActions, dispatch);

  const { id } = useParams("id");

  const newID = atob(id);
  const { userID } = authActions.DecodeToken();

  useEffect(() => {
    CreateUserProfileView(newID, userID);
    FetchUserProfile(newID);
    FetchUserLoginHistory(newID);
    FetchUserTransactionHistory(newID);
    GetDocumentStatus(newID);
    setSupportSearchToggle(0);
    FetchUserProfileView(newID);
    FetchDocumentHistory(newID);

    //eslint-disable-next-line
  }, [newID]);

  const filterAddressVerification = () => {
    
    if (documentHistory.user ) {
      const verification = documentHistory.user.verifications.filter(
      (v) => v.verification_type === "business address verification"
    );
    return verification;
    }
    
  };

  const handleModal = () => {
    if (userSearchToggle === 0) {
      return null;
    }
    if (userSearchToggle === 1) {
      return (
        <div className="profile-review-modals">
          <DocumentStatus id={newID} setToggle={setSupportSearchToggle} />
        </div>
      );
    }
    if (userSearchToggle === 2) {
      return (
        <div className="profile-review-modals">
          <TransactionHistory id={newID} setToggle={setSupportSearchToggle} />
        </div>
      );
    }

    if (userSearchToggle === 3) {
      return (
        <div className="profile-review-modals">
          <UploadDocument id={newID} setToggle={setSupportSearchToggle} />
        </div>
      );
    }
    if (userSearchToggle === 4) {
      return (
        <div className="profile-review-modals">
          <DCModal setToggle={setSupportSearchToggle} />
        </div>
      );
    }
    if (userSearchToggle === 5) {
      return (
        <div className="profile-review-modals">
          <BankCard setToggle={setSupportSearchToggle} />
        </div>
      );
    }
    if (userSearchToggle === 6) {
      return (
        <div className="profile-review-modals">
          <LoginHistory id={newID} setToggle={setSupportSearchToggle} />
        </div>
      );
    }
    if (userSearchToggle === 7) {
      return (
        <div className="profile-review-modals">
          <SupportUserReactivate
            id={newID}
            setToggle={setSupportSearchToggle}
            value={10}
            notify="Are you sure you want to reactivate this customer's profile?"
          />
        </div>
      );
    }
    if (userSearchToggle === 8) {
      return (
        <div className="profile-review-modals">
          <UserControlDeleteModal
            handleReject={setSupportSearchToggle}
            value={9}
            content="Are you sure you want to delete this record"
          />
        </div>
      );
    }
    if (userSearchToggle === 9) {
      return (
        <div className="profile-review-modals">
          <ProfileViewReactivation
            notify="Record has been successfully deleted"
            setToggle={setSupportSearchToggle}
          />
        </div>
      );
    }
    if (userSearchToggle === 10) {
      return (
        <div className="profile-review-modals">
          <ProfileViewReactivation
            notify="Profile has been successfully reactivated"
            setToggle={setSupportSearchToggle}
          />
        </div>
      );
    }
    if (userSearchToggle === 11) {
      return (
        <div className="profile-review-modals">
          <UserSearchDeactivation
            id={newID}
            value={12}
            setToggle={setSupportSearchToggle}
          />
        </div>
      );
    }
    if (userSearchToggle === 12) {
      return (
        <div className="profile-review-modals">
          <ProfileViewReactivation
            notify="Profile has been successfully deactivated"
            setToggle={setSupportSearchToggle}
          />
        </div>
      );
    }

    if (userSearchToggle === 13) {
      return (
        <div className="profile-review-modals">
          <SupportUserReactivateError setToggle={setSupportSearchToggle} />
        </div>
      );
    }

    if (userSearchToggle === 14) {
      return (
        <div className="profile-review-modals">
          <ProfileViewReactivation
            notify="Document has been successfully uploaded"
            setToggle={setSupportSearchToggle}
          />
        </div>
      );
    }
  };

  return (
    <>
      {handleModal()}
      <div className="profile-preview-wrapper">
        <div className="profile-nav">
          <Nav Active={3} />
        </div>
        <div className="profile-preview-body profile-top">
          <div className="">
            <KYCTOP url="support/" suburl="Profile Review" />{" "}
          </div>

          <div className="profile-review-con">
            <div className="customer-app">
              <div>
                <h4>Customer's Details</h4>
                <div className="customer">
                  <div className="cust-grid-item">
                    <h6>Full Name</h6> <br />
                    <p>{(userProfile && userProfile.name) || "-"}</p>
                  </div>

                  <div className="cust-grid-item">
                    <h6>Email Address</h6> <br />
                    <p>{(userProfile && userProfile.email) || "-"}</p>
                  </div>
                  <div className="cust-grid-item">
                    <h6>Phone Number</h6> <br />
                    <p>{(userProfile && userProfile.telephone) || "-"}</p>
                  </div>

                  <div className="grid-item">
                    <h6>BVN</h6> <br />
                    <p>
                      {(userProfile &&
                        userProfile.kyc &&
                        userProfile.kyc.bvn_number) ||
                        "-"}
                    </p>
                  </div>
                  <div className="grid-item">
                    <h6>Date of Birth</h6> <br />
                    <p>
                      {(documentHistory && documentHistory.user && documentHistory.user.bvn &&
                        documentHistory.user.bvn.date_of_birth && (
                          <Moment format="MMM Do YYYY">
                            {documentHistory.user.bvn.date_of_birth}
                          </Moment>
                        )) ||
                        "-"}
                    </p>
                  </div>
                  <div className="grid-item">
                    <h6>Profile Level</h6> <br />
                    <p>{(userProfile && userProfile.kyc_level) || "-"}</p>
                  </div>
                  <div className="grid-item">
                    <h6>Address Verification Status</h6> <br />
                    <p>
                      {documentHistory &&
                        documentHistory.user &&
                        (filterAddressVerification() && filterAddressVerification().length > 0 && filterAddressVerification()[0].status === "approved" 
                          ? "Verified"
                          : "Unverified")}
                    </p>
                  </div>
                  <div className="grid-item">
                    <h6>Residential Address</h6> <br />
                    <p>{(documentHistory && documentHistory.user && documentHistory.user.bvn &&
                        documentHistory.user.bvn.residential_address) || "-"}</p>
                  </div>
                  <div className="grid-item">
                    <h6>Account Number</h6> <br />
                    <p>
                      {(userProfile &&
                        userProfile.account &&
                        userProfile.account.number) ||
                        "-"}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <h4>App Activity</h4>
                <div className="app-activity">
                  <div className="cust-grid-item">
                    <h6>Onboarding Date</h6> <br />
                    <p>
                      {(userProfile && (
                        <Moment format="MMM D, YYYY">{userProfile.dob}</Moment>
                      )) ||
                        "-"}
                    </p>
                  </div>
                  <div className="cust-grid-item">
                    <h6>Last Login</h6> <br />
                    <p>
                      {(userLoginHistory.length > 0 &&
                        userLoginHistory[0].login_at && (
                          <Moment format="MMM D, YYYY">
                            {userLoginHistory[0].login_at}
                          </Moment>
                        )) ||
                        "-"}
                    </p>
                  </div>
                  <div className="cust-grid-item">
                    <h6>Last Device Login</h6> <br />
                    <p>
                      {(userLoginHistory.length > 0 &&
                        userLoginHistory[0].data &&
                        userLoginHistory[0].data.deviceFamily +
                          " " +
                          userLoginHistory[0].data.deviceModel) ||
                        "-"}
                    </p>
                  </div>
                  <div className="grid-item">
                    <h6>Profile Status</h6> <br />
                    <p>{(userProfile && userProfile.status) || "-"}</p>
                  </div>
                  <div className="grid-item">
                    <h6>Deactivation Comment</h6> <br />
                    <p>{(userProfile && userProfile.comment) || "-"}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="support-func-cont">
              <h4>Support Functions</h4>
              <div className="support-func-con">
                <div className="support-func">
                  <button onClick={() => setSupportSearchToggle(1)}>
                    Document Status
                  </button>
                  <button onClick={() => setSupportSearchToggle(2)}>
                    Transaction History
                  </button>
                  <button
                    onClick={() => {
                      setSupportSearchToggle(3);
                    }}
                  >
                    Upload Document
                  </button>
                  <button onClick={() => setSupportSearchToggle(4)}>
                    Document History
                  </button>
                  <button onClick={() => setSupportSearchToggle(5)}>
                    Banks & Cards
                  </button>
                  <button onClick={() => setSupportSearchToggle(6)}>
                    Login History
                  </button>
                  {(userProfile && userProfile.status) === "inactive" ? (
                    <button
                      onClick={() => setSupportSearchToggle(7)}
                      className="green-btn"
                    >
                      Reactivate Profile
                    </button>
                  ) : (
                    <button
                      onClick={() => setSupportSearchToggle(11)}
                      className="red-btn"
                    >
                      Deactivate Profile
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SupportProfileReview;
