import { useState } from 'react'

import "../../css/upload-modal-id.css"
import UploadIDFront from "../../img/uploadIDFront.png"
import UploadIDBack from "../../img/upload-id.png"
import CloseBtn from "../../img/Close-Square.svg";
import VendorSuccessNotify from '../vendorModals/vendorSuccessNotification';
import RejectModal from '../vendorModals/rejectModal';

const UploadModalID = ({ handleModalUpload }) => {
  const [active, setActive] = useState("")
  const [modal, setModal] = useState(0)
  const closeModal = () => {
    setActive("close-modal")
    handleModalUpload()
  }
  const renderDom = () => {
    if (modal === 0) {
      return (<>
        <div className='upload-id-container'>
          <div className='upload-id-umCont'>
            <div className={`upload-id-modal`}>
              <div className="upload-id-header">
                <div className='upload-id-header-right'>Passport Upload</div>
                <div className='upload-id-header-left'>
                  <img src={CloseBtn} onClick={closeModal} alt="" ></img>
                </div>

              </div>
              <div className="uplaod-id-body">
                <div className="body-1">
                  <p style={{
                    width: "150px", fontWeight: "400",
                    fontSize: "15px", color: "#162082"
                  }}>ID Card(Front)</p>
                  <div className="upload-id-body-1">
                    <img src={UploadIDFront} alt="Front id upload" />
                  </div>
                  <div className="inputs">
                    <h5>ID Type</h5>
                    <p>International Passport</p>
                    <h5>Issue Date</h5>
                    <p>27  - 09  -  2019</p>
                  </div>
                </div>
                <div className="upload-id-body-2">
                  <p style={{
                    width: "150px", fontWeight: "400",
                    fontSize: "15px", color: "#162082"
                  }}>ID Card(Back)</p>
                  <div className="upload-id-body-22">
                    <img src={UploadIDBack} alt="Back id upload" />
                  </div>
                  <div className="inputs">
                    <h5>ID Card No</h5>
                    <p>AG34568038</p>
                    <h5>Expiry Date</h5>
                    <p>26  - 09  -  2024</p>

                  </div>
                </div>


              </div>
              <div className="upload-id-footer">
                <a href="#approve" className="upload-id-footer-button" onClick={() => setModal(1)}>
                  Approve
                </a>
                <a href="#reject" className="upload-id-footer-button-2" style={{ marginLeft: "15px" }} onClick={() => setModal(2)}>
                  Reject
                </a>
              </div>
            </div>
          </div>
        </div>
      </>)
    }
    if (modal === 1) {
      return <div className="display-deactivate close-deactivate">
        <VendorSuccessNotify handleModalUpload={handleModalUpload} notify="Document upload has been successfully approved" />
      </div>
    }
    if (modal === 2) {
      return <div className="display-deactivate close-deactivate">
        <RejectModal handleModalUpload={handleModalUpload} notify="Document upload has been successfully rejected" />
      </div>
    }
  }
  return (
    <>
      <div className={` ${active}`}>
        {renderDom()}
      </div>

    </>

  )
}

export default UploadModalID