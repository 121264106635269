import { Navigate } from "react-router-dom"

import Cookies from "js-cookie"
import * as types from "./state/types"
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { authActions } from "./state/actions";

const PrivateRoute = ({ children }) => {
    const dispatch = useDispatch();
    const { Logout } = bindActionCreators(authActions, dispatch);

    const token = Cookies.get(btoa(types.TOKEN))

    if (!token) {
        Logout()
        return <Navigate to='/welcome-back' />
    }

    return children
}

export default PrivateRoute
