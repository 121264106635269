import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../css/app-content/in_app_notifications.css";
import KycInput from "../input/input";
import kimg from "../../img/ksearch.svg";
import plus from "../../img/plus.svg";
// import InAppNotificationToggleModal from "./in_app_toggle_modal";
import AppContentDropDown from "../../pages/app-contents/dropdown/in-app-drop-down";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { AppContentActions } from "../../state/actions";
import Pagination from "../footer-pagination";
import Moment from "react-moment";

function InAppNotifications({ acceptToggle }) {
//   const [toggle, setToggle] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    inAppNotifications,
    inAppSearchData,
    inAppNotificationMeta,
    inAppLoading,
  } = useSelector((state) => state.appContent);

  const dispatch = useDispatch();
  const { FetchInAppNotification, searchInAppNotification } =
    bindActionCreators(AppContentActions, dispatch);

  useEffect(() => {
    FetchInAppNotification(currentPage);

    // eslint-disable-next-line
  }, [currentPage]);

  const { per_page, total } = inAppNotificationMeta;

  let userData =
    inAppSearchData !== null ? inAppSearchData : inAppNotifications;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  // const changeToggle = (value) => {
  //     setToggle(!toggle)
  // }

  return (
    <>
      <div className="in-app-user-cont">
        <div>
          <Link to="/create-in-app-notification">
            <button className="in-app-btn-primary">
              <img src={plus} alt="plus icon" />
              New Notification
            </button>
          </Link>
        </div>
        <div className="in-app-kscont">
          <KycInput
            label="Search Records"
            searchValue={searchInAppNotification}
          />
          <img src={kimg} alt="search icon" />
        </div>
      </div>

      <div>
        <div className="in-app-table-content">
          <table className="control-table">
            <tr>
              <td></td>
              <td>Notification Title</td>
              <td>Interval</td>
              <td>Created By</td>
              <td>Date Created</td>
            </tr>
            {userData.map((item, idx) => (
              <tr key={idx} className="in-app-item-tr">
                <td>{(currentPage - 1) * per_page + 1 + idx}</td>
                <td>{item.title}</td>
                <td>{item.interval}</td>
                <td>{(item.author && item.author.name) || "-"}</td>
                <td>{<Moment format="DD/MM/YYYY">{item.created_at}</Moment>}</td>
                <td>
                  {
                    <AppContentDropDown
                      id={item.id}
                      acceptToggle={acceptToggle}
                    />
                  }
                </td>
              </tr>
            ))}
            {userData.length === 0 ? (<tr>
            <td colSpan="5" className="text-danger">No Record found</td>
            </tr>) : null}
          </table>
        </div>
        {!inAppLoading && (
          <Pagination
            className="users-pagination"
            currentPage={currentPage}
            totalCount={total}
            pageSize={per_page}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </>
  );
}
export default InAppNotifications;
