import { useState } from "react"
// import BillTable from "./bill-table"
// import VendorTable from "./vendor-table2"
export default function VendorCard({ getId }) {
    const [active, setActive] = useState(0)
    const cardData = [
        {
            id: 1,
            text: "KYC Vendors",
            num: 8,
        },
        {
            id: 2,
            text: "Bill Vendors",
            num: 8,
        }
    ]


    function handleClick(id) {
        setActive(id)
        getId(id)
    }

    return (
        <>
            <div className="vendor-dash-card">
                {cardData.map((item, index) => (
                    <div className={active === item.id ? `vendor-vcard active bb` : `vendor-vcard`} key={index} onClick={() => handleClick(item.id)}>
                        <p style={{ width: "114px" }}>{item.text}</p>
                        <h2>{item.num}</h2>
                    </div>
                ))}

            </div>
        </>
    )
}