import * as types from "../types";

const initialState = {
  inAppNotifications: [],
  emailNotifications: [],
  articlesNotifications: [],
  faqsNotifications: [],
  inAppNotificationMeta: {},
  emailNotificationMeta: {},
  articlesNotificationMeta: {},
  faqsNotificationMeta: {},
  inAppSearchData: null,
  emailSearchData: null,
  articlesSearchData: null,
  faqsSearchData: null,
  error: {},
  inAppLoading: true,
  emailLoading: true,
  articlesLoading: true,
  faqsLoading: true,
  loading: false,
  activeCard: 0,
  toggle: 0,
  appContentId: "",
  inAppCreateToggle: 0,
  inAppCreateLoading: false,
  inAppError: {},
  emailCreateToggle: 0,
  emailCreateLoading: false,
  emailError: {},
  activeID: 0,
};

const appContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_APP_CONTENT_CARD:
      return {
        ...state,
        activeCard: action.payload,
      };
      case types.SET_ACTIV_ID:
      return {
        ...state,
        activeID: action.payload,
      };
    case types.SET_APP_CONTENT_TOGGLE:
      return {
        ...state,
        toggle: action.payload,
        appContentId: action.id,
      };
    case types.CREATE_IN_APP_NOTIFICATION:
      return {
        ...state,
        inAppCreateLoading: false,
        inAppCreateToggle: 1,
        inAppNotifications: [action.payload, ...state.inAppNotifications],
        inAppError: {},
      };
    case types.UPDATE_IN_APP_NOTIFICATION:
      return {
        ...state,
        inAppCreateLoading: false,
        inAppCreateToggle: 1,
        inAppNotifications: state.inAppNotifications.map((app) =>
          app.id === action.payload.id ? action.payload : app
        ),
        inAppError: {},
      };
    case types.DELETE_IN_APP_NOTIFICATION:
      return {
        ...state,
        toggle: action.toggle,
        inAppCreateLoading: false,
        appContentId: 0,
        activeID: 0,
        inAppNotifications: state.inAppNotifications.filter(
          (app) => app.id !== action.payload
        ),
      };
    case types.DELETE_IN_APP_NOTIFICATION_ERROR:
      return {
        ...state,
        activeID: 0,
        toggle: action.toggle,
        inAppError: action.payload,
        inAppCreateLoading: false,
      };
    case types.CREATE_IN_APP_NOTIFICATION_ERROR:
      return {
        ...state,
        inAppCreateLoading: false,
        inAppCreateToggle: 2,
        inAppError: action.payload,
      };
    case types.CREATE_EMAIL_NOTIFICATION:
      return {
        ...state,
        emailCreateLoading: false,
        emailCreateToggle: 1,
        emailNotifications: [action.payload, ...state.emailNotifications],
        emailError: {},
      };
    case types.UPDATE_EMAIL_NOTIFICATION:
      return {
        ...state,
        emailCreateLoading: false,
        emailCreateToggle: 1,
        emailNotifications: state.emailNotifications.map((app) =>
          app.id === action.payload.id ? action.payload : app
        ),
        inAppError: {},
      };
    case types.DELETE_EMAIL_NOTIFICATION:
      return {
        ...state,
        toggle: action.toggle,
        emailCreateLoading: false,
        appContentId: 0,
        activeID: 0,
        emailNotifications: state.emailNotifications.filter(
          (app) => app.id !== action.payload
        ),
      };
    case types.DELETE_EMAIL_NOTIFICATION_ERROR:
      return {
        ...state,
        toggle: action.toggle,
        activeID: 0,
        emailError: action.payload,
        emailCreateLoading: false,
      };
    case types.DELETE_ARTICLES_NOTIFICATION:
      return {
        ...state,
        toggle: action.toggle,
        emailCreateLoading: false,
        appContentId: 0,
        activeID: 0,
        articlesNotifications: state.articlesNotifications.filter(
          (app) => app.id !== action.payload
        ),
      };
      case types.DELETE_FAQ_NOTIFICATION:
      return {
        ...state,
        toggle: action.toggle,
        emailCreateLoading: false,
        appContentId: 0,
        activeID: 0,
        faqsNotifications: state.faqsNotifications.filter(
          (app) => app.id !== action.payload
        ),
      };
    case types.DELETE_ARTICLES_NOTIFICATION_ERROR:
      return {
        ...state,
        toggle: action.toggle,
        articleError: action.payload,
        activeID: 0,
        emailCreateLoading: false,
      };
    case types.CREATE_EMAIL_NOTIFICATION_ERROR:
      return {
        ...state,
        emailCreateLoading: false,
        emailCreateToggle: 2,
        emailError: action.payload,
      };
    case types.SET_LOADING:
      return {
        ...state,
        inAppCreateLoading: true,
      };
    case types.SET_IN_APP_CONTENT_TOGGLE:
      return {
        ...state,
        inAppCreateToggle: action.payload,
      };
    case types.SET_EMAIL_CONTENT_TOGGLE:
      return {
        ...state,
        emailCreateToggle: action.payload,
      };
    case types.FETCH_IN_APP_NOTIFICATION:
      return {
        ...state,
        inAppNotifications: action.payload,
        inAppNotificationMeta: action.meta,
        loading: false,
        inAppLoading: false,
      };
    case types.FETCH_EMAIL_NOTIFICATION:
      return {
        ...state,
        emailNotifications: action.payload,
        emailNotificationMeta: action.meta,
        loading: false,
        emailLoading: false,
      };
    case types.FETCH_ARTICLES_NOTIFICATION:
      return {
        ...state,
        articlesNotifications: action.payload,
        articlesNotificationMeta: action.meta,
        loading: false,
        articlesLoading: false,
      };
    case types.FETCH_FAQS_NOTIFICATION:
      return {
        ...state,
        faqsNotifications: action.payload,
        faqsNotificationMeta: action.meta,
        loading: false,
        faqsLoading: false,
      };
    case types.SEARCH_IN_APP_NOTIFICATION:
      return {
        ...state,
        inAppSearchData:
          action.payload &&
          state.inAppNotifications.filter(
            (item) =>
              item.title.toLowerCase().includes(action.payload.toLowerCase()) ||
              item.created_at
                .toLowerCase()
                .includes(action.payload.toLowerCase())
          ),
      };
    case types.SEARCH_EMAIL_NOTIFICATION:
      return {
        ...state,
        emailSearchData:
          action.payload &&
          state.emailNotifications.filter(
            (item) =>
              item.title.toLowerCase().includes(action.payload.toLowerCase()) ||
              item.created_at
                .toLowerCase()
                .includes(action.payload.toLowerCase())
          ),
      };
    case types.SEARCH_ARTICLES_NOTIFICATION:
      return {
        ...state,
        articlesSearchData:
          action.payload &&
          state.articlesNotifications.filter(
            (item) =>
              item.title.toLowerCase().includes(action.payload.toLowerCase()) ||
              item.created_at
                .toLowerCase()
                .includes(action.payload.toLowerCase())
          ),
      };
    case types.SEARCH_FAQS_NOTIFICATION:
      return {
        ...state,
        faqsSearchData:
          action.payload &&
          state.faqsNotifications.filter(
            (item) =>
              item.title.toLowerCase().includes(action.payload.toLowerCase()) ||
              item.created_at
                .toLowerCase()
                .includes(action.payload.toLowerCase())
          ),
      };
    default:
      return state;
  }
};

export default appContentReducer;
