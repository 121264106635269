import axios from "../../axios";
import * as types from "../types";

export const SetError = (error) => (dispatch) => {
  dispatch({
    type: types.LOGIN_ERROR,
    payload: error,
  });
};

export const Login = (email, password, remember) => async (dispatch) => {
  
  try {
    const res = await axios.post("/login", { email, password, remember });
    if (res.data.data.user.status !== "active") {
      dispatch({
        type: types.LOGIN_ERROR,
        payload: { top: "Please contact an administrator, your account is not active" },
      });
    } else {
      dispatch({
        type: types.LOGIN,
        payload: res.data.data,
        
      });
      window.location.href="/"
    }
  } catch (err) {
    
    if (err.response.status === 500) {
      dispatch({
        type: types.LOGIN_ERROR,
        payload: { top: (err.response.data && err.response.data.error.message) || "something went wrong, Please try again or contact an administrator" },
      });
    } else {
      dispatch({
      type: types.LOGIN_ERROR,
      payload: { top: (err.response.data && err.response.data.error.message.join("\n")) || "something went wrong, Please try again or contact an administrator" },
    });
    }
    
  }
};

export const Logout = () => async (dispatch) => {
  try {
    const res = await axios.post("/logout");
    dispatch({
      type: types.LOGOUT,
    });
    console.log(res.data)
    
  } catch (err) {
    dispatch({
      type: types.LOGOUT,
    });    
    console.log(err.response)
  }
 
};

export const FetchUserDetail = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/users/${id}?include=roles`);
    let res2;
    const roles = res.data.data.user.roles
    if (roles.length > 0) {
      res2 = await axios.get(`roles/${roles[0].id}?include=permissions`)
    }
    
    dispatch({
      type: types.FETCH_USER,
      payload: res.data.data.user,
      roles: (res2 && res2.data.data.role) || {}
    });
  } catch (err) {
    dispatch({
      type: types.FETCH_USER_ERROR,
      payload: { editTop: err.response.data.error.message },
    });
  }
};

export const ClearLocalStorage = () => (dispatch) => {
  dispatch({
    type: types.CLEAR_LOCAL,
  });
};

export const SetUser = () => (dispatch) => {
  dispatch({
    type: types.SET_USER,
  });
};

export const SetLoading = () => (dispatch) => {
  dispatch({
    type: types.SET_LOADING,
  });
};

export const DecodeToken = () => {
  const user = localStorage.getItem(btoa(types.USERNAME));
  const value = JSON.parse(atob(user));
  return value;
};

export const CheckPermission = (role, permission) => {
  if (role.name === "admin" || role.name === "super-admin") {
    return true;
  }
  const permissions = role.permissions;
  const found = permissions.find((item) => item === permission);
  if (found) {
    return true;
  }

  return false
}
