// import { useSelector } from "react-redux";

import "../css/kyc-distr.css";
// import { Column } from "@ant-design/plots";

import "charts.css";

export default function KYCDistribution() {
  // const { data, loading } = useSelector((state) => state.dashboard);

  const { data, loading } = {
    data: {
      user_count: 19873,
      daily_user_count: 8045,
      weekly_user_count: 10927,
      monthly_count: 14052,
      active_user_count: 15683,
      address_verification: {
        total_count: 18,
        approved_count: 4,
        pending_count: 0
      },
      bills: {
        airtime_bill_count:43,
        other_bill_count:61,
        total_count:61,
        tv_bill_count: 1
      },
      cash_request:{
        pending_cash_request_count: 0,
        processed_cash_request_count: 0,
        total_count: 0
      },
      complaints:{
        pending_count:1,
        resoled_count:0,
        total_count:1
      },
      current_active_cash_provider: 1,
      inactive_user_count: 3190,
      kyc: {
        agent_level :6126,
        level_one :4013,
        level_two :8933,
        level_zero :801,
        pending_kyc_count :801,
        total_count :19873
      },
      transaction:{
        failed_count: 18,
        pending_count: 214,
        success_count: 7122,
        total_count: 7354,
        total_volume: 12457980.00
    },
  },
    loading: false
  };

  let max = 1;
  let kycs = [0, 0, 0, 0];
  let level0 = 0;
  let level1 = 0;
  let level2 = 0;
  let agent_level = 0;
  if (data !== null) {
    kycs = new Float64Array([
      data.kyc.level_zero,
      data.kyc.level_one,
      data.kyc.level_two,
      data.kyc.agent_level,
    ]);
    
    kycs.sort((a, b) => b - a)

    level0 = loading ? 0 : data.kyc.level_zero;
    level1= 3670
    level2=2934
    agent_level= 2561  
    // level1   = loading ? 0 : data.kyc.level_two;
    // level2 = loading ? 0 : data.kyc.agent_level;
    // agent_level = loading ? 0 : data.kyc.level_one;
  }
  max = kycs[0]

// static dummy data..
  const total_transaction_count = Math.round(data.transaction.total_volume/1000)
  const total_active =  Math.round(2/3 *  total_transaction_count)
  const total_customer = Math.round(total_active + (1/5 * total_active))

  const DemoColumn2 = () => {
    return (
      <>
        <table
          id="kyc-column"
          class="charts-css column show-labels data-spacing-5"
        >
          <tbody>
            <tr>
              <th>No KYC</th>
              <td
                style={{
                  "--size": max === 0 ? 0 : level0 / max,
                }}
              >
                <span class="data"> {level0} </span>
              </td>
            </tr>
            <tr>
              <th>Level 1</th>
              <td
                style={{
                  "--size": max === 0 ? 0 : level1 / max,
                }}
              >
                <span class="data"> {level1} </span>
              </td>
            </tr>
            <tr>
              <th>Level 2</th>
              <td
                style={{
                  "--size": max === 0 ? 0 : level2 / max,
                }}
              >
                <span class="data"> {level2} </span>
              </td>
            </tr>
            <tr>
              <th>Agency</th>
              <td
                style={{
                  "--size":
                  max === 0 ? 0 : agent_level / max,
                }}
              >
                <span class="data"> {agent_level} </span>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  return (
    <div>
      <div className="line-chart-section dashboard-card-color">
        <div className="line-chart-title-section">
          <p className="line-chart-title">KYC Distribution</p>
        </div>
        <div className="kyc-distr-detail line-chart-details">
          <div className="chart-details">
            <p className="line-chart-sub-title">Total Customer</p>
            {/* <h1>{data !== null ? data.kyc.total_count : 0}</h1> */}

            {/* total customer dummy data */}
            <h1>{total_customer }</h1>
          </div>
          <div>
            <p className="line-chart-sub-title">Pending KYC</p>
            <h1>{data !== null ? data.kyc.pending_kyc_count : 0}</h1>
          </div>
        </div>
        <div className="demo-column">
          <DemoColumn2 />
        </div>
      </div>
    </div>
  );
}

// const DemoColumn = () => {
//   const data = [
//     {
//       type: "No KYC",
//       sales: 38,
//     },
//     {
//       type: "Level 1",
//       sales: 52,
//     },
//     {
//       type: "Level 2",
//       sales: 61,
//     },
//     {
//       type: "Agency",
//       sales: 145,
//     },
//   ];
//   const config = {
//     data,
//     xField: "type",
//     yField: "sales",
//     seriesfield: '',
//     color: '#162082',
//     label: {
//       position: "top",
//       // 'top', 'bottom', 'middle',
//       // 配置样式
//       style: {
//         fill: "#FFFFFF",
//         opacity: 0.6,
//       },
//     },
//     xAxis: {
//       label: {
//         autoHide: true,
//         autoRotate: false,
//       },
//     },
//     meta: {
//       type: {
//         alias: "measure",
//       },
//       sales: {
//         alias: "measure",
//       },
//     },
//   };
//   return <Column {...config} />;
// };
