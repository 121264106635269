import Logo from "../logo";
import "./kyc-top.css";

function KYCTOP({ url, suburl }) {
  return (
    <div className="kyc-top-container">
      <div className="kyc-top-content">
        {suburl ? (
          <h3 style={{ fontSize: "px" }}>
            <small style={{ fontWeight: "380" }}>{url}</small>
            {suburl}
          </h3>
        ) : (
          <h3 style={{fontSize: "26px"}}>{url}</h3>
        )}
      </div>
      <div className="logo-nav"><Logo /></div>
    </div>
  );
}

export default KYCTOP;
