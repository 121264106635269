import * as types from "../types";

const initialState = {
  cashRequests: [],
  cashRequestMeta: {},
  searchCashRequestData: null,
  searchCashRequestMeta: null,
  cashRequestLoading: true,
  billHistory: [],
  billHistoryMeta: {},
  billHistoryLoading: true,
  failedTransaction: [],
  failedTransactionMeta: {},
  searchFailedTransactionMeta: null,
  searchFailedTransactionData: null,
  searchBillHistoryMeta: null,
  searchBillHistoryData: null,
  failedTransactionLoading: true,
  laoding: false,
};

const settlementReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_CASH_REQUEST:
      return {
        ...state,
        cashRequests: action.payload,
        cashRequestMeta: action.meta,
        loading: false,
        cashRequestLoading: false,
      };
    case types.FETCH_BILL_HISTORY:
      return {
        ...state,
        billHistory: action.payload,
        billHistoryMeta: action.meta,
        loading: false,
        billHistoryLoading: false,
      };
    case types.FETCH_FAILED_TRANSACTION:
      return {
        ...state,
        failedTransaction: action.payload,
        failedTransactionMeta: action.meta,
        loading: false,
        failedTransactionLoading: false,
      };
    case types.SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.SEARCH_FAILED_TRANSACTION:
      return {
        ...state,
        searchFailedTransactionData: action.payload,
        searchFailedTransactionMeta: action.meta,
      };
    case types.SEARCH_BILL_HISTORY:
      return {
        ...state,
        searchBillHistoryData: action.payload,
        searchBillHistoryMeta: action.meta,
      };

    case types.SEARCH_CASH_REQUEST:
      return {
        ...state,
        searchCashRequestData: action.payload,
        searchCashRequestMeta: action.meta,
      };
    default:
      return state;
  }
};

export default settlementReducer;
