import { useSelector } from 'react-redux'

import '../../css/cash-provider.css'

export default function CashProvider(){
    // const { data } = useSelector((state) => state.dashboard)

    const { data } = {
        data: {
          user_count: 19873,
          daily_user_count: 8045,
          weekly_user_count: 10927,
          monthly_count: 14052,
          active_user_count: 15683,
          address_verification: {
            total_count: 18,
            approved_count: 4,
            pending_count: 0
          },
          bills: {
            airtime_bill_count:43,
            other_bill_count:61,
            total_count:61,
            tv_bill_count: 1
          },
          cash_request:{
            pending_cash_request_count: 0,
            processed_cash_request_count: 0,
            total_count: 0
          },
          complaints:{
            pending_count:1,
            resoled_count:0,
            total_count:1
          },
          current_active_cash_provider: 8933,
          inactive_user_count: 3190,
          kyc: {
            agent_level :4,
            level_one :2,
            level_two :1,
            level_zero :2,
            pending_kyc_count :1,
            total_count :6
          },
          transaction:{
            failed_count: 18,
            pending_count: 214,
            success_count: 7122,
            total_count: 7354,
            total_volume: 8534450.00
        },
      },
        loading: false
      };
    return(
        <div className='dashboard-card-color cash-provider'>
            <p>Current Active Cash Provider</p>
            {/* <h2>{data !== null ? data.current_active_cash_provider : 0}</h2> */}
            <h2>{`468`}</h2>
        </div>
    )
}