import "../../css/upload-document/upload-doc-modal.css"

const UploadDocumentModal = ({options, setCardType}) => {
    return (
      <div className={`uploadDoc-wrapper ${options}`}>
        <div className={`uploadDoc-modal`}>
            <div className="uploadDoc-modal-content" onClick={() => setCardType('ID Card')}>ID Card</div>
            <hr />
            <div className="uploadDoc-modal-content" onClick={() => setCardType('Passport')}>Passport</div>
            <hr />
            <div className="uploadDoc-modal-content" onClick={() => setCardType('Business Registration Doc')}>Business Registration Doc</div>
            <hr />
            {/* <div className="uploadDoc-modal-content" onClick={() => setCardType('Resident Permit')}>Resident Permit</div>
            <hr /> */}
            {/* <div className="uploadDoc-modal-content" onClick={() => setCardType('CAC Cert of Incorporation')}>CAC Cert of Incorporation</div> */}
        </div>
    </div>
  )
}

export default UploadDocumentModal
