import "../../css/login.css";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { useState } from "react";
import Toggle from "../../components/btn/toggle";

import { useSelector, useDispatch } from "react-redux"
import { bindActionCreators } from "redux";

import { authActions } from "../../state/actions";

import Spinner from "./spinner";

function Login() {
  const [email, setEmail] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [remember, setRemember] = useState(false);

  const { error, loading } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const { Login, SetError, SetLoading } = bindActionCreators(authActions, dispatch)

  const handleLogin = (e) => {
    e.preventDefault();
    SetLoading()
    if (email.trim() === "") {
      SetError({ email: "Email field is required" });
    } else if (passwordInput.trim() === "") {
      SetError({ password: "Password field is required" });
    } else {
      Login(email, passwordInput, remember)
    }
  };

  const handlePasswordChange = (e) => {
    setPasswordInput(e.target.value);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div className="dashboard">
      <div className="login-containers">
        <div className="login-container">
          <form className="login-form-section" onSubmit={handleLogin}>
            <h4 className="login-header">Login</h4>
            {error.top && <span className="top-span">{error.top}</span>}
            <div className="login-contents">
              <div className="login-content">
                {error.email && <span className="span">{error.email}</span>}
                <div className="login-input-section login-email-section">
                  <label className="email" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your AD email"
                  />
                </div>
                {error.password && <span className="span">{error.password}</span>}
                <div className="login-input-section login-password-section">
                  <label className="pass" htmlFor="password">
                    Password
                  </label>
                  <input
                    type={passwordType}
                    name="password"
                    value={passwordInput}
                    onChange={handlePasswordChange}
                    placeholder="Enter your AD password"
                  />
                  <span onClick={togglePassword}>
                    {passwordType === "password" ? (
                      <BsEyeSlash color="#162082" size={25} />
                    ) : (
                      <BsEye color="#162082" size={25} />
                    )}
                  </span>
                </div>
              </div>

              <div className="toggle" >
                <Toggle checked={remember} setRemember={setRemember} />
                <p>Remember me</p>
              </div>
            </div>
            <button type="submit" className="login-btn-primary" disabled={loading ? true : false}>
              {loading ? <Spinner /> : "Login"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
