import React from 'react'
import { useNavigate } from 'react-router-dom'

// import closeBtn from "../../../img/Close-Square.svg"
import rejection from "../../../img/rejection.svg"
import "../../../css/kyc/rejectModal.css"

const RejectConfirmation = ({ setView, setModal, close }) => {
    // const [toggle, setToggle] = useState(true)

    const navigate = useNavigate()

    // const CloseDisplay = () => {
        // setView("")
        // setModal(0)
        // setToggle(!toggle)
    // }
    const submit = () => {
        if (close === "close") {
             setView("")
            setModal(0)
        } else {
            navigate("/kyc")
            setView("hidden")
        }
       
    }
    return (
        <>
            <div className={`level1Reject-container`}>

                <div className='level1Reject-header'>
                    <div></div>
                    <div>

                    </div>

                </div>
                <div className='level1Reject-body'>
                    <div>
                        <img src={rejection} loading="lazy" alt="" />
                    </div>
                    <div className='level1-confirm2'>You have successfully rejected the customer's selfie
                    </div>

                    <button className='level1-body4' onClick={submit}>Close</button>
                </div>

            </div>

        </>
    )
}

export default RejectConfirmation