import React from "react";
import "./layout.css";
import Nav from "../../components/nav";
import KYCTOP from "../../components/kyc-top/kyc-top";
import LineChart from "../../components/issues/line-chart";
import IssueComp from "../../components/issues/issues-comp";
import CashProvider from "../../components/dashboard/cash-provider";
import KYCDistribution from "../../components/kyc-distr";
import TransactionInsight from "../../components/dashboard/transaction-insight";
import { Link } from "react-router-dom";


export default function Layout() {
  return (
    <>
      <div class="wrapper">
        <div className="content">
          <KYCTOP url={"Welcome "} suburl={"Bola"} />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "31px 40px",
            }}
          >
            <div className="dashboard-content">
              <div style={{ width: "628px", marginRight: "25px" }}>
                <LineChart />
              </div>
              <div style={{ width: "747px" }}>
                <TransactionInsight />
              </div>
            </div>
            <div className="kyc-dist-cash-provider">
              <div className="kyc-issue" style={{}}>
                <div>
                  <KYCDistribution />
                </div>

                <IssueComp data={complaint[0]} />
              </div>
              <div style={{ width: "53%" }}>
                <div style={{ marginBottom: "20px" }}>
                  <Link to="/vendor" className="rm-link-dec">
                    <CashProvider />
                  </Link>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "30px",
                  }}
                >
                  <IssueComp data={complaint[1]} />
                  <IssueComp data={complaint[0]} />
                  <IssueComp data={complaint[2]} />
                  <IssueComp data={complaint[3]} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <aside>
          <Nav />
        </aside>
      </div>
    </>
  );
}
const complaint = [
    {
      total: {
        title: "Complaints",
        value: 23,
      },
      done: {
        title: "Resolved",
        value: 23,
      },
      undone: {
        title: "Pending",
        value: 23,
      },
    },
    {
      total: {
        title: "Total User Count",
        value: 23,
      },
      done: {
        title: "Active users",
        value: 23,
      },
      undone: {
        title: "Inactive user",
        value: 23,
      },
    },
    {
      total: {
        title: "Address Verification",
        value: 23,
      },
      done: {
        title: "Active users",
        value: 23,
      },
      undone: {
        title: "Inactive user",
        value: 23,
      },
    },
    {
      total: {
        title: "KYC vendors",
        value: 23,
      },
      done: {
        title: "Active users",
        value: 23,
      },
      undone: {
        title: "Inactive user",
        value: 23,
      },
    },
  ];
  