import "../vendorModals/css/bills.css"
import Closebtn from "../../img/Close-Square.svg"
// import BillTable from "../../pages/vendors/vendor-table2";
import { useState } from "react";

export default function Bills({setToggle}){

    const [display, setDisplay] = useState("")
    const closeDisplay = ()=> {
      setDisplay("close-details")
      setToggle(0)
    }

    const vData = [
        {
            id: 1,
            name: "Wade Warren",
            trxid: "CR12344U",
            amount: "N12,000",
            status: "Succefull",
            billType: "IKEDC",
            time: "24/11/2021 - 17:01"
        },
        {
            id: 2,
            name: "Wade Warren",
            trxid: "BL1234667",
            amount: "N1,500",
            status: "Failed",
            billType: "Airtime Recharge",
            time: "24/11/2021 - 17:01"
        }
    ]
    return(
        <>
     <div className={`display-details ${display}`}>

<div className={`modal-container`}>
  <div className="modal-content">
    <img
      className="cancel-icon4"
      src={Closebtn}
      alt=""
      onClick={closeDisplay}
    />
    <div className={`closecase1 ${display}`}>

    <div style={{
      display: "flex",
      justifyContent: "center",
      color: "#462C94",
      fontSize: "20px"

    }}><h2>Bills Transaction History</h2></div>
      <div className="bill-cont">
        
        <div className="bills">
          <div className="heading1">
            <div className="search-section1">
              <div className="input-cont">
                <input type="date" />
                <input type="date" />
                <button>Display Records</button>
                <p className="verify-pass">Displaying Record for Today</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="table-content">
      <table style={{ width: "100%" }} className={`${display}`}>
        <tr>
          <th></th>
          <th>Customer</th>
          <th>Transaction ID</th>
          <th>Amount</th>
          <th>Status</th>
          <th>Bill Type</th>
          <th>Time</th>
        </tr>
        {vData.map((item) => (
          <tr className="item-tr">
            <td>{item.id}</td>
            <td>{item.name}</td>
            <td>{item.trxid}</td>
            <td>{item.amount}</td>
            <td>{item.status}</td>
            <td>{item.billType}</td>
            <td>{item.time}</td>
          </tr>
        ))}
      </table>
    </div>
  </div>
</div>
</div>
        </>
    )
}