import React, { useState } from 'react'

import closeBtn from "../../../img/Close-Square.svg"
import rejection from "../../../img/rejection.svg"
import down from "../../../img/downArrow.svg"
import "../../../css/kyc/rejectModal.css"
import DropdownModal from './dropDown'

const Level1RejectModal = ({ setView, setModal, setComment }) => {
    const [toggle, setToggle] = useState(true)
    const [options, setOptions] = useState(false)
    const [cardType, setCardType] = useState("Blurry Image")

    const CloseDisplay = () => {
        setView("")
        setModal(0)
        setToggle(!toggle)
    }
    const changeCardType = (value) => {
        setCardType(value)
        setOptions(!options)
    }
    const submit = () => {
        setComment(cardType)
        setModal(2)
    }

    return (
        <>
            <div className={`level1Reject-container ${toggle}`}>

                <div className='level1Reject-header'>
                    <div></div>
                    <div>
                        <img src={closeBtn} loading="lazy" width="24" height="24" alt="" onClick={CloseDisplay} />
                    </div>
                    

                </div>
                <div className='level1Reject-body'>
                    <div>
                        <img src={rejection} loading="lazy" alt="" />
                    </div>
                    <div className='level1-body2'>Select Rejection Reason</div>
                    <div className='level1-body3'>
                        <div className='level1Body-dropdown' onClick={() => setOptions(!options)}>
                            <div className='level1Body-text'>{cardType}</div>
                            <img className="level1Body-image" width="10" height="7" src={down} alt="" />
                        </div>
                    </div>
                    {options && <DropdownModal changeCardType={changeCardType} />}
                    {!options && <button className='level1-body4' onClick={submit}>Submit</button>}
                </div>

            </div>

        </>
    )
}

export default Level1RejectModal