import React, { useState } from "react";
import { useSelector } from "react-redux";

import "../../css/support/support-search.css";
import SupportSearchResult from "./search-result";

function SupportProfileSearch({ setCardHeight }) {
  const [input, setInput] = useState("");
  const [searchTable, setSearchTable] = useState(false);
  const [text, setText] = useState("");
  const [innerError, setInnerError] = useState("");

  const { loading } = useSelector(
    (state) => state.support
  );

  const onClick = () => {
    if (input.trim() === "") {
      setInnerError("Empty search!! please search by Phone, Name or Bvn");
      setSearchTable(false);
      setCardHeight(true);
      
    } else {
      setInnerError("");
      setText(input);
      setSearchTable(true);
      setCardHeight(false);
    }
  };

  return (
    <>
      <div className="profile-search-wrapper">
        <div className="profile-search-cont">
          <h3>Profile Search</h3>
          {innerError && (
            <h6 style={{ marginBottom: "10px" }} className="text-danger">
              {innerError}
            </h6>
          )}
          {/* {error.top && (
            <h6 style={{ marginBottom: "10px" }} className="text-danger">
              {error.top}
            </h6>
          )} */}
          <input
            type="search"
            name="search"
            id="search"
            placeholder="Search customer`s profile by Phone/Name/BVN"
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />

          <button className="support-btn" onClick={onClick} disabled={loading}>
            Search
          </button>
        </div>

        <div>
          {searchTable && (
            <div>
              <div className="support-search-text">{`search Result for: ${text} `}</div>
              <SupportSearchResult search={text} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default SupportProfileSearch;
