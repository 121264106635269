import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"

import Cookies from "js-cookie"
import * as types from "./state/types"

const LoginPrivateRoute = ({ children }) => {
    const { user, authenticate } = useSelector((state) => state.auth)

    const token = Cookies.get(btoa(types.TOKEN))

    if (token || authenticate) {
        return <Navigate to='/' />
    }
    if (user !== null && !token) {
        return <Navigate to='/welcome-back' />
    }

    return children
}

export default LoginPrivateRoute
