import React from "react";
import { BsEye } from "react-icons/bs";
import { BsLock } from "react-icons/bs";
import { BsTelegram } from "react-icons/bs";

import "../../css/main-chat.css";
import Lock from "./asset/lock.svg";

const MainChat = () => {
  return (
    <div className="main-chat">
      <div className="top-bar">
        <div className="first">
          <div>
            <BsLock style={{ marginRight: "10px" }} />
          </div>
          <small>Lock</small>
        </div>
        <div className="second">
          <div>
            <BsEye src={Lock} style={{ marginRight: "7px" }} />
          </div>
          <small>4</small>
        </div>
      </div>
      <hr className="divider" />
      <div className="chat-body">
        <div className="body">
          <div className="right">
            <div className="body-right">
              Welcome to the chat, please type your message here and hit enter
              to send. You can also type /help to see a list of commands. You
              can also type /help <strong>command</strong> to see a list of
              commands for a specific command.
            </div>
            {/* <div className="image"></div> */}
          </div>

          <div className="body-left">
            Hi there, I'm a bot. I'm here to help you with your account. I can
            help you with the following:
          </div>

          <div className="body-left">Welcome back</div>
          <div className="body-left">Tell me more about the platform</div>
          <div className="body-left">Lorem ipsum loremipsum lorem ipsum</div>

          <div className="right">
            <div className="body-right">
              Okay the platform is a great way to get started. I'm glad you're
              here. I'm glad you're here.
            </div>
          </div>
          <div className="day">
            <div className="day-left"></div>
            <div>TODAY</div>
            <div className="day-right"></div>
          </div>
          <div className="right">
            <div className="body-right">
              Okay the platform is a great way to get started. I'm glad you're
              here. I'm glad you're here.
            </div>
            {/* <div className="image">dfsd</div> */}
          </div>
        </div>
      </div>
      <div className="chat-bottom">
        <div className="input-container">
          <input type="text" name="input" placeholder="Type message..." />
        </div>
        <div className="chat-submit">
          <a href="#send">
            <BsTelegram style={{ fontSize: "40px" }} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default MainChat;
