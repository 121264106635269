import KYCTOP from "../../components/kyc-top/kyc-top";
import './vendor.css'
import VendorTable from "./vendor-table2";
import BillTable from "./bill-table";
import VendorCard from "./vendor-card";
import { useState } from "react"
import VendorProfileDeactivation from "../../components/vendorModals/vendorProfileDeactivation";
import KYCVendorDetails from "../../components/vendorModals/vendorModalDetails";
import VendorProfileReactivation from "../../components/vendorModals/vendorProfileReactivation";
import VendorSuccessNotify from "../../components/vendorModals/vendorSuccessNotification";
import Bills from "../../components/vendorModals/bills";
import VendorAddressVerificationHistory from "../../components/vendorModals/addressVerificationHistory";
import Nav from "../../components/nav";
import BillsVendorDetails from "../../components/vendorModals/billsVendorDetails";

export default function Vendor() {
    const [id, getId] = useState(0)
    const [toggle, setToggle] = useState(0)
    const [deactivate, setDeactivate] = useState(false)
    const [reject, setReject] = useState(false)


    const handleReject = () => {
        setReject(c => c = true)
        setDeactivate(c => c = false)
    }

    const changeToggle = (value) => {
        console.log(value)
        setToggle(value)
        console.log(toggle)
    }

    const handleToggle = () => {
        if (toggle === 0) {
            return null
        }
        if (toggle === 1) {
            return <div className="kyc-ven-detail"><KYCVendorDetails setToggle={setToggle} /></div>
        }
        if (toggle === 2) {
            return <div className="kyc-ven-detail"><VendorProfileDeactivation value={5} handleReject={setToggle} /></div>
        }
        if (toggle === 3) {
            return <div className="kyc-ven-detail"><VendorProfileReactivation notify={"Are you sure you want to reactivate this vendor?"} value={6} handleReject={setToggle} /></div>
        }
        if (toggle === 4) {
            return <div className="kyc-ven-detail"><VendorAddressVerificationHistory setToggle={setToggle} /></div>
        }
        if (toggle === 5) {
            return <div className="display-deactivate close-deactivate"> <VendorSuccessNotify notify={"Vendor has been successfully deactivated"} handleReject={handleReject} reject={reject} deactivate={deactivate} /></div>
        }
        if (toggle === 6) {
            return <div className="display-deactivate close-deactivate"> <VendorSuccessNotify notify={"Vendor has been successfully reactivated"} handleReject={handleReject} /></div>
        }
        if (toggle === 7) {
            return <div className="kyc-ven-detail"> <BillsVendorDetails setToggle={setToggle} /></div>
        }
        if (toggle === 8) {
            return <div className="kyc-ven-detail"> <VendorProfileDeactivation value={11} setToggle={setToggle} /></div>
        }
        if (toggle === 9) {
            return <div className="kyc-ven-detail"> <VendorProfileReactivation notify={"Are you sure you want to reactivate this KYC vendor?"} value={12} setToggle={setToggle} /></div>
        }
        if (toggle === 10) {
            return <div className="kyc-ven-detail"> <Bills setToggle={setToggle} /></div>
        }
        if (toggle === 11) {
            return <div className="display-deactivate close-deactivate"> <VendorSuccessNotify notify={"KYC Vendor has been successfully deactivated"} handleReject={handleReject} /></div>
        }
        if (toggle === 12) {
            return <div className="display-deactivate close-deactivate"> <VendorSuccessNotify notify={"KYC Vendor has been successfully reactivated"} handleReject={handleReject} setToggle={setToggle} /></div>
        }


    }

    function renderTable() {
        if (id === 0) {
            return null
        } else if (id === 1) {
            return <VendorTable parse={changeToggle} />
        } else {
            return <BillTable parse={changeToggle} />
        }
    }


    return (
        <div className="vendor-container">
            <div className="vendor-siderbar">
                <Nav/>
            </div>
            <div className="vendor-wrap right-content">
                <div className="">
                    <KYCTOP url="Dashboard/" suburl="vendor" />
                </div>
                <div className="vendor-compts">
                    <VendorCard getId={getId} />
                    {renderTable()}
                </div>

            </div>
            {handleToggle()}
        </div>
    )
}