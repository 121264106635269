import React, { forwardRef, useRef, useState } from "react";
import "../../css/upload-document/upload-document-main.css";
import ImageUpload from "../../img/ImageUpload.svg";
import DateIcon from "../../img/date.svg";
import axios from "../../axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const UploadDocumentMain = ({ id, cardType, closeDisplay, setToggle }) => {
  const file = useRef();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [selectedExpiry, setSelectedExpiry] = useState(null);
  const [fileName, setFileName] = useState("");
  const [noExpiry, setNoExpiry] = useState(false);
  const [idNo, setIDNo] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [idType, setIDType] = useState("")

  const onUpload = () => {
    file.current.click();
  };

  const onFileUpload = (e) => {
    setSelectedFile(e.target.files[0]);
    if (e.target.files[0]) {
      setFileName(e.target.files[0].name);
    }

    console.log(selectedFile, cardType);
  };

  const DateFormat = forwardRef(({ value, onClick }, ref) => (
    <>
      <div
        className="uploadDoc-card-body-input date"
        onClick={onClick}
        ref={ref}
      >
        {value || "DD-MM-YYYY"}
        <img
          src={DateIcon}
          loading="lazy"
          width="15"
          height="20"
          alt="date icon"
        />
      </div>
    </>
  ));

  const DateFormat2 = forwardRef(({ value, onClick }, ref) => (
    <>
      <div
        className="uploadDoc-card-body-input date"
        onClick={onClick}
        ref={ref}
      >
        {value || "DD-MM-YYYY"}
        <img
          src={DateIcon}
          loading="lazy"
          width="15"
          height="20"
          alt="date icon"
        />
      </div>
    </>
  ));

  const onSubmit = async () => {
    if (!noExpiry && selectedExpiry < selectedIssue) {
      setError("Expiry cannot be less than issue date");
      return;
    }
    setError("");
    setLoading(true);
    let body = new FormData();
    let type = cardType;
    let cardNo = "identity_card_number";
    let idCardType = "";
    console.log(type);
    if (
      cardType === "Business Registration Doc" ||
      cardType === "CAC Cert of Incorporation"
    ) {
      type = "cac document";
      cardNo = "business_registration_number";
    } else if (cardType === "ID Card" && idType === "driver-license") {
      type = "identity card";
      idCardType = "driver-licence";
    }else if (cardType === "ID Card" && idType === "national-identity-card") {
      type = "identity card";
      idCardType = "national-identity-card";
    } else {
      type = "profile picture";
      // idCardType = "national-identity-card";
    }

    body.append("upload_type", type);
    body.append("identity_card_type", idCardType);
    body.append("file", selectedFile);
    body.append(cardNo, idNo);
    body.append("issued_date", selectedIssue);
    if (!noExpiry) {
      body.append(
        "expiration_date",
        moment(selectedExpiry).format("YYYY-MM-DD")
      );
    } else {
      body.append("expiration_date", "");
    }
    body.append("_method", "patch");

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      await axios.post(`/user-profile/${id}/uploads`, body, config);
      setError("");

      setToggle(14);
      setLoading(false);
    } catch (err) {
      console.log(err.response);
      if (
        err.response.status === 500 ||
        err.response.status === 404 ||
        err.response.status === 422
      ) {
        setError(err.response.data.error.message);
        setTimeout(() => {
          setError("");
        }, 5000);
      } else {
        setError(err.response.data.error.message.join("\n"));
        setTimeout(() => {
          setError("");
        }, 5000);
      }
      setLoading(false);
    }
  };

  const modifyNoExpiry = (value) => {
    if (value) {
      setSelectedExpiry(null);
    }
    setNoExpiry(value);
  };

  return (
    <>
      {error && <span className="text-danger">{error}</span>}
      {cardType === "ID Card" && (
        <>
          <div
            style={{ marginLeft: "160px", marginTop: "25px" }}
            className="uploadDoc-card-content"
          >
            <label
              style={{ marginRight: "113px" }}
              className="uploadDoc-card-body-label"
              htmlFor=""
            >
              Card Type
            </label>
            <select
              style={{
                color: "#6A616F",
                height: "50px",
                width: "310px",
                padding: "10px",
                border: `1px solid rgba(0, 0, 0, 0.2)`,
                borderRadius: `10px`,
              }}
              value={idType}
              onChange={(e) => setIDType(e.target.value)}
            >
              <option value="driver-license">Driver's License</option>
              <option value="national-identity-card">National ID</option>
            </select>
          </div>
        </>
      )}

      <div className="uploadDocMain-container">
        <label className="uploadDocMain-label" style={{ marginRight: "85px" }}>
          Upload Image
        </label>

        <div className="uploadDocMain-input" onClick={onUpload}>
          <div style={{ marginLeft: "10px", marginRight: "10px" }}>
            <img
              src={ImageUpload}
              loading="lazy"
              width="35"
              height="35"
              alt="cancel icon"
            />
          </div>
          <div className="input-placeholder">
            {fileName === "" ? (
              "Click to select image"
            ) : (
              <div style={{ width: "250px" }}>{fileName}</div>
            )}
          </div>
          <input
            type="file"
            ref={file}
            style={{ display: "none" }}
            onChange={onFileUpload}
          ></input>
        </div>
      </div>
      {cardType !== "Passport" && (
        <>
          <div style={{ margin: "35px 83px 0px 83px", marginLeft: "155px" }}>
            <hr />
          </div>
          <div className="uplaodDocMain-details">Document Details</div>
          <div className="uploadDocMain-detailsInput">
            <div>
              <div className="detailsInput-label">
                {!(
                  cardType === "Business Registration Doc" ||
                  cardType === "CAC Cert of Incorporation"
                )
                  ? "ID Card"
                  : "Business Reg/CAC Cert"}
              </div>

              <input
                type="text"
                className="detailsInput-input"
                placeholder="Enter ID Card/Business Reg. No"
                value={idNo}
                onChange={(e) => setIDNo(e.target.value)}
              />
            </div>
            {!(
              cardType === "Business Registration Doc" ||
              cardType === "CAC Cert of Incorporation" ||
              cardType === "Passport"
            ) ? (
              <div className="detailsInput-date">
                <div style={{ marginRight: "70px" }}>
                  <div className="detailsInput-label">Issue Date</div>
                  <DatePicker
                    closeOnScroll={true}
                    selected={selectedIssue}
                    onChange={(date) => setSelectedIssue(date)}
                    isClearable
                    scrollableYearDropdown={true}
                    yearDropdownItemNumber={100}
                    showYearDropdown
                    customInput={<DateFormat />}
                  />
                </div>

                <div>
                  <div style={{ marginRight: "70px" }}>
                    <div className="detailsInput-label">Expiry Date</div>
                    <DatePicker
                      closeOnScroll={true}
                      selected={selectedExpiry}
                      onChange={(date) => setSelectedExpiry(date)}
                      isClearable
                      scrollableYearDropdown={true}
                      yearDropdownItemNumber={100}
                      showYearDropdown
                      customInput={<DateFormat2 />}
                    />
                  </div>
                </div>
                <div>
                  <div className="detailsInput-label"></div>
                  <div style={{ marginTop: "40px" }}>
                    <input
                      type="radio"
                      checked={noExpiry}
                      onClick={() => modifyNoExpiry(!noExpiry)}
                    />{" "}
                    No Expiry Date
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </>
      )}

      <div className="uploadDocMain-footer">
        <button
          className="uploadDocMain-footer-botton"
          onClick={onSubmit}
          disabled={loading}
        >
          {loading ? "Loading..." : "Upload"}
        </button>
        <button className="uploadDocMain-close" onClick={closeDisplay}>
          Close
        </button>
      </div>
    </>
  );
};

export default UploadDocumentMain;
