// import Sidebar from "../../components/dashboard/sidebar/sidebar";
import Card from "../../components/card/card";
import AddressVerificationView from "../../components/modals/addressVerificationView";
import KYCTOP from "../../components/kyc-top/kyc-top";
import { useEffect, useState } from "react";
import Nav from "../../components/nav";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { KYC } from "../../state/actions";

export default function Kyc() {
  const [text, setText] = useState("");
  const [hidden, setHidden] = useState({
    display: "none",
  });
  const [userID, setUserID] = useState("");

  const dispatch = useDispatch();

  const {
    FetchKYCLevelOne,
    FetchKYCLevelTwo,
    FetchPasswordVerification,
    FetchPinVerification,
    FetchKYCLevelZero,
    FetchAgency
  } = bindActionCreators(KYC, dispatch);
  useEffect(() => {
    FetchKYCLevelZero();
    FetchKYCLevelOne();
    FetchAgency();
    FetchKYCLevelTwo();
    FetchPasswordVerification();
    FetchPinVerification();
    

    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <div style={hidden}>
        <AddressVerificationView setHidden={setHidden} userID={userID} />
      </div>
      <div className="gen-cont">
        <div>
          <Nav Active={2} />
        </div>
        <div className="right-content">
          <KYCTOP url="KYC" suburl={text} />
          <div className="pending-verification">Pending Verifications</div>
          <Card setText={setText} setHidden={setHidden} setUserID={setUserID} />
        </div>
      </div>
    </div>
  );
}
