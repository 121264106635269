import Cancel from "../btn/cancel";
import "../../css/support/transaction-history.css";
import { SupportProfileSearchInput } from "../input/input";
import kimg from "../../img/ksearch.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { SupportActions } from "../../state/actions";
import Pagination from "../footer-pagination";
import Moment from "react-moment";
export default function TransactionHistory({ setToggle, id }) {
  // const [close, setClose] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");

  const handleClick = () => {
    // setClose("close");
    setToggle(0);
  };
  const {
    userTransactionHistory,
    searchuserTransactionHistoryMeta,
    searchuserTransactionHistoryData,
  } = useSelector((state) => state.support);
  const dispatch = useDispatch();
  const { SearchUserTransactionHistory, FetchUserTransactionHistory } =
    bindActionCreators(SupportActions, dispatch);

  useEffect(() => {
    if (search.trim() === "") {
      FetchUserTransactionHistory(id, currentPage);
    } else {
      SearchUserTransactionHistory(id, search, currentPage);
    }

    // eslint-disable-next-line
  }, [currentPage]);

  const { per_page, total } = searchuserTransactionHistoryMeta
    ? searchuserTransactionHistoryMeta
    : userTransactionHistory.transactions.meta;

  let userData =
    searchuserTransactionHistoryData !== null
      ? searchuserTransactionHistoryData
      : userTransactionHistory.transactions.data;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="transaction-history-container">
      <div className="transaction-history-content">
        <div className={`login-history-cancel`} onClick={handleClick}>
          <Cancel />
        </div>
        <p className="login-history-title">Transaction History</p>
        <div className="transaction-history-summary">
          <div>
            <p>Cash Requests</p>
            <h1>
              {userTransactionHistory &&
                userTransactionHistory.cash_request_count}
            </h1>
          </div>
          <div>
            <p>Cash Delivery</p>
            <h1>
              {userTransactionHistory &&
                userTransactionHistory.cash_deliveries_count}
            </h1>
          </div>
          <div>
            <p>Bills Transaction</p>
            <h1>
              {userTransactionHistory && userTransactionHistory.bills_count}
            </h1>
          </div>
        </div>
        <div className="transaction-history-search">
          <div className="kscont transaction-history-search">
            <SupportProfileSearchInput
              label="Search Records"
              searchValue={SearchUserTransactionHistory}
              id={id}
              search={search}
              setSearch={setSearch}
            />
            <img src={kimg} alt="" />
          </div>
        </div>

        <div style={{ height: "350px", overflow: "auto" }}>
          <table className="user-login-history-table">
            <tr>
              <th>Time</th>
              <th>Transaction type</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Beneficiary</th>
            </tr>

            {userData.map((item, idx) => (
              <tr key={idx} className="item-tr history-items">
                <td>{item.created_at && <Moment format="DD/MM/YYY - HH:mm">{item.created_at}</Moment>}</td>
                <td>{(item.model_type && (item.model_type.split("\\")[2])) || "-"}</td>
                <td>{item.amount && item.amount}</td>
                <td>{(item.status && item.status) || "-"}</td>
                <td>{(item.beneficiary && item.beneficiary.name) || "-"}</td>
              </tr>
            ))}
          </table>
        </div>
        {userData.length > 0 && (
          <Pagination
            className="users-pagination"
            currentPage={currentPage}
            totalCount={total}
            pageSize={per_page}
            onPageChange={onPageChange}
          />
        )}
        <div className="transaction-history-button-section">
          <button className="transaction-history-close">Print statement</button>
        </div>
      </div>
    </div>
  );
}
