import React from "react";
import "../../../css/user-control/role-delete.css";
import CloseBtn from "../../../img/Close-Square.svg";
import ProfileActivate from "../../../img/profileactivate.svg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { AppContentActions } from "../../../state/actions";


function EmailDeleteModal({ value, content }) {
// const [loading, setLoading] = useState(false)

const {
  emailCreateLoading, activeID
} = useSelector((state) => state.appContent);

const dispatch = useDispatch();
const { deleteEmailNotification, setToggle } =
  bindActionCreators(AppContentActions, dispatch);

  const onDelete =  () => {
    deleteEmailNotification(value, activeID)
  }

  let close = "";
  const [display, setDisplay] = useState("");
  const closeDisplay = () => {
    if (close === null) {
      setDisplay("");
    //   setError({})
    setToggle(0);
    } else {
      setDisplay("close");
    //   setError({})
    setToggle(0);
    }
  };

  return (
    <>
      <div className={`display-details ${display}`}>
        <div class={`role-delete `}>
          <div onClick={closeDisplay}>
            <strong class="role-delete-bold-text">Cancel</strong>
          </div>
          <div class="role-delete-flexedcont">
            <img
              src={ProfileActivate}
              loading="lazy"
              width="63"
              height="63"
              alt=""
              class="role-delete-image"
            />
            <div class="role-delete-text-block-2">
              {content}
            </div>

              <button
                className="role-delete-button role-delete-w-button"
                onClick={onDelete}
                disabled={emailCreateLoading}
              >
                <strong>Delete</strong>
              </button>
            {/* {error.roleErr ? null : (
            )} */}
          </div>
          <img
            src={CloseBtn}
            loading="lazy"
            width="24"
            height="24"
            alt=""
            class="role-delete-image-2"
            onClick={closeDisplay}
          />
        </div>
      </div>
    </>
  );
}

export default EmailDeleteModal;
