import "../css/reasign-ticket.css";
import closeBtn from "../img/Close-Square.svg";
// import chevDown from "../img/chevdown.png";
// import DropComp from "./drop-comp";
import { useEffect, useState } from "react";

import axios from "../axios";
import { useSelector } from "react-redux";

export default function ReasignTicket({ setToggle, id, setTicketID }) {
  const handleClick = () => {
    setToggle(0);
  };

  const { internalUser } = useSelector((state) => state.support);

  const [ticket, setTicket] = useState(null);
  const [user, setUser] = useState("");
  const [priority, setPriority] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`/tickets/${id}`);
        setTicket(res.data.data.ticket);
        setUser(res.data.data.ticket.internal_user.id)
        setPriority(res.data.data.ticket.priority_level)
      } catch (err) {}
    })();

    // eslint-disable-next-line
  }, []);

  const reAssign = async () => {
    setLoading(true);
    try {
      await axios.patch(`/tickets/${id}`, {
        internal_user_id: user,
        priority_level: priority,
        status: "pending",
      });
      // console.log(res.data.data.internal_users)
      setTicketID(Math.random())
      setToggle(4);
    } catch (err) {
      if (err.response.status === 500 || err.response.status === 404) {
        setError(err.response.data.error.message);
      } else {
        setError(err.response.data.error.message.join("\n"));
      }
    }
  };

  return (
    <>
      <div className="re-ticketContainer">
        <div className="re-ticketCont">
          <div className="house">
            <h6>Reasign Ticket: {ticket && ticket.ticket_reference}</h6>
            <img onClick={handleClick} src={closeBtn} alt="" />
          </div>
          <div className="retextdiv">
            <h6>
              currently assigned to -{" "}
              <span>
                {ticket && ticket.internal_user && ticket.internal_user.name}
              </span>
            </h6>
          </div>
          {error && <span>{error}</span>}
          <div className="" style={{ marginTop: "30px", marginBottom: "20px" }}>
            <div style={{ marginBottom: "17px" }}>
              <h6>Escalate to</h6>
              <div className="escalate-checkbox" style={{ width: "50%" }}>
                <select
                  className="form-control"
                  style={{}}
                  value={user}
                  onChange={(e) => setUser(e.target.value)}
                >
                  <option value="">Select Agent</option>
                  {internalUser.map((user) => (
                    <option value={user.id}>{user.name}</option>
                  ))}
                </select>
                {/* {error.user && <span className="text-danger">{error.user}</span>} */}
              </div>
            </div>
            <div
              className=""
              style={{ marginTop: "30px", marginBottom: "20px" }}
            >
              <h6>Priority Level</h6>
              <div className="escalate-checkbox" style={{ width: "50%" }}>
                <select
                  className="form-control"
                  value={priority}
                  onChange={(e) => setPriority(e.target.value)}
                >
                  <option value="low">Low</option>
                  <option value="medium">Medium</option>
                  <option value="high">High</option>
                </select>
                {/* {error.user && <span className="text-danger">{error.user}</span>} */}
              </div>
            </div>
            <div className="reticketbtngtoup">
              {loading ? (
                <button className="btn-default">Loading...</button>
              ) : (
                <button onClick={reAssign}>Reasign</button>
              )}

              <button onClick={() => setToggle(0)}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
