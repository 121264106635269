  // import Item from "antd/lib/list/Item"
import KycInput from "../../components/input/input"
// import VendorModalUserAction2 from "../../components/vendorModals/vendorModal2"
import kimg from "../../img/ksearch.svg"
import BillsDropdown from "./VendorDropdown"
// import { useState } from "react"

export default function BillTable({ parse }) {
    // const [toggle, setToggle] = useState(false)
    const billData = [
        {
            id: 1,
            name: "Vendor Name 1",
            transaction: 43,
            services: 4,
            status: "Active",
        },
        {
            id: 2,
            name: "Vendor Name 1",
            transaction: 21,
            services: 5,
            status: "Active",
        },
        {
            id: 3,
            name: "Vendor Name 1",
            transaction: 45,
            services: 6,
            status: "Active",
        },
        {
            id: 4,
            name: "Vendor Name 1",
            transaction: 32,
            services: 7,
            status: "Active",
        }
    ]

    // const changeToggle = (value) => {
    //     setToggle(!toggle)
    // }

    return (
        <>
            <div className="vendor-table">
                <div className="search-section2">

                    <div className="kscont"><KycInput label="Search Records" /><img src={kimg} alt="" /></div>
                </div>
                <div className="vtable-wrapper">

                    <div className="vendor-table-content">
                        <table className="vendor-table-main">
                            <tr>
                                <th></th>
                                <th>Vendor Name</th>
                                <th>Bills Transactions</th>
                                <th>Bills services Provided</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                            {billData.map((item) => (
                                <tr className="item-tr">
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>{item.transaction}</td>
                                    <td>{item.services}</td>
                                    <td className="green">{item.status}</td>
                                    <td>
                                    {<BillsDropdown acceptToggle={parse}/>}
                                    </td>
                                </tr>
                            ))}
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}