import { useState, useEffect } from "react";

import "../../../css/kyc/agency-kyc-table.css";
import { KycLevelsInput } from "../../input/input";
import kimg from "../../../img/ksearch.svg";
import { useNavigate } from "react-router-dom";

import Pagination from "../../footer-pagination";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { KYC } from "../../../state/actions";


export default function AgencyKycTable() {
  const [currentPage, setCurrentPage] = useState(1);
  // const [pageSize, setPageSize] = useState(3);

  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const {
    kycAgency,
    kycAgencyMeta,
    // kycLevelTwo,
    // kycLevelTwoMeta,
    levelTwoLoading,
    searchKycAgencyData,
    searchKycAgencyMeta,
  } = useSelector((state) => state.kyc);

  const dispatch = useDispatch();
  const { 
    searchKycAgency, 
    // FetchKYCLevelTwo, 
    FetchAgency } = bindActionCreators(
    KYC,
    dispatch
  );

  const navigatePage = (id) => {
    const newId = btoa(id);
    navigate(`/kyc/agency/${newId}`);
  };

  useEffect(() => {
    if (search.trim() === "") {
      FetchAgency(currentPage);
    } else {
      searchKycAgency(search, currentPage);
    }

    //eslint-disable-next-line
  }, [currentPage]);

  const { per_page, total } = searchKycAgencyMeta
    ? searchKycAgencyMeta
    : kycAgencyMeta;

  let userData = searchKycAgencyData !== null ? searchKycAgencyData : kycAgency;


  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="kyc-cont1">
        <div className="kyc-search-cont">
          <div className="kscont">
          <KycLevelsInput
              label="Search Records"
              searchValue={searchKycAgency}
              search={search}
              setSearch={setSearch}
            />
            <img src={kimg} alt="" />
          </div>
        </div>
        <div className="kyc-table-cont">
          <table className="kyc-level-table">
            <tr style={{ display: "fixed" }}>
              <th>#</th>
              <th>Profile Name</th>
              <th>BVN</th>
              <th>Phone Number</th>
            </tr>
            {userData.map((item, idx) => (
              <tr className="kyc-table-tr">
                <th>{(currentPage - 1) * per_page + 1 + idx}</th>
                <th>{item.name || "-"}</th>
                <th>{(item.bvn && item.bvn.bvn_number) || "-"}</th>
                <th>{item.telephone || "-"}</th>
                <td>
                  <button
                    onClick={() => navigatePage(item.id)}
                    className="kyc2-kbtn"
                    style={{ marginRight: "-10px" }}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
            {userData.length === 0 ? (
              <td colSpan={5} className="text-danger">
                No record found
              </td>
            ) : null}
          </table>
        </div>
        {!levelTwoLoading && (
          <Pagination
            className="users-pagination"
            currentPage={currentPage}
            totalCount={total}
            pageSize={per_page}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </>
  );
}
