import { combineReducers } from "redux";
import KYCReduce from "./kycReducer";
import reducer from "./authReducer";
import dashboardReducer from "./dashboardReducers";
import userControlReducer from "./userControlReducers";
import settlementReducer from "./settlementReducer";
import appContentReducer from "./appContentReducer";
import supportReducer from "./supportReducer";

const reducers = combineReducers({
  auth: reducer,
  dashboard: dashboardReducer,
  userControl: userControlReducer,
  kyc: KYCReduce,
  settlement: settlementReducer,
  appContent: appContentReducer,
  support: supportReducer,
});

export default reducers;
