import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"

import Cookies from "js-cookie"
import * as types from "./state/types"

const Login2PrivateRoute = ({ children }) => {
    const token = Cookies.get(btoa(types.TOKEN))

    const { user } = useSelector((state) => state.auth)
    if (token) {
        return <Navigate to='/' />
    }
    if (user === null) {
        return <Navigate to='/login' />
    }

    return children
}

export default Login2PrivateRoute
