import "../../../css/kyc-img.css";
import working_late from "../../../img/Working-Late.png";

function Kycimg() {
  return (
    <div className="kyc-img-container">
      <div className="kyc-img">
        <img height="200px" src={working_late} alt="kyc-img" />
      </div>
      <div className="kyc-para">Select a customer bucket to work on</div>
    </div>
  );
}

export default Kycimg;
