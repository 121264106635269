import { useState, useEffect } from "react";

import "../../../css/kyc/kyc-table.css";
import { KycLevelsInput } from "../../input/input";
import kimg from "../../../img/ksearch.svg";

import Pagination from "../../footer-pagination";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { KYC } from "../../../state/actions";
import Moment from "react-moment";

export default function KycTable2({ setHidden, setUserID }) {
  const [currentPage, setCurrentPage] = useState(1);
  // const [pageSize, setPageSize] = useState(3);

  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const { searchKycVerification, FetchKYCLevelTwo } = bindActionCreators(
    KYC,
    dispatch
  );

  const {
    kycLevelTwo,
    kycLevelTwoMeta,
    levelTwoLoading,
    searchKycVerificationData,
    searchKycVerificationMeta,
  } = useSelector((state) => state.kyc);

  useEffect(() => {
    if (search.trim() === "") {
      FetchKYCLevelTwo(currentPage);
    } else {
      searchKycVerification(search, currentPage);
    }

    //eslint-disable-next-line
  }, [currentPage]);

  const { per_page, total } = searchKycVerificationMeta
    ? searchKycVerificationMeta
    : kycLevelTwoMeta;

  let userData = searchKycVerificationData !== null ? searchKycVerificationData : kycLevelTwo;

  const showModal = (id) => {
    setUserID(id);
    setHidden({ display: "flex" });
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="kyc-cont1">
        <div className="kyc-search-cont">
          {/* <div className="sdrop">
            <label>Page</label>
            <div class="custom-select">
              <select
                value={pageSize}
                onChange={(e) => setPageSize(e.target.value)}
              >
                <option value={3}>3</option>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
              </select>
            </div>
          </div> */}
          <div className="kscont">
          <KycLevelsInput
              label="Search Records"
              searchValue={searchKycVerification}
              search={search}
              setSearch={setSearch}
            />
            <img src={kimg} alt="" />
          </div>
        </div>
        <div className="kyc-table-cont">
          <table className="kyc-level-table">
            <tr style={{ display: "fixed" }}>
              <th>#</th>
              <th>Profile Name</th>
              <th>Phone Number</th>
              <th>Date Assigned</th>
              <th>Vendor</th>
            </tr>
            {userData.map((item, idx) => (
              <tr className="kyc-table-tr">
                <th>{(currentPage - 1) * per_page + 1 + idx}</th>
                <th>{item.name}</th>
                <th>{item.telephone}</th>
                <th>
                  <Moment format="DD/MM/YYYY">{item.created_at}</Moment>
                </th>
                <th>{item.vendor || "-"}</th>
                <td>
                  <button
                    onClick={() => showModal(item.id)}
                    className="kyc2-kbtn"
                    style={{ marginRight: "-10px" }}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
            {userData.length === 0 ? (
              <td colSpan={5} className="text-danger">
                No record found
              </td>
            ) : null}
          </table>
        </div>
        {!levelTwoLoading && (
          <Pagination
            className="users-pagination"
            currentPage={currentPage}
            totalCount={total}
            pageSize={per_page}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </>
  );
}
