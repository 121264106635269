import "../../css/modal/mfCreationRed.css";
import CloseBtn from "../../img/Close-Square.svg";
// import Completed from "../../img/Close-Square.svg";
import { useState } from "react";

export default function MfRed({
  setView,
  setModal,
  handleSubmit,
  setComment,
  loading,
}) {
  const [display, setDisplay] = useState("");
  const closeDisplay = () => {
    setView("hidden");
    setModal(0);
    setComment("");
    setDisplay("close");
  };
  console.log(loading)
  return (
    <>
      <div class={`modbox ${display}`}>
        <div onClick={closeDisplay}>
          <strong class="bold-text">Cancel</strong>
        </div>
        <div class="flexedcont">
          <MfRedRejectIcon />
          <div class="text-block-2">
            Are you sure want to reject this customer`s selfie
          </div>
          <button className="reject-button w-button" disabled={loading} onClick={handleSubmit}>
            <strong>Reject</strong>
          </button>
        </div>
        <img
          src={CloseBtn}
          loading="lazy"
          width="24"
          height="24"
          alt=""
          class="image-2"
          onClick={closeDisplay}
        />
      </div>
    </>
  );
}

function MfRedRejectIcon() {
  return (
    <svg
      width="53"
      height="57"
      viewBox="0 0 53 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.375 33.75H26.5"
        stroke="#162082"
        stroke-width="4"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.375 12.645L3.53125 2.80127"
        stroke="#FC3131"
        stroke-width="4"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.27 2.90625L3.42627 12.75"
        stroke="#FC3131"
        stroke-width="4"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.375 23.25H34.375"
        stroke="#162082"
        stroke-width="4"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.25 2.25H37C45.7412 2.7225 50.125 5.95125 50.125 17.9737V39"
        stroke="#162082"
        stroke-width="4"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.875 20.6514V38.9476C2.875 49.4739 5.5 54.7501 18.625 54.7501H26.5C26.9463 54.7501 33.955 54.7501 34.375 54.7501"
        stroke="#162082"
        stroke-width="4"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M50.125 39L34.375 54.75V46.875C34.375 41.625 37 39 42.25 39H50.125Z"
        stroke="#162082"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
