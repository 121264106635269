import axios from "../../axios";
import * as types from "../types";

export const setLoading = () => (dispatch) => {
  dispatch({
    type: types.SET_KYC_LOADING,
  });
};

const parseError = (err) => {
  if (err.response.status === 500 || err.response.status === 404) {
    return err.response.data.error.message;
  } else {
    return err.response.data.error.message.join("\n");
  }
};

export const searchUsers = (search, page) => async (dispatch) => {
  page = page || 1;
  setLoading();
  try {
    const res = await axios.get(
      `/user-profile?term=${search.trim()}&page%5Bnumber%5D=${page}?include=bvn`
    );
    dispatch({
      type: types.PROFILE_SEARCH,
      payload: res.data.data.users.data,
      meta: res.data.data.users.meta,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: types.PROFILE_SEARCH_ERROR,
      payload: { top: error.response.data.error.message },
    });
  }
};

export const FetchUserProfile = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/user-profile/${id}?include=account,kyc,agent`
    );
    dispatch({
      type: types.FETCH_USER_PROFILE,
      payload: res.data.data.user,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: types.PROFILE_SEARCH_ERROR,
      payload: { top: error.response.data.error.message },
    });
  }
};

export const FetchUserLoginHistory = (id, page) => async (dispatch) => {
  page = page || 1;
  try {
    const res = await axios.get(
      `/users/${id}/login-history?page%5Bnumber%5D=${page}`
    );
    dispatch({
      type: types.FETCH_USER_LOGIN_HISTORY,
      payload: res.data.data.users.data,
      meta: res.data.data.users.meta,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: types.PROFILE_SEARCH_ERROR,
      payload: { top: error.response.data.error.message },
    });
  }
};

export const CreateUserProfileView = (userID, adminID) => async (dispatch) => {
  try {
    const res = await axios.post(`/profile-view`, {
      admin: adminID,
      user: userID,
    });
    dispatch({
      type: types.CREATE_USER_PROFILE_VIEW,
      payload: res.data.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: types.PROFILE_SEARCH_ERROR,
      payload: { top: error.response.data.error.message },
    });
  }
};

export const FetchUserProfileView = (id, page) => async (dispatch) => {
  page = page || 1;
  try {
    const res = await axios.get(`/profile-view/${id}?page%5Bnumber%5D=${page}`);
    dispatch({
      type: types.FETCH_USER_PROFILE_VIEW_HISTORY,
      payload: res.data.data.profile_view.data,
      meta: res.data.data.profile_view.meta,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: types.PROFILE_SEARCH_ERROR,
      payload: { top: error.response.data.error.message },
    });
  }
};

export const FetchUserTransactionHistory = (id, page) => async (dispatch) => {
  page = page || 1;
  try {
    const res = await axios.get(
      `/user-profile/${id}/transactions?include=beneficiary&page%5Bnumber%5D=${page}`
    );
    dispatch({
      type: types.FETCH_USER_TRANSACTION_HISTORY,
      payload: res.data.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: types.PROFILE_SEARCH_ERROR,
      payload: { top: error.response.data.error.message },
    });
  }
};

export const SearchUserTransactionHistory =
  (id, search, page) => async (dispatch) => {
    setLoading();
    page = page || 1;
    try {
      const res = await axios.get(
        `/user-profile/${id}/transactions?filter[term]=${search.trim()}&page%5Bnumber%5D=${page}&include=beneficiary`
      );
      dispatch({
        type: types.SEARCH_USER_TRANSACTION_HISTORY,
        payload: res.data.data.transactions.data,
        meta: res.data.data.transactions.meta,
      });
    } catch (err) {
      dispatch({
        type: types.PROFILE_SEARCH_ERROR,
        payload: { top: err.response.data.error.message },
      });
    }
  };

export const DeactivateUser = (id, comment, toggle) => async (dispatch) => {
  setLoading();

  try {
    await axios.patch(`/users/${id}/status`, {
      status: "inactive",
      comment,
    });

    dispatch({
      type: types.DEACTIVATE_USER,
      toggle: toggle,
      comment: comment,
    });
    return;
  } catch (err) {
    dispatch({
      type: types.DEACTIVATE_USER_ERROR,
      payload: { active: parseError(err) },
    });
    return;
  }
};

export const ReactivateUser = (id, toggle) => async (dispatch) => {
  setLoading();
  try {
    await axios.patch(`/users/${id}/status`, {
      status: "active",
    });
    dispatch({
      type: types.REACTIVATE_USER,
      toggle: toggle,
    });
    return;
  } catch (err) {
    dispatch({
      type: types.REACTIVATE_USER_ERROR,
      payload: { active: parseError(err) },
    });
    return;
  }
};

export const GetDocumentStatus = (id) => async (dispatch) => {
  setLoading();
  try {
    const res = await axios.get(`/users/${id}/verifications`, {
      status: "active",
    });
    dispatch({
      type: types.GET_DOCUMENT_STATUS,
      payload: res.data.data.Verifications,
    });
    return;
  } catch (err) {
    dispatch({
      type: types.SUPPORT_ERROR,
      payload: { active: parseError(err) },
    });
    return;
  }
};

export const FetchDocumentHistory = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/kycs/${id}?include=kyc,verifications`, {
      status: "active",
    });
    dispatch({
      type: types.FETCH_DOCUMENT_HISTORY,
      payload: res.data.data,
    });
    return;
  } catch (err) {
    dispatch({
      type: types.SUPPORT_ERROR,
      payload: { active: parseError(err) },
    });
    return;
  }
};

export const setSupportSearchToggle = (value) => (dispatch) => {
  dispatch({
    type: types.SET_TOGGLE,
    payload: value,
  });
};

export const setStatus = (value, comment) => (dispatch) => {
  dispatch({
    type: types.SET_STATUS,
    payload: value,
    comment: comment,
  });
};

export const FetchSupportTicket = (page) => async (dispatch) => {
  page = page || 1;
  try {
    const res = await axios.get(`/tickets?page%5Bnumber%5D=${page}`);
    dispatch({
      type: types.FETCH_SUPPORT_TICKET,
      payload: res.data.data.tickets.data,
      meta: res.data.data.tickets.meta,
    });
    return;
  } catch (err) {
    dispatch({
      type: types.SUPPORT_ERROR,
      payload: { active: parseError(err) },
    });
    return;
  }
};

export const SearchSupportTickets = (search, page) => async (dispatch) => {
  page = page || 1;
  try {
    const res = await axios.get(
      `/tickets?page%5Bnumber%5D=${page}?term=${search}`
    );
    dispatch({
      type: types.SEARCH_SUPPORT_TICKET,
      payload: res.data.data.tickets.data,
      meta: res.data.data.tickets.meta,
    });
  } catch (err) {
    dispatch({
      type: types.SUPPORT_ERROR,
      payload: { top: err.response.data.error.message },
    });
  }
};

export const GetInternalUser = (phone) => async (dispatch) => {
  try {
    const res = await axios.get(`/tickets/internal-user`);
    // console.log(res.data.data.internal_users)
    dispatch({
      type: types.GET_INTERNAL_USER,
      payload: res.data.data.internal_users,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: types.SUPPORT_ERROR,
      payload: { top: err.response.data.error.message },
    });
  }
};

export const CreateTicket =
  (phone, title, description, escalateTo, priority) => async (dispatch) => {
    try {
      const res = await axios.post(`/tickets`, {
        customer_telephone: phone,
        internal_user_id: escalateTo,
        priority_level: priority,
        title: title,
        description: description,
      });
      // console.log(res.data.data.internal_users)
      dispatch({
        type: types.CREATE_TICKET,
        payload: res.data.data.ticket,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: types.SUPPORT_CREATE_TICKET_ERROR,
        payload: { createTicket: parseError(err) },
      });
    }
  };
