import { useEffect, useState } from "react";
import "../../css/modal/addressVerificationView.css";
import CloseBtn from "../../img/Close-Square.svg";

import axios from "../../axios"
import Moment from "react-moment";

function AddressVerificationView({ setHidden, userID }) {
  const [data, setData] = useState({})
  const [disableButton, setDisable] = useState(false)
  const [id, setId] = useState("")
  const [random, setRandom] = useState(Math.random())
  const [status, setStatus] = useState("")
  const [error, setError] = useState("")
  // const [verifications, setVerifications] = useState([])

  const CloseModal = () => {
    setError("")
    setDisable(false)
    setHidden({ display: "none" });
  };

  const filterAgentVerification = (data) => {
    const verification = data.filter(
      (v) => v.verification_type === "business address verification"
    );
    return verification;
  };

  useEffect(() => {
    (async function fetchuser() {
      try {
        // const newId = atob(id);
        const res = await axios.get(`/kycs/${userID}?include=verifications,kyc,agent`);
        // console.log(res.data.data);
        setData(res.data.data.user);
        // setVerifications(res.data.data.user.verifications);
        const v = filterAgentVerification(res.data.data.user.verifications)
        if (v.length > 0) {
          setId(v[0].id)
          setStatus(v[0].status)
        } else {
          setDisable(true)
        }
      } catch (error) {
        console.log(error);
      }
    })()
    
    //eslint-disable-next-line
  }, [userID, random]);

  const markAsVerified = async () => {
    try {
      await axios.patch(`/verifications/${id}`, {
        status: "approved",
      })
      CloseModal()
      setRandom(Math.random())
    } catch (err) {
      setError("OOPS!, something went wrong, please try again")
    }
  }
  // const UnVerifyAddress = async () => {
  //   try {
  //     await axios.patch(`/verifications/${id}`, {
  //       status: "rejected",
  //     })
  //     CloseModal()
  //   } catch (err) {
  //     setError("OOPS!, something went wrong, please try again")
  //   }
  // }
  return (
    <div className="address-verification-container">
      <div className="address-verification-view-container">
        <div className="address-header">
          <div className="address-verification">
            <h5>Address Verification Details</h5>
          </div>
        </div>
        
        <div className="cancel-icon" onClick={CloseModal}>
          <img
            src={CloseBtn}
            loading="lazy"
            width="24"
            height="24"
            alt="cancel icon"
          />
        </div>
        {error && (<span className="text-danger">{error}</span>)}
        <div className="address-verification-view-contents">
        
          <div className="address-verification-view-content">
            <div className="address-content">
              <h5>Full Name</h5>
              <p>{(data.agent && data.agent.business_name) || (data.name || "-")}</p>
            </div>
            <div className="address-content">
              <h5>Phone Number</h5>
              <p>{data.telephone}</p>
            </div>
            <div className="address-content">
              <h5>Email</h5>
              <p>{data.email}</p>
            </div>
            <div className="address-content">
              <h5>BVN</h5>
              <p>{(data.bvn && data.bvn.bvn_number) || "-"}</p>
            </div>
            <div className="address-content">
              <h5>Residential Address</h5>
              <p>
              {(data.agent && data.agent.business_address) || "-"}
              </p>
            </div>
            <div className="address-content">
              <h5>Date Assigned</h5>
              <p>{(data.agent && (<Moment format="MMMM DD, YYYY HH:mm">{data.agent.updated_at}</Moment>)) || "-"}</p>
            </div>
          </div>

        <div>
          {(status === "pending" && <button className="vendor-mark-btn" disabled={disableButton} onClick={markAsVerified} >Mark Address as verified</button>) || <button className="vendor-mark-btn"  >Verified</button>}
          
        </div>
          {/* <button className="vendor-reassign-btn">Vendor Reassign</button> */}
        </div>
      </div>
    </div>
  );
}

export default AddressVerificationView;
