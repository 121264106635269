export default function DashBtndark() {
  return (
    <svg
      width="35"
      height="45"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.4167 24.9792V9.39587C50.4167 5.95837 48.95 4.58337 45.3063 4.58337H36.0479C32.4042 4.58337 30.9375 5.95837 30.9375 9.39587V24.9792C30.9375 28.4167 32.4042 29.7917 36.0479 29.7917H45.3063C48.95 29.7917 50.4167 28.4167 50.4167 24.9792Z"
        fill="#162082"
        stroke="#162082"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M50.4167 45.6041V41.4791C50.4167 38.0416 48.95 36.6666 45.3063 36.6666H36.0479C32.4042 36.6666 30.9375 38.0416 30.9375 41.4791V45.6041C30.9375 49.0416 32.4042 50.4166 36.0479 50.4166H45.3063C48.95 50.4166 50.4167 49.0416 50.4167 45.6041Z"
        fill="#162082"
        stroke="#162082"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.0622 30.0209V45.6042C24.0622 49.0417 22.5955 50.4167 18.9518 50.4167H9.69342C6.04967 50.4167 4.58301 49.0417 4.58301 45.6042V30.0209C4.58301 26.5834 6.04967 25.2084 9.69342 25.2084H18.9518C22.5955 25.2084 24.0622 26.5834 24.0622 30.0209Z"
        fill="#162082"
        stroke="#162082"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.0622 9.39587V13.5209C24.0622 16.9584 22.5955 18.3334 18.9518 18.3334H9.69342C6.04967 18.3334 4.58301 16.9584 4.58301 13.5209V9.39587C4.58301 5.95837 6.04967 4.58337 9.69342 4.58337H18.9518C22.5955 4.58337 24.0622 5.95837 24.0622 9.39587Z"
        fill="#162082"
        stroke="#162082"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
