// import "./css/support-dropdown.css"
function SupportDropDown({ setToggle, id, setID }) {
  const changeToggle = (value) => {
    setToggle(value);
    if (value === 0) {
      setID("");
    } else {
      setID(id);
    }
  };
  return (
    <div className="btn-group">
      <button
        type="button"
        className="btn btn-default"
        style={{
          width: "35px",
          height: "35px",
          backgroundColor: "darkblue",
          borderRadius: "100%",
          color: "white",
        }}
        data-toggle="dropdown"
        aria-expanded="false"
      >
        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
      </button>
      <div className="dropdown-menu">
        {/* <span
          style={{ cursor: "pointer" }}
          className="dropdown-item"
          onClick={() => {
            changeToggle(6);
          }}
        >
          Update Ticket
        </span>
        <div class="dropdown-divider"></div> */}
        <span
          style={{ cursor: "pointer" }}
          className="dropdown-item"
          onClick={() => {
            changeToggle(1);
          }}
        >
          View Ticket
        </span>
        <div class="dropdown-divider"></div>
        <span
          style={{ cursor: "pointer" }}
          className="dropdown-item"
          onClick={() => {
            changeToggle(2);
          }}
        >
          View Comments
        </span>
        <div class="dropdown-divider"></div>
        <span
          style={{ cursor: "pointer" }}
          className="dropdown-item"
          onClick={() => {
            changeToggle(3);
          }}
        >
          Re-assign
        </span>
      </div>
    </div>
  );
}

export default SupportDropDown;
