export default function AppContentBtn(){
    return (
<svg width="35" height="40" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" d="M22.2167 45.1897L22.2163 45.1893C21.3089 43.9794 20.0477 43.3085 18.7326 43.2369C17.4175 43.1653 16.0908 43.6953 15.0573 44.8041C14.072 45.8562 13.157 46.5286 12.3572 46.8726C11.5621 47.2146 10.9109 47.2217 10.3982 47.0009C9.88216 46.7786 9.41391 46.2855 9.06975 45.4403C8.7249 44.5934 8.52051 43.4268 8.52051 41.9372V16.133C8.52051 11.5077 9.07217 8.8112 10.5455 7.23632C12.0078 5.67329 14.5069 5.08301 18.8372 5.08301H36.1622C40.4926 5.08301 42.9914 5.67338 44.4507 7.23599C45.9209 8.81038 46.4674 11.5064 46.4559 16.1318V16.133V41.9601C46.4559 43.4441 46.2529 44.6081 45.9097 45.4538C45.5671 46.2978 45.1008 46.7905 44.5864 47.0125C44.0757 47.2329 43.4256 47.2262 42.6293 46.8828C41.8283 46.5374 40.9104 45.8621 39.9191 44.804C38.8856 43.6952 37.5589 43.1653 36.2438 43.2369C34.9288 43.3085 33.6675 43.9794 32.7601 45.1893L32.7597 45.1897L30.4469 48.2812C30.4465 48.2817 30.4462 48.2821 30.4459 48.2825C29.5981 49.4017 28.5235 49.9221 27.4882 49.9221C26.4529 49.9221 25.3783 49.4017 24.5306 48.2825C24.5302 48.2821 24.5299 48.2817 24.5296 48.2812L22.2167 45.1897Z" fill="#162082" stroke="#162082"/>
<path d="M37.194 26.9268H24.5898C23.6503 26.9268 22.8711 26.1476 22.8711 25.208C22.8711 24.2684 23.6503 23.4893 24.5898 23.4893H37.194C38.1336 23.4893 38.9128 24.2684 38.9128 25.208C38.9128 26.1476 38.1336 26.9268 37.194 26.9268Z" fill="#162082"/>
<path d="M37.194 17.7607H24.5898C23.6503 17.7607 22.8711 16.9816 22.8711 16.042C22.8711 15.1024 23.6503 14.3232 24.5898 14.3232H37.194C38.1336 14.3232 38.9128 15.1024 38.9128 16.042C38.9128 16.9816 38.1336 17.7607 37.194 17.7607Z" fill="#162082"/>
<path d="M17.8288 18.3333C16.5684 18.3333 15.5371 17.3021 15.5371 16.0417C15.5371 14.7813 16.5684 13.75 17.8288 13.75C19.0892 13.75 20.1204 14.7813 20.1204 16.0417C20.1204 17.3021 19.0892 18.3333 17.8288 18.3333Z" fill="#162082"/>
<path d="M17.8288 27.5003C16.5684 27.5003 15.5371 26.4691 15.5371 25.2087C15.5371 23.9482 16.5684 22.917 17.8288 22.917C19.0892 22.917 20.1204 23.9482 20.1204 25.2087C20.1204 26.4691 19.0892 27.5003 17.8288 27.5003Z" fill="#162082"/>
</svg>

    )
}