import "../../../css/kyc/rejectDropdown.css"

const RejectDropDown = ({ changeCardType }) => {
    return (
        <div className={`rejectDropdown-wrapper`}>
            <div className={`rejectDropdown-modal`}>
                <div className="rejectDropdown-modal-content" onClick={() => changeCardType('Blurry Image')}>Blurry Image</div>
                <hr />
                <div className="rejectDropdown-modal-content" onClick={() => changeCardType('Selfie is different from BVN Image')}>Selfie is different from BVN Image</div>
                <hr />
                <div className="rejectDropdown-modal-content" onClick={() => changeCardType('Poor Background lightening')}>Poor Background lightening</div>
                <hr />
                <div className="rejectDropdown-modal-content" onClick={() => changeCardType('Image not fully captured')}>Image not fully captured</div>
                <hr />
                <div className="rejectDropdown-modal-content" onClick={() => changeCardType('Invalid Image')}>Invalid Image</div>
            </div>
        </div>
    )
}

export default RejectDropDown