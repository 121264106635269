import { useEffect, useState } from "react";
import "../../css/app-content/email_notifications.css";
import KycInput from "../input/input";
import kimg from "../../img/ksearch.svg";
import plus from "../../img/plus.svg";
// import EmailNotificationToggleModal from "./email_notification_toggle_modal";
import { useNavigate } from "react-router-dom";
import EmailDropDown from "../../pages/app-contents/dropdown/email-drop-down";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { AppContentActions } from "../../state/actions";
import Moment from "react-moment";
import Pagination from "../footer-pagination";

function EmailNotification({ acceptToggle }) {
//   const [toggle, setToggle] = useState(false)

  const [currentPage, setCurrentPage] = useState(1);

  const {
    emailNotifications,
    emailSearchData,
    emailNotificationMeta,
    emailLoading,
  } = useSelector((state) => state.appContent);

  const dispatch = useDispatch();
  const { FetchEmailNotification, searchEmailNotification } =
    bindActionCreators(AppContentActions, dispatch);

  useEffect(() => {
    FetchEmailNotification(currentPage);

    // eslint-disable-next-line
  }, [currentPage]);

  const { per_page, total } = emailNotificationMeta;

  let userData =
    emailSearchData !== null ? emailSearchData : emailNotifications;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const navigate = useNavigate();

  const onClick = () => {
    navigate("/create-email-notification");
  };

//   const changeToggle = (value) => {
//     setToggle(!toggle);
//   };

  return (
    <>
      <div className="email-user-cont">
        <div>
          <button className="email-btn-primary" onClick={onClick}>
            <img src={plus} alt="plus icon" /> New Notification
          </button>
        </div>
        <div className="email-kscont">
          <KycInput
            label="Search Records"
            searchValue={searchEmailNotification}
          />
          <img src={kimg} alt="search icon" />
        </div>
      </div>

      <div>
        <div className="email-table-content">
          <table className="control-table">
            <tr>
              <td></td>
              <td>Notification Title</td>
              <td>Interval</td>
              <td>Created By</td>
              <td>Date Created</td>
            </tr>
            {userData.map((item, idx) => (
              <tr ke={idx} className="email-item-tr">
                <td>{(currentPage - 1) * per_page + 1 + idx}</td>
                <td>{item.title}</td>
                <td>{item.interval}</td>
                <td>{(item.author && item.author.name) || "-"}</td>
                <td>
                  {<Moment format="DD/MM/YYYY">{item.created_at}</Moment>}
                </td>
                <td>
                  {<EmailDropDown id={item.id} acceptToggle={acceptToggle} />}
                </td>
              </tr>
            ))}
            {userData.length === 0 ? (<tr>
            <td colSpan="5" className="text-danger">No Record found</td>
            </tr>) : null}
          </table>
        </div>
        {/* {toggle && <EmailNotificationToggleModal acceptToggle={acceptToggle} />} */}
        {!emailLoading && (
          <Pagination
            className="users-pagination"
            currentPage={currentPage}
            totalCount={total}
            pageSize={per_page}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </>
  );
}
export default EmailNotification;
