import "../../css/modal/mfCreation.css"
import CloseBtn from "../../img/Close-Square.svg"
// import Icomf from "../../img/icomf.svg"
import { useState } from "react"

export default function Lilmodal({ setView, setModal, loading, handleSubmit }) {
    const [display, setDisplay] = useState("")
    const closeDisplay = () => {
        setView("hidden")
        setModal(0)
        setDisplay("close")
    }
    return (
        <>
            <div class={`modbox ${display}`}>
                <div onClick={closeDisplay}><strong class="bold-text">Cancel</strong></div>
                <div class="flexedcont"><LilmodalSuccess/>
                    <div class="text-block-2">Are you sure want to approve this customer`s selfie</div>
                    <button href="#/" className="button-success w-button" disabled={loading} onClick={handleSubmit}><strong>Approve</strong></button>
                </div>
                <img src={CloseBtn} loading="lazy" width="24" height="24" alt="" class="image-2" onClick={closeDisplay} />
            </div>
        </>
    )
}

function LilmodalSuccess() {
    return (
        <svg width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.375 36.75H31.5" stroke="#162082" stroke-width="3.9375" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.375 26.25H39.375" stroke="#162082" stroke-width="3.9375" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M26.25 5.25H42C50.7412 5.7225 55.125 8.95125 55.125 20.9737V42" stroke="#162082" stroke-width="3.9375" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.875 23.6504V41.9466C7.875 52.4729 10.5 57.7491 23.625 57.7491H31.5C31.9462 57.7491 38.955 57.7491 39.375 57.7491" stroke="#162082" stroke-width="3.9375" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M55.125 42L39.375 57.75V49.875C39.375 44.625 42 42 47.25 42H55.125Z" stroke="#162082" stroke-width="3.9375" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.25 11.8125L9.1875 15.75L17.7187 7.875" stroke="#089E17" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    )
}