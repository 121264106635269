import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../css/app-content/articles_notifications.css";
import KycInput from "../input/input";
import kimg from "../../img/ksearch.svg";
import plus from "../../img/plus.svg";
// import ArticlesNotificationToggleModal from "./articles_toggle_modal";
import ArticleDropDown from "../../pages/app-contents/dropdown/article-drop-down";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { AppContentActions } from "../../state/actions";
import Moment from "react-moment";
import Pagination from "../footer-pagination";

function ArticleNotifications({ acceptToggle}) {
  // const [toggle, setToggle] = useState(false)

  const [currentPage, setCurrentPage] = useState(1);

  const {
    articlesNotifications,
    articlesSearchData,
    articlesNotificationMeta,
    articlesLoading,
  } = useSelector((state) => state.appContent);

  const dispatch = useDispatch();
  const { FetchArticlesNotification, searchArticlesNotification } =
    bindActionCreators(AppContentActions, dispatch);

  useEffect(() => {
    FetchArticlesNotification(currentPage);

    // eslint-disable-next-line
  }, [currentPage]);

  const { per_page, total } = articlesNotificationMeta;

  let userData =
    articlesSearchData !== null ? articlesSearchData : articlesNotifications;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const navigate = useNavigate();
  // const changeToggle = (value) => {
  //     setToggle(!toggle)
  // }

  const onClick = () => {
    navigate("/create-article");
  };
  return (
    <>
      <div className="articles-cont">
        <div>
          <button className="articles-btn-primary" onClick={onClick}>
            <img src={plus} alt="plus icon" />
            New Notification
          </button>
        </div>
        <div className="articles-kscont">
          <KycInput
            label="Search Records"
            searchValue={searchArticlesNotification}
          />
          <img src={kimg} alt="search icon" />
        </div>
      </div>

      <div>
        <div className="articles-table-content">
          <table className="control-table">
            <tr>
              <td></td>
              <td>Notification Title</td>
              <td>Active Platform</td>
              <td>Created By</td>
              <td>Date Created</td>
            </tr>
            {userData.map((item, idx) => (
              <tr key={idx} className="articles-item-tr">
                <td>{(currentPage - 1) * per_page + 1 + idx}</td>
                <td>{item.title}</td>
                <td>{item.active_platform}</td>
                <td>{(item.author && item.author.name) || "-"}</td>
                <td>
                  {<Moment format="DD/MM/YYYY">{item.created_at}</Moment>}
                </td>
                <td>
                  {<ArticleDropDown id={item.id} acceptToggle={acceptToggle} />}
                </td>
              </tr>
            ))}
            {userData.length === 0 ? (<tr>
            <td colSpan="5" className="text-danger">No Record found</td>
            </tr>) : null}
          </table>
        </div>
        {/* {toggle && (
          <ArticlesNotificationToggleModal acceptToggle={acceptToggle} />
        )} */}
        {!articlesLoading && (
          <Pagination
            className="users-pagination"
            currentPage={currentPage}
            totalCount={total}
            pageSize={per_page}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </>
  );
}
export default ArticleNotifications;
