import { useState } from "react";
import "../../css/btn/login-history-btn.css";
const LoginHistoryButton = ({ setCurrent }) => {
  // const [active, setActive] = useState(true);
  const [btn, setBtn] = useState("log-his-user");
  const [secBtn, setSecBtn] = useState("");
  const handleClick = (value) => {
    

    if (value === 1) {
      setSecBtn("");
      setBtn("log-his-user");
      setCurrent(1);
    } else {
      setSecBtn("login-his-pro");
      setBtn("");
      setCurrent(2);
    }
  };
  
  return (
    <div className="login-history-button-container">
      <button onClick={() => handleClick(1)} className={`login-history-button-1 ${btn}`}>
        User's History
      </button>
      <button
        onClick={() => handleClick(2)}
        className={`login-history-button-2 ${secBtn}`}
      >
        Profile View History
      </button>
    </div>
  );
};

export default LoginHistoryButton;
