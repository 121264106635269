export default function SettlementBtn(){
    return (
        <svg width="35" height="50" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.57446 19.479H26.3453" stroke="#162082" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.7411 37.8125H18.3244" stroke="#162082" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M24.0536 37.8125H33.2203" stroke="#162082" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M50.4078 27.5689V36.9189C50.4078 44.9627 48.3682 46.9793 40.2328 46.9793H14.7495C6.61405 46.9793 4.57446 44.9627 4.57446 36.9189V18.0814C4.57446 10.0377 6.61405 8.021 14.7495 8.021H33.2203" stroke="#162082" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M43.7162 9.46475L35.2141 17.9668C34.8933 18.2877 34.5724 18.9293 34.5037 19.3877L34.0453 22.6418C33.8849 23.8106 34.7099 24.6356 35.8787 24.4752L39.1328 24.0168C39.5912 23.9481 40.2328 23.6273 40.5537 23.3064L49.0558 14.8043C50.5224 13.3377 51.2099 11.6418 49.0558 9.48767C46.8787 7.31059 45.1828 7.99809 43.7162 9.46475Z" stroke="#162082" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M42.5016 10.6792C43.2349 13.2688 45.2516 15.2854 47.8183 15.9959" stroke="#162082" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    )
}