import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../css/app-content/faq_notifications.css";
import KycInput from "../input/input";
import kimg from "../../img/ksearch.svg";
import plus from "../../img/plus.svg";
// import FaqNotificationToggleModal from "./faq_toggle_modal";
import FaqDropDown from "../../pages/app-contents/dropdown/faq-drop-down";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { AppContentActions } from "../../state/actions";
// import Moment from "react-moment";
import Pagination from "../footer-pagination";

function FaqNotifications({ acceptToggle, setId }) {
  // const [toggle, setToggle] = useState(false)

  const [currentPage, setCurrentPage] = useState(1);

  const {
    faqsNotifications,
    faqsSearchData,
    faqsNotificationMeta,
    faqsLoading,
  } = useSelector((state) => state.appContent);

  const dispatch = useDispatch();
  const { FetchFAQsNotification, searchFaqsNotification } = bindActionCreators(
    AppContentActions,
    dispatch
  );

  useEffect(() => {
    FetchFAQsNotification(currentPage);

    // eslint-disable-next-line
  }, [currentPage]);

  const { per_page, total } = faqsNotificationMeta;

  let userData = faqsSearchData !== null ? faqsSearchData : faqsNotifications;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const navigate = useNavigate();

  // const changeToggle = (value) => {
  //     setToggle(!toggle)
  // }

  const onClick = () => {
    navigate("/create-faq");
  };

  return (
    <>
      <div className="faq-cont">
        <div>
          <button className="faq-btn-primary" onClick={onClick}>
            <img src={plus} alt="plus icon" />
            New Notification
          </button>
        </div>
        <div className="faq-kscont">
          <KycInput
            label="Search Records"
            searchValue={searchFaqsNotification}
          />
          <img src={kimg} alt="search icon" />
        </div>
      </div>

      <div>
        <div className="faq-table-content">
          <table className="control-table">
            <tr>
              <td></td>
              <td>Notification Title</td>
              <td>Helpful</td>
              <td>Not Helpful</td>
              <td>Created By</td>
            </tr>
            {userData.map((item, idx) => (
              <tr key={idx} className="faq-item-tr">
                <td>{(currentPage - 1) * per_page + 1 + idx}</td>
                <td>{item.question}</td>
                <td>{item.helpful || 0}</td>
                <td>{item.not_helpful || 0}</td>
                <td>{(item.author && item.author.name) || "-"}</td>
               
                <td>
                  {<FaqDropDown id={item.id} acceptToggle={acceptToggle} />}
                </td>
              </tr>
            ))}
            {userData.length === 0 ? (<tr>
            <td colSpan="5" className="text-danger">No Record found</td>
            </tr>) : null}
          </table>
        </div>
        {/* {toggle && <FaqNotificationToggleModal acceptToggle={acceptToggle} />} */}
        {!faqsLoading && (
          <Pagination
            className="users-pagination"
            currentPage={currentPage}
            totalCount={total}
            pageSize={per_page}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </>
  );
}
export default FaqNotifications;
