export default function PeopleBtn(){
    return (
        <svg width="45" height="50" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M41.2496 16.4084C41.1121 16.3855 40.9517 16.3855 40.8142 16.4084C37.6517 16.2938 35.1309 13.7042 35.1309 10.4959C35.1309 7.2188 37.7663 4.58337 41.0434 4.58337C44.3204 4.58337 46.9558 7.24171 46.9558 10.4959C46.9329 13.7042 44.4121 16.2938 41.2496 16.4084Z" stroke="#162082" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M38.8891 33.0917C42.0287 33.6188 45.4891 33.0688 47.9182 31.4417C51.1495 29.2875 51.1495 25.7584 47.9182 23.6042C45.4662 21.9771 41.9599 21.4271 38.8203 21.9771" stroke="#162082" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.6809 16.4084C13.8184 16.3855 13.9788 16.3855 14.1163 16.4084C17.2788 16.2938 19.7996 13.7042 19.7996 10.4959C19.7996 7.2188 17.1642 4.58337 13.8871 4.58337C10.61 4.58337 7.97461 7.24171 7.97461 10.4959C7.99753 13.7042 10.5184 16.2938 13.6809 16.4084Z" stroke="#162082" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.0415 33.0917C12.9019 33.6188 9.44147 33.0688 7.0123 31.4417C3.78105 29.2875 3.78105 25.7584 7.0123 23.6042C9.46439 21.9771 12.9706 21.4271 16.1102 21.9771" stroke="#162082" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M27.5006 33.527C27.3631 33.5041 27.2027 33.5041 27.0652 33.527C23.9027 33.4125 21.3818 30.8229 21.3818 27.6145C21.3818 24.3374 24.0172 21.702 27.2943 21.702C30.5714 21.702 33.2068 24.3604 33.2068 27.6145C33.1839 30.8229 30.6631 33.4354 27.5006 33.527Z" stroke="#162082" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.8316 40.7458C17.6004 42.9 17.6004 46.4292 20.8316 48.5833C24.4983 51.0354 30.5025 51.0354 34.1691 48.5833C37.4004 46.4292 37.4004 42.9 34.1691 40.7458C30.5254 38.3167 24.4983 38.3167 20.8316 40.7458Z" stroke="#162082" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>



    )
}