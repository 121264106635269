import DashBtn from "../assets/DashboardBtn";
import DashDark from "../assets/DashboardBtndark";
import UserBtn from "../assets/userbtnlight";
import UserBtndark from "../assets/userbtn";
import SupportBtn from "../assets/supportbtn";
import SupportBtndark from "../assets/supportbtndark";
import SettleBtn from "../assets/settlebtn";
import SettleBtndark from "../assets/settlebtndark";
import PeopleBtn from "../assets/peoplebtn";
import PeopleBtndark from "../assets/peoplebtndark";
import AppContentBtn from "../assets/appcontentbtn";
import AppContentBtndark from "../assets/appcontentbtndark";
import Profile from "../img/profile.svg";

import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";

import "../css/nav.css";
import { Link, useNavigate } from "react-router-dom";
import { authActions } from "../state/actions";
// import { useState } from "react";

export default function Nav({ Active }) {
  const navigate = useNavigate()

  const dispatch = useDispatch()
  // const {CheckPermission} = authActions
  const { Logout } = bindActionCreators(authActions, dispatch)

  const {currentUser} = useSelector((state) => state.auth)

  const LogoutUser = () => {
    Logout()
    navigate("/welcome-back")
  }
  
  // const name = authActions.DecodeToken().name
  const {name, roles} = currentUser
  return (
    <nav className="side-nav">
      <div className="side-nav-content">
        <div className="side-nav-img-section">
          <img src={Profile} alt="" />
          <p className="nav-name">
            {(name && name.split(" ")[0]) || "-"}
            <br />
            {(name && name.split(" ")[1]) || "-"}
          </p>
          <div className="verifier-p">
            <div className="user-role">{(roles && roles.length > 0 && roles[0].name.toUpperCase()) || "-"}</div>
          </div>
        </div>

        <ul className="side-nav-list">
          {/* {!roles && <li></li>}
          {(roles && roles.length > 0 && CheckPermission(currentUserRole, "dashboard") && ( */}
            <li>
            <Link to="/">
              {" "}
             {Active === 1 ? <div className="nav-icon-active"><div><DashDark/></div> <p>Dashboard</p></div>  : <DashBtn />} 
            </Link>
          </li>
          {/* ))} */}
          {/* {!roles && <li></li>}
          {(roles && roles.length > 0 && CheckPermission(currentUserRole, "kyc") && ( */}
          <li>
            <Link to="/kyc">
              {" "}
              {Active === 2 ? <div className="nav-icon-active"><div><UserBtndark /></div> <p>KYC</p></div> : <UserBtn />} 
            </Link>
          </li>
          {/* ))} */}
          {/* {!roles && <li></li>}
          {(roles && roles.length > 0 && CheckPermission(currentUserRole, "support") &&( */}
            <li>
            <Link to="/support">
              {Active === 3 ? <div className="nav-icon-active"><div><SupportBtndark /> </div> <p>Support</p></div> : <SupportBtn />} 
            </Link>
          </li>
          {/* ))} */}
          {/* {!roles && <li></li>}
          {(roles && roles.length > 0 && CheckPermission(currentUserRole, "settlement") &&( */}
            <li>
            <Link to="/settlements">
              {Active === 4 ? <div className="nav-icon-active"><div><SettleBtndark /> </div> <p>Settlement</p></div>  :  <SettleBtn />}
            </Link>
          </li>
          {/* ))} */}
          {/* {!roles && <li></li>}
          {(roles && roles.length > 0 && CheckPermission(currentUserRole, "user control") && ( */}
            <li>
            <Link to="/user-control">
              {Active === 5 ? <div className="nav-icon-active"><div><PeopleBtndark /> </div> <p>User</p></div>  :  <PeopleBtn />}
            </Link>
            {/* <Link to="/settlements">
              <PeopleBtn />
            </Link> */}
          </li>
          {/* ))} */}
          {/* {!roles && <li></li>}
          {(roles && roles.length > 0 && CheckPermission(currentUserRole, "app content") && ( */}
            <li>
            <Link to="/app-content">
              {" "}
              {Active === 6 ? <div className="nav-icon-active"><div><AppContentBtndark /> </div> <p>App Content</p></div>  : <AppContentBtn />}
            </Link>
          </li>
          {/* ))} */}
          
        </ul>
        <button className="nav-logout-btn" style={{ cursor: "pointer" }} onClick={LogoutUser}>
          <LogoutIcon />
          Logout
        </button>
      </div>
    </nav>
  );
}
function LogoutIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0996 16.44C14.7896 20.04 12.9396 21.51 8.88961 21.51L8.75961 21.51C4.28961 21.51 2.49961 19.72 2.49961 15.25L2.49961 8.73001C2.49961 4.26001 4.28961 2.47001 8.75961 2.47001L8.88961 2.47001C12.9096 2.47001 14.7596 3.92001 15.0896 7.46001"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.99988 12L20.3799 12"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.15 15.35L21.5 12L18.15 8.64998"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
