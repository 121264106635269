export default function AppContentBtn(){
    return (
        <svg width="45" height="45" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.423 45.146C17.3021 43.1293 20.1667 43.2897 21.8167 45.4897L24.1313 48.5835C25.9875 51.0356 28.9896 51.0356 30.8459 48.5835L33.1605 45.4897C34.8105 43.2897 37.675 43.1293 39.5542 45.146C43.6334 49.5002 46.9563 48.0564 46.9563 41.9606V16.1335C46.9792 6.89808 44.825 4.5835 36.1625 4.5835H18.8375C10.175 4.5835 8.02087 6.89808 8.02087 16.1335V41.9377C8.02087 48.0564 11.3667 49.4772 15.423 45.146Z" stroke="#162082" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.5535 25.2083H18.5741" stroke="#162082" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M24.9756 25.2085H37.5798" stroke="#162082" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.5535 16.0418H18.5741" stroke="#162082" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M24.9756 16.0415H37.5798" stroke="#162082" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    )
}